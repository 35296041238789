<template>
  <div :class="{ 'new-post-container': parent !== parentTypes.PROFILE }">
    <div v-if="parent !== parentTypes.PROFILE" class="new-post-heading">
      <i class="icon-Aula_write" />
      <label v-if="inEdit">{{ 'POSTS_EDIT_LABEL_HEADING' | fromTextKey }}</label>
      <label v-else>{{ 'POSTS_CREATE_LABEL_HEADING' | fromTextKey }}</label>
      <div class="close">
        <i
          class="icon-Aula_close"
          tabindex="0"
          role="button"
          :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
          @click="cancel"
          @keydown.enter="cancel"
        />
      </div>
    </div>
    <div v-if="institutionsThatCanBeSeen.length > 1">
      <label class="thin">{{ 'CALENDAR_TITLE_CHOOSE_INSTITUTION' | fromTextKey }}</label>
      <aula-select
        v-model="postForm.institutionCode"
        :placeholder="'SELECT' | fromTextKey"
        :aria-label="'ARIA_LABEL_POST_CHOOSE_INSTITUTION' | fromTextKey"
        :disabled="inEdit || postForm.sharedWithGroups.length > 0"
        @change="onInstitutionChange"
      >
        <aula-option
          v-for="institution in institutionsThatCanBeSeen"
          :id="institution.institutionCode"
          :key="institution.institutionCode"
          :label="institution.name"
          :value="institution.institutionCode"
        />
      </aula-select>
    </div>
    <div v-if="parent === parentTypes.PROFILE">
      <label>{{ 'POSTS_CREATE_LABEL_TO' | fromTextKey }} <span class="mandatory">*</span></label>
      <aula-search-recipients
        :include-otp-from-guardian-in-sub-group="true"
        :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
        :doc-types-api="[docTypes.GROUP]"
        :from-module="moduleTypes.OVERVIEW"
        :show-search-icon="true"
        :enable-relatives-for-students="false"
        :close-dropdown-after-select="false"
        :disable-portal-roles-members-when-expanding-group="[
          portalRoles.CHILD,
          portalRoles.EMPLOYEE,
          portalRoles.GUARDIAN,
        ]"
        :allow-create="false"
        :reset-input="resetRecipientsInput"
        :institution-code="postForm.institutionCode"
        :scope-employees-to-institution="false"
        :existing-participants="existingParticipants"
        :class="newPostValidator.showMandatoryReceiverError ? 'red-border' : ''"
        @emitSelected="addGroups"
      />
      <div v-if="showAdminWarning" class="admin-warning">
        {{ 'POST_ADMIN_CAN_SEE_POST' | fromTextKey }}
      </div>
      <b-alert variant="danger" :show="newPostValidator.showMandatoryReceiverError">
        {{ 'POST_MANDATORY_RECEIVERS' | fromTextKey }}
      </b-alert>
    </div>
    <div>
      <label>{{ 'POSTS_CREATE_LABEL_TITLE' | fromTextKey }} <span class="mandatory">*</span></label>
      <b-form-input
        v-model.trim="postForm.title"
        :class="newPostValidator.showMandatoryTitleError ? 'red-border' : ''"
        :placeholder="'POSTS_CREATE_TITLE_INPUT_PLACEHOLDER' | fromTextKey"
        :aria-label="'ARIA_LABEL_POSTS_CREATE_TITLE' | fromTextKey"
        @change="saveDraftInStorage"
      />
      <b-alert variant="danger" :show="newPostValidator.showMandatoryTitleError">
        {{ 'POST_MANDATORY_TITLE' | fromTextKey }}
      </b-alert>
    </div>
    <b-row align-h="between">
      <b-col cols="12" lg="5">
        <span v-if="isEditedPostPublishedInPast" class="sr-only" tabindex="0">
          {{ publishDateFormat }}
        </span>
        <label>{{ 'POSTS_PUBLISH_LABEL' | fromTextKey }}</label>
        <b-row>
          <b-col cols="7">
            <el-date-picker
              v-model="postForm.publishDate"
              :picker-options="publishOptions"
              :format="defaultDateFormat"
              :value-format="defaultDateValueFormat"
              :clearable="false"
              type="date"
              :placeholder="'ARIA_LABEL_POSTS_PUBLISH_DATE' | fromTextKey"
              class="pr-2 w-100"
              :class="{ 'red-border': newPostValidator.showDateNotValidError }"
              :disabled="isEditedPostPublishedInPast"
              @change="onChangePublishDate"
              @blur="setDefaultPublishDate"
            />
          </b-col>
          <b-col cols="5">
            <label class="sr-only" for="publishTime">
              {{ 'ARIA_LABEL_POSTS_PUBLISH_TIME' | fromTextKey }}
            </label>
            <AulaTimepicker
              id="publishTime"
              v-model="postForm.publishTime"
              format="HH:mm"
              :hour-label="'HOUR' | fromTextKey"
              :minute-label="'MINUTE' | fromTextKey"
              :placeholder="'ARIA_LABEL_POSTS_PUBLISH_TIME' | fromTextKey"
              :minute-interval="minuteInterval"
              :class="{ 'red-border': newPostValidator.showDateNotValidError }"
              :disabled="isEditedPostPublishedInPast"
              hide-clear-button
              advanced-keyboard
              lazy
              @change="onChangePublishDate"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="5">
        <label>{{ 'POSTS_EXPIRE_LABEL' | fromTextKey }}</label>
        <b-row>
          <b-col cols="7">
            <el-date-picker
              v-model="postForm.expireDate"
              :picker-options="expireOptions"
              :format="defaultDateFormat"
              :value-format="defaultDateValueFormat"
              :clearable="false"
              type="date"
              :placeholder="'ARIA_LABEL_POSTS_EXPIRE_DATE' | fromTextKey"
              class="pr-2 w-100"
              :class="{ 'red-border': newPostValidator.showDateNotValidError }"
              @change="newPostValidator.showDateNotValidError = false"
              @blur="setDefaultExpireDate"
            />
          </b-col>
          <b-col cols="5">
            <label class="sr-only" for="expireTime">
              {{ 'ARIA_LABEL_POSTS_EXPIRE_TIME' | fromTextKey }}
            </label>
            <AulaTimepicker
              id="expireTime"
              v-model="postForm.expireTime"
              format="HH:mm"
              :hour-label="'HOUR' | fromTextKey"
              :minute-label="'MINUTE' | fromTextKey"
              :placeholder="'ARIA_LABEL_POSTS_EXPIRE_TIME' | fromTextKey"
              :minute-interval="minuteInterval"
              :class="{ 'red-border': newPostValidator.showDateNotValidError }"
              :start="expireStartTime"
              end="23:59"
              hide-clear-button
              advanced-keyboard
              @change="onChangeExpireTime"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert variant="danger" :show="newPostValidator.showDateNotValidError">
      {{ 'POSTS_EXPIRE_BEFORE_PUBLISH' | fromTextKey }}
    </b-alert>
    <div class="margin-bottom-40">
      <label>{{ 'POSTS_CREATE_LABEL_CONTENTS' | fromTextKey }} <span class="mandatory">*</span></label>
      <PluginTextEditor
        v-if="showEditor"
        v-model="postForm.content"
        :max-length="maxLength"
        :hide-alert="newPostValidator.showContentTooLongError"
        :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
        :class="{
          'red-border': newPostValidator.showContentTooLongError || newPostValidator.showMandatoryContentError,
        }"
        :institution-code="postForm.institutionCode"
        @input="saveDraftInStorage"
        @textTooLong="disableSubmit"
      />
      <b-alert variant="danger" :show="newPostValidator.showMandatoryContentError">
        {{ 'POST_MANDATORY_CONTENT' | fromTextKey }}
      </b-alert>
      <b-alert variant="danger" :show="newPostValidator.showContentTooLongError">
        {{ 'POST_CONTENT_TOO_LONG' | fromTextKey }}
      </b-alert>
    </div>
    <upload
      class="margin-top margin-bottom"
      :limit-file-upload="200"
      :existing-media="existingMedia"
      :institution-code-for-tagging="postForm.institutionCode"
      @mediaUpdated="filesChanged"
    />
    <b-row class="controls">
      <b-col>
        <b-form-checkbox v-model="postForm.allowComments" @input="showWarningWhenDisablingComments()">
          {{ 'POSTS_CREATE_ENABLE_COMMENTS' | fromTextKey }}
        </b-form-checkbox>
        <b-form-checkbox
          v-if="profile.role == portalRoles.EMPLOYEE"
          v-model="postForm.isImportant"
          :value="true"
          :unchecked-value="false"
          @change="onChangeIsImportant"
        >
          {{ 'POSTS_CREATE_HIGH_PRIORITY' | fromTextKey }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="isEmployee && postForm.isImportant" class="controls">
      <b-col sm="6" md="3">
        <el-date-picker
          v-model="postForm.importantFrom"
          :picker-options="availableDateFrom"
          format="dd-MM-yyyy"
          type="date"
          :placeholder="'POSTS_CREATE_DATE_FROM' | fromTextKey"
          :aria-label="'ARIA_LABEL_POSTS_CREATE_DATE_FROM' | fromTextKey"
          :class="{ elPickerActive: isImportantFromClicked, 'mb-3': isMobile }"
          @change="onChangeImportantDate"
          @focus="
            datePickerModifier();
            isImportantFromClicked = true;
          "
          @blur="isImportantFromClicked = false"
        />
      </b-col>
      <b-col sm="6" md="3">
        <el-date-picker
          v-model="postForm.importantTo"
          :picker-options="availableDateTo"
          type="date"
          format="dd-MM-yyyy"
          :placeholder="'POSTS_CREATE_DATE_TO' | fromTextKey"
          :aria-level="'ARIA_LABEL_POSTS_CREATE_DATE_TO' | fromTextKey"
          :class="{ elPickerActive: isImportantToClicked }"
          @change="onChangeImportantDate"
          @focus="
            datePickerModifier();
            isImportantToClicked = true;
          "
          @blur="isImportantToClicked = false"
        />
      </b-col>
    </b-row>
    <b-alert variant="danger" :show="newPostValidator.showPermissionsError">
      {{ 'POST_NO_PERMISSION' | fromTextKey }}
    </b-alert>
    <aula-form-footer
      :is-loading="isLoading"
      :submit-text="inEdit ? 'BUTTON_SAVE' : 'BUTTON_CREATE'"
      :submit-disabled="submitDisabled"
      @cancelClicked="cancel"
      @submitClicked="preparePostForm"
    />
    <aula-modal
      ref="warningCreateModal"
      header-text="POST_CREATE_TITLE_WARNING"
      @cancelClicked="$refs.warningCreateModal.hide()"
      @okClicked="$refs.warningCreateModal.hide(), cancel()"
    >
      {{ 'POST_CREATE_CONTENT_WARNING_POST' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="NO"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="
        $refs.theCommunicationChannelIsBlocked.hide();
        removeBlocked();
      "
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS' | fromTextKey }}
      <div class="mt-2">
        {{
          blockedGroups
            .map(
              group =>
                group.blockedGroup.name +
                ' (' +
                Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + group.portalRole.toUpperCase()) +
                ')'
            )
            .join(', ')
        }}
      </div>
    </aula-modal>
    <aula-modal ref="disableCommentsWarning" :show-cancel="false" @okClicked="$refs.disableCommentsWarning.hide()">
      {{ 'POST_DISABLE_COMMENTS_WARNING' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment-timezone';
import { formatDateTime } from '../../../shared/utils/dateUtil';
import { types } from '../../store/types/types';
import Upload from '../../../shared/components/Upload.vue';
import { docTypes } from '../../../shared/enums/docTypes';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { parentTypes } from '../../../shared/enums/parentTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import AulaTimepicker from '../../../shared/components/Timepicker';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { mapActions, mapGetters } from 'vuex';
import { filterGroupMembershipInstitutions, getDefaultInstitutionCode } from '../../../shared/utils/institutionUtil';
import { NewPostValidator } from '../../business/validators/newPostValidator';
import PluginTextEditor from '../text_editor/PluginTextEditor.vue';

export default {
  name: 'NewPost',
  components: {
    PluginTextEditor,
    AulaSearchRecipients,
    AulaTimepicker,
    Upload,
  },
  props: {
    parent: {
      type: String,
      default: parentTypes.PROFILE,
    },
    id: {
      type: Number,
      default: undefined,
    },
  },
  data: function () {
    return {
      showEditor: false,
      showAdminWarning: false,
      Vue: Vue,
      newPostValidator: new NewPostValidator(),
      postForm: {
        id: '',
        creatorInstitutionProfileId: '',
        institutionCode: null,
        title: '',
        content: '',
        allowComments: false,
        isImportant: false,
        importantFrom: '',
        importantTo: '',
        sharedWithGroups: [],
        sharedGroupIds: [],
        attachments: [],
        preparedAttachments: {},
        parent: this.parent,
        publishDate: moment().format('YYYY-MM-DD'),
        publishTime: moment().format('HH:mm:ss'),
        expireDate: moment().add(1, 'years').format('YYYY-MM-DD'),
        expireTime: moment().add(1, 'years').format('HH:mm:ss'),
      },
      isImportantFromClicked: false,
      isImportantToClicked: false,
      isLoading: false,
      resetRecipientsInput: false,
      existingParticipants: [],
      blockedGroups: [],
      maxLength: 10000,
      submitDisabled: false,
      moduleTypes: moduleTypes,
      existingMedia: [],
      publishOptions: {
        firstDayOfWeek: 1,
        disabledDate: time => {
          const today = moment().startOf('day').unix();
          return today > time.getTime() / 1000;
        },
      },
      expireOptions: {
        firstDayOfWeek: 1,
        disabledDate: time => {
          const publishDate = moment(this.postForm.publishDate).startOf('day').unix();
          const today = moment().startOf('day').unix();
          const milliseconds = time.getTime() / 1000;
          return publishDate > milliseconds || today > milliseconds;
        },
      },
      availableDateFrom: {
        disabledDate: time => {
          let to;
          const today = moment().startOf('day').unix();
          if (this.postForm.importantTo) {
            to = moment(this.postForm.importantTo).startOf('day').unix();
          } else {
            to = moment(time).endOf('month').unix();
          }
          return today > time.getTime() / 1000 || time.getTime() / 1000 > to;
        },
        firstDayOfWeek: 1,
      },
      availableDateTo: {
        disabledDate: time => {
          let from;
          if (this.postForm.importantFrom) {
            from = moment(this.postForm.importantFrom).startOf('day').unix();
          } else {
            this.postForm.importantTo = null;
            return true;
          }
          return time.getTime() / 1000 < from;
        },
        firstDayOfWeek: 1,
      },
      docTypes: docTypes,
      portalRoles: portalRoles,
      groupTypes: groupTypes,
      parentTypes: parentTypes,
      timepickerSettings: timepickerSettings,
    };
  },
  computed: {
    ...mapGetters({
      posts: types.GET_POSTS,
      post: types.GET_SINGLE_POST,
      group: types.GET_ACTIVE_GROUP,
      draftPost: types.GET_DRAFT_POST,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      getMaxFileSize: types.GET_MAX_FILE_SIZE,
      isValidFileFormat: types.GET_VALID_FILE_FORMAT,
      isMobile: types.GET_IS_MOBILE,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      hasPermissionOnInstitutionGroup: types.HAS_PERMISSION_ON_INSTITUTION_GROUP,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
    }),
    publishDateFormat() {
      const date = moment(this.post.publishAt).format('DD-MM-YYYY HH:mm');
      return this.$options.filters.fromTextKey('POST_PUBLISH_DATE', { date });
    },
    institutionsThatCanBeSeen() {
      let institutions = this.institutions;
      institutions = institutions.filter(institution => !institution.communicationBlock);
      const group = this.group;
      if (this.parent === parentTypes.GROUP) {
        institutions = filterGroupMembershipInstitutions(institutions, group).filter(
          institution =>
            this.hasPermissionOnInstitutionGroup({ permissionId: permissionEnum.WRITE_POST, institution, group }) ||
            this.hasPermissionOnInstitution(
              permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
              institution.institutionCode
            )
        );
      }
      return institutions;
    },
    minuteInterval() {
      return timepickerSettings.FIFTEEN_MINUTE_INTERVAL;
    },
    lastMinuteInterval() {
      return 60 - this.minuteInterval;
    },
    isEmployee() {
      return this.profile.role === portalRoles.EMPLOYEE;
    },
    inEdit() {
      return this.getId !== undefined;
    },
    getId() {
      if (this.parent === parentTypes.PROFILE) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    expireStartTime() {
      return this.postForm.publishDate === this.postForm.expireDate
        ? moment(this.postForm.publishTime, 'HH:mm').add(15, 'minutes').format('HH:mm')
        : '00:00';
    },
    isEditedPostPublishedInPast() {
      return this.inEdit && moment(this.post.publishAt).isBefore(moment());
    },
  },
  watch: {
    draftPost() {
      this.postForm = { ...this.postForm, ...this.draftPost };
      this.existingMedia = this.postForm.attachments;
      this.existingParticipants = this.postForm.sharedWithGroups;
    },
    'postForm.content'() {
      this.newPostValidator.validatePostContent(this.postForm.content);
    },
    profile() {
      if (this.profile.id) {
        this.loadDraft();
      }
    },
  },
  mounted() {
    const elementSelectors = document.getElementsByClassName('el-select__input');
    if (elementSelectors.length > 0) {
      setTimeout(() => {
        elementSelectors[0].focus();
      }, 500);
    }
    this.populatePostData();
    if (this.profile.id) {
      this.loadDraft();
    }
    this.fetchMaxFileSize();
    this.fetchAuthorisedFileFormats();
  },
  methods: {
    ...mapActions({
      clearNewPostLocally: types.ACTIONS_CLEAR_NEW_POST,
      addNewPost: types.ACTIONS_ADD_NEW_POST,
      fetchPost: types.ACTION_GET_POST,
      editPost: types.ACTIONS_EDIT_POST,
      savePostsLocally: types.ACTIONS_SAVE_NEW_POSTS_LOCALLY,
      initSavedPosts: types.ACTIONS_INIT_LOCAL_NEW_POSTS,
      fetchMaxFileSize: types.ACTIONS_CENTRAL_CONFIG_MAX_FILE_SIZE,
      fetchAuthorisedFileFormats: types.ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
    }),
    populatePostData() {
      if (!this.inEdit) {
        this.postForm.institutionCode = getDefaultInstitutionCode(
          this.institutionsThatCanBeSeen,
          this.activeInstitutionCodes,
          this.institutionsThatCanBeSeen[0]?.institutionCode
        );
        return;
      }

      this.fetchPost({
        id: this.getId,
      }).then(() => {
        this.postForm = { ...this.postForm, ...this.post };
        this.postForm.content = this.post.content.html;
        if (this.postForm.importantFrom) {
          this.postForm.importantFrom = moment(this.postForm.importantFrom).utc().format('YYYY-MM-DD');
        }
        if (this.postForm.importantTo) {
          this.postForm.importantTo = moment(this.postForm.importantTo).utc().format('YYYY-MM-DD');
        }
        this.populatePublishExpire();
        this.existingMedia = this.post.attachments;
        const sharedWithGroups = [];
        for (const group of this.post.sharedWithGroups) {
          if (group.portalRoles != null) {
            for (const role of group.portalRoles) {
              sharedWithGroups.push({
                groupId: group.id,
                value: 'group' + group.id + group.institutionCode + role,
                type: 'group',
                id: group.id,
                institutionCode: group.institutionCode,
                name: group.name,
                portalRole: role,
                label: group.name + ' (' + Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + role.toUpperCase()) + ')',
              });
            }
          } else {
            sharedWithGroups.push({
              groupId: group.id,
              value: 'group' + group.id + group.institutionCode,
              type: 'group',
              id: group.id,
              institutionCode: group.institutionCode,
              name: group.name,
              portalRole: null,
              label: group.name,
            });
          }
        }
        this.postForm.sharedWithGroups = sharedWithGroups;
        this.existingParticipants = this.postForm.sharedWithGroups;
        this.postForm.institutionCode = this.institutions.find(
          inst => inst.institutionProfileId === this.post.ownerProfile.id
        )?.institutionCode;
        this.showEditor = true;
      });
    },
    onChangeImportantDate() {
      this.saveDraftInStorage();
    },
    onChangeIsImportant() {
      this.saveDraftInStorage();
    },
    onChangeExpireTime() {
      this.newPostValidator.showDateNotValidError = false;
      this.saveDraftInStorage();
    },
    onChangePublishDate() {
      this.saveDraftInStorage();
      this.newPostValidator.showDateNotValidError = false;
    },
    saveDraftInStorage() {
      if (!this.inEdit) {
        this.savePostsLocally({ profile: this.profile, group: this.group, draft: this.postForm });
      }
    },
    onInstitutionChange() {
      this.saveDraftInStorage();
      this.resetRecipientsInput = !this.resetRecipientsInput;
    },
    setDefaultPublishDate() {
      if (!this.postForm.publishDate) {
        this.postForm.publishDate = moment().format('YYYY-MM-DD');
      }
    },
    setDefaultExpireDate() {
      if (!this.postForm.expireDate) {
        this.postForm.expireDate = moment().add(1, 'years').format('YYYY-MM-DD');
      }
      this.saveDraftInStorage();
    },
    disableSubmit(state) {
      this.submitDisabled = state;
    },
    showWarningWhenDisablingComments() {
      if (
        this.inEdit &&
        this.postForm.commentCount != null &&
        this.postForm.commentCount > 0 &&
        !this.postForm.allowComments
      ) {
        this.$refs.disableCommentsWarning.show();
      }
      this.saveDraftInStorage();
    },
    filesChanged(attachments) {
      this.postForm.attachments = attachments;
    },
    addGroups(groups) {
      this.showAdminWarning = false;
      this.postForm.sharedWithGroups = groups.map(group => {
        if (group.portalRole != null) {
          this.showAdminWarning = true;
        }
        return {
          groupId: group.id,
          value: group.value,
          type: 'group',
          id: group.id,
          institutionCode: group.institutionCode,
          name: group.name,
          portalRole: group.portalRole,
          label: group.labelWhenGroupIsChosen,
        };
      });
      this.saveDraftInStorage();
    },
    discardPost() {
      // Issue in edge where new data is not returned in the following getPosts request.
      // Can be solved by returning newly created post from the API.
      // Until then, this is a tempory fix for EDGE.
      setTimeout(this.executeDelayedDiscardPost, 40);
    },
    executeDelayedDiscardPost() {
      this.resetForm();
      if (!this.inEdit) {
        this.clearNewPostLocally({ profile: this.profile, group: this.group });
      }

      if (this.parent === parentTypes.PROFILE) {
        // on the dashboard
        this.$emit('postSubmitted');
      } else {
        // in a modal
        this.backToGroupOverviewPage();
      }
    },
    resetForm() {
      this.postForm.title = '';
      this.postForm.content = '';
      this.postForm.allowComments = false;
      this.postForm.isImportant = false;
      this.postForm.importantFrom = '';
      this.postForm.importantTo = '';
      this.postForm.sharedWithGroups = [];
      this.postForm.sharedGroupIds = [];
      this.postForm.attachments = [];
      this.existingParticipants = [];
      this.postForm.publishDate = moment().format('YYYY-MM-DD');
      this.postForm.publishTime = moment().format('HH:mm:ss');
      this.postForm.expireDate = moment().add(1, 'years').format('YYYY-MM-DD');
      this.postForm.expireTime = moment().add(1, 'years').format('HH:mm:ss');
    },
    prepareSharedGroups() {
      const sharedWithGroups = [];
      if (
        this.parent === parentTypes.GROUP &&
        this.group.id != null &&
        this.postForm.sharedWithGroups.filter(group => group.id == this.group.id).length == 0
      ) {
        this.postForm.sharedWithGroups.push({
          id: this.group.id,
          portalRole: portalRoles.GUARDIAN,
        });
        this.postForm.sharedWithGroups.push({
          id: this.group.id,
          portalRole: portalRoles.CHILD,
        });
        this.postForm.sharedWithGroups.push({
          id: this.group.id,
          portalRole: portalRoles.EMPLOYEE,
        });
      }
      for (const group of this.postForm.sharedWithGroups) {
        if (group.id == null) {
          continue;
        }
        const existing = sharedWithGroups.find(gro => gro.groupId == group.id);
        if (existing != null) {
          if (group.portalRole != null) {
            if (existing.portalRoles != null) {
              existing.portalRoles.push(group.portalRole);
            }
          } else {
            existing.portalRoles = null;
          }
        } else {
          sharedWithGroups.push({
            id: group.id,
            groupId: group.id,
            portalRoles: group.portalRole != null ? [group.portalRole] : null,
          });
        }
      }
      this.postForm.sharedGroupIds = sharedWithGroups;
    },
    setPublishDateAndTime() {
      this.postForm.publishDate = moment().startOf('day').format('YYYY-MM-DD');
      this.postForm.publishTime = moment().format('HH:mm:ss');
    },
    preparePostForm() {
      this.isLoading = true;

      const now = moment();
      const isNewPublishInThePast = moment(this.postForm.publishDate + ' ' + this.postForm.publishTime).isBefore(now);
      if (isNewPublishInThePast && !this.isEditedPostPublishedInPast) {
        this.setPublishDateAndTime();
      }

      if (!this.newPostValidator.validate(this.validationModel())) {
        this.isLoading = false;
        return;
      }
      this.postForm.parent = this.parent;
      if (this.postForm.importantFrom) {
        this.postForm.importantFrom = moment(this.postForm.importantFrom).format('YYYY-MM-DD');
      }
      if (this.postForm.importantTo) {
        this.postForm.importantTo = moment(this.postForm.importantTo).format('YYYY-MM-DD');
      }
      this.prepareSharedGroups();

      if (this.postForm.institutionCode == null) {
        this.postForm.institutionCode = getDefaultInstitutionCode(this.institutions, this.activeInstitutionCodes);
      }

      this.postForm.creatorInstitutionProfileId = this.institutions.find(
        inst => inst.institutionCode == this.postForm.institutionCode
      ).institutionProfileId;

      this.checkForBlockedComChannels();
    },
    removeBlocked() {
      this.postForm.sharedWithGroups = this.postForm.sharedWithGroups.filter(
        group =>
          !this.blockedGroups.some(block => group.id == block.blockedGroup.id && group.portalRole == block.portalRole)
      );
      this.prepareSharedGroups();
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.$nextTick(() => {
        this.existingParticipants = this.postForm.sharedWithGroups;
        if (this.postForm.sharedWithGroups.length > 0) {
          this.send();
        }
      });
    },
    checkForBlockedComChannels() {
      this.blockedGroups = [];
      this.resolveBlockedUsers({
        creator: this.postForm.creatorInstitutionProfileId,
        groupSharings: this.postForm.sharedGroupIds,
      }).then(response => {
        if (response != null && response.length > 0) {
          this.blockedGroups = response.filter(group => group.portalRole !== portalRoles.OTP);
          this.$refs.theCommunicationChannelIsBlocked.show();
          this.isLoading = false;
        } else {
          this.send();
        }
      });
    },
    async send() {
      this.isLoading = true;
      this.postForm.sharedWithGroups = this.postForm.sharedGroupIds;
      this.postForm.preparedAttachments = Vue.filter('prepareAttachments')(this.postForm.attachments);
      // Update existing attachments
      if (this.postForm.preparedAttachments.existingMedia.length > 0) {
        const updatedMedia = this.postForm.preparedAttachments.existingMedia.filter(
          att => att.media != null && att.media.updated
        );
        if (updatedMedia.length > 0) {
          try {
            await this.updateAttachments({
              media: Vue.filter('prepareMedia')(updatedMedia),
            });
          } catch (error) {
            this.isLoading = false;
            return;
          }
        }
      }
      if (
        this.postForm.preparedAttachments.media != null ||
        this.postForm.preparedAttachments.files != null ||
        this.postForm.preparedAttachments.links != null
      ) {
        // Create new attachments
        this.postForm.preparedAttachments.ownerInstitutionProfileId = this.postForm.creatorInstitutionProfileId;
        this.postForm.preparedAttachments.institutionCode = this.postForm.institutionCode;
        this.createAttachments(this.postForm.preparedAttachments)
          .then(rsp => {
            const attachments = rsp.files
              .concat(rsp.links)
              .concat(rsp.media)
              .concat(this.postForm.preparedAttachments.existingMedia);
            this.postForm.attachmentIds = attachments.map(att => att.id);
            if (this.inEdit) {
              this.postForm.id = this.getId;
              this.editPost(this.postForm).then(() => {
                this.discardPost();
                this.isLoading = false;
              });
            } else {
              this.addNewPost(this.postForm).then(() => {
                this.discardPost();
                this.isLoading = false;
              });
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.postForm.attachmentIds = this.postForm.preparedAttachments.existingMedia.map(att => att.id);
        if (this.inEdit) {
          this.postForm.id = this.getId;
          this.editPost(this.postForm).then(() => {
            this.discardPost();
            this.isLoading = false;
          });
        } else {
          this.addNewPost(this.postForm).then(() => {
            this.discardPost();
            this.isLoading = false;
          });
        }
      }
    },
    cancel() {
      this.saveDraftInStorage();
      this.resetForm();
      if (this.parent === parentTypes.PROFILE) {
        // on the dashboard
        this.$emit('cancel');
      } else {
        // in a modal
        this.backToGroupOverviewPage();
      }
    },
    backToGroupOverviewPage() {
      this.$router.push({ name: 'group-overview', query: { filter: this.$router.currentRoute.query.filter } });
    },
    loadDraft() {
      if (!this.inEdit) {
        this.initSavedPosts({ parent: this.parent });
        this.showEditor = true;
      }
    },
    populatePublishExpire() {
      this.postForm.publishDate = moment(this.post.publishAt).format('YYYY-MM-DD');
      this.postForm.publishTime = moment(this.post.publishAt).format('HH:mm:ss');
      this.postForm.expireDate = moment(this.post.expireAt).format('YYYY-MM-DD');
      this.postForm.expireTime = moment(this.post.expireAt).format('HH:mm:ss');
    },
    validationModel() {
      this.postForm.publishAt = formatDateTime(this.postForm.publishDate, this.postForm.publishTime);
      this.postForm.expireAt = formatDateTime(this.postForm.expireDate, this.postForm.expireTime);
      return {
        parent: this.parent,
        title: this.postForm.title,
        content: Vue.filter('textWithoutHtml')(this.postForm.content),
        expireAt: this.postForm.expireAt,
        publishAt: this.postForm.publishAt,
        sharedWithGroups: this.postForm.sharedWithGroups,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.new-post-container {
  padding: 40px 0 0 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-grey-light;

  @include breakpoint-lg-down() {
    padding: 0 20px 0 20px;
    position: fixed;
    max-width: unset;
    top: 0;
    width: 100%;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 1051;
  }
  .admin-warning {
    color: $color-conversation-left;
  }
}

.new-post-heading {
  position: relative;
  margin-bottom: 20px;
  i {
    font-size: 20px;
  }
  label {
    font-size: 18px;
  }
}

hr {
  margin-bottom: 0;
  margin-top: 15px;
}

.row {
  min-height: 60px;
}

.controls > div {
  font-size: 12.5px;

  &.checkbox {
    padding-left: 15px;
  }
  .custom-checkbox {
    margin-right: 5px;
    padding-top: 7px;
    /deep/ .custom-control-label::before,
    /deep/ .custom-control-label::after {
      top: -4px !important;
    }
  }
  input {
    width: auto;
  }
  /deep/ .el-date-editor {
    &.el-input,
    &.el-input__inner {
      width: 100%;
    }
  }
}

.submit-area {
  margin-top: 15px;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 30px;
}

.btn-light,
.btn-light:not([disabled]):not(.disabled) {
  &:hover,
  &:active {
    color: $color-grey;
    background-color: transparent;
    border: none;
  }
}

.drawer-modal /deep/ {
  .modal-dialog {
    max-width: 500px !important;
  }
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
}
</style>
