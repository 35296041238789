<template>
  <div id="aula-posts" class="page-layout-padding" :data-layout="postPageLayout">
    <alerts
      v-if="parent !== parentTypes.GROUP"
      class="mb-4"
      :class="{ 'p-2': isMobile }"
      :parent="parent"
      :filtered-areas="notificationAreas"
    />
    <div v-if="!isMobile" class="pb-2">
      <div v-if="parent !== parentTypes.NOTICE_BOARDS" class="post-header-container">
        <div class="posts-header">
          <h1 class="posts-title" tabindex="-1" :aria-label="'ARIA_LABEL_POSTS' | fromTextKey">
            {{ 'POSTS_PAGETITLE' | fromTextKey }}
          </h1>
          <b-nav v-if="parent === parentTypes.PROFILE && showCreateButton">
            <b-nav-item-dropdown class="dropdown-select" right no-caret>
              <template slot="button-content">
                <b-btn size="lg">
                  {{ 'POSTS_CREATE' | fromTextKey }}
                  <span class="small-icon icon-Aula_close m-4" aria-hidden="true" />
                  <span class="small-icon icon-Aula_down-arrow m-4" />
                </b-btn>
              </template>
              <template v-for="(item, i) in multiButtonItems">
                <b-dropdown-item
                  v-if="hasOneOfTheFollowingPermissions(item.permissions) && hasModule(item.module)"
                  :key="i"
                  role="button"
                  @click="executeMethodDynamically(item.action)"
                >
                  <i v-if="!isEarlyStudent(profile)" :class="item.icon" />
                  <img v-else class="menu-icon" :src="'static/icons/' + item.icon + '_child.svg'" />
                  <span>{{ item.text | fromTextKey }}</span>
                </b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
          </b-nav>
          <b-btn
            v-else-if="parent === parentTypes.GROUP && showCreateButton"
            class="tool-link"
            variant="link"
            @click="newInlinePost"
          >
            <i class="icon icon-Aula_plus in-circle" />
            {{ 'POSTS_CREATE' | fromTextKey }}
          </b-btn>
        </div>
        <AulaNavigationToolbar class="tabs-nav config-sections">
          <AulaButtons class="h-100 tabs-buttons">
            <AulaButton
              v-for="(section, i) in configSections"
              :key="i"
              class="tab-item text-uppercase"
              fill="clear"
              :class="{ 'active-tab': section.filter === chosenFilter }"
              :aria-selected="section.filter === chosenFilter"
              @click="onTabItemClicked(section)"
            >
              <template v-if="section.icon"><i :class="section.icon" /> - </template>
              <span :aria-label="getFilterAriaLabel(section.name)" class="sr-only" />
              <span aria-hidden="true">{{ section.name | fromTextKey }}</span>
            </AulaButton>
          </AulaButtons>
        </AulaNavigationToolbar>
      </div>
      <ObservingContainer v-if="!isPreviewMode" :options="observerOptions">
        <PostList :posts="currentPosts" @edit="editPost" @delete="showDeleteModal" @report="reportPostClicked" />
        <aula-spinner v-if="loading" />
        <ObservedTarget v-if="currentPosts.length > 0" @onIntersecting="onBottomReached" />
      </ObservingContainer>
      <template v-if="posts[parent] && posts[parent].length === 0 && !loading && !isPreviewMode">
        {{ noPosts }}
      </template>
    </div>
    <div v-if="isMobile">
      <transition name="slide-only" appear>
        <b-navbar v-if="parent == parentTypes.PROFILE && !isPreviewMode" class="mobile-page-navbar" :toggleable="false">
          <b-navbar-nav>
            <b-nav-item
              :class="postPageComponent == 'overview' ? 'active' : ''"
              :aria-selected="postPageComponent == 'overview'"
              @click="postPageComponent = 'overview'"
            >
              {{ 'POSTS_TAB_OVERVIEW' | fromTextKey }}
            </b-nav-item>
            <b-nav-item
              :class="postPageComponent == 'myday' ? 'active' : ''"
              :aria-selected="postPageComponent == 'myday'"
              @click="postPageComponent = 'myday'"
            >
              {{ 'POSTS_TAB_MYDAY' | fromTextKey }}
            </b-nav-item>
            <b-nav-item
              :class="postPageComponent == 'planning' ? 'active' : ''"
              :aria-selected="postPageComponent == 'planning'"
              @click="postPageComponent = 'planning'"
            >
              {{ 'POSTS_TAB_PLANNING' | fromTextKey }}
            </b-nav-item>
            <b-nav-item
              :class="postPageComponent == 'birthdays' ? 'active' : ''"
              :aria-selected="postPageComponent == 'birthdays'"
              @click="postPageComponent = 'birthdays'"
            >
              {{ 'POSTS_TAB_BIRTHDAY' | fromTextKey }}
            </b-nav-item>
            <b-nav-item
              :class="postPageComponent == 'widgets' ? 'active' : ''"
              :aria-selected="postPageComponent == 'widgets'"
              @click="postPageComponent = 'widgets'"
            >
              {{ 'POSTS_TAB_WIDGETS' | fromTextKey }}
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      </transition>
      <div class="post-item-area">
        <multi-button-mobile
          v-if="parent === parentTypes.PROFILE && showCreateButton"
          :items="multiButtonItems"
          @newPost="newPost()"
          @newEvent="newEvent()"
          @newMessage="newMessage()"
          @newMeetingEvent="newMeetingEvent()"
          @checkStepUp="checkStepUp()"
          @checkStepUpForNote="checkStepUpForNote()"
          @newAlbum="newAlbum()"
        />
        <multi-button-mobile
          v-if="
            parent === parentTypes.GROUP &&
            group != null &&
            (hasPermissionGroup(permissionEnum.WRITE_POST, group.id) ||
              hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS))
          "
          :items="multiButtonGroupItems"
          @newPost="newInlinePost()"
        />
        <div v-if="postPageComponent == 'overview'">
          <div v-if="!isOnNoticeBoard" class="config-sections" :class="{ 'group-page': parent === parentTypes.GROUP }">
            <b-dropdown class="dropdown-select" right>
              <template slot="button-content">
                {{ 'POSTS_FILTER_BUTTON' | fromTextKey }}
                <i class="icon icon-Aula_down-arrow" />
              </template>
              <b-dropdown-item
                v-for="(section, i) in configSections"
                :key="i"
                :active="section.filter == chosenFilter"
                :aria-selected="section.filter == chosenFilter"
                @click="onTabItemClicked(section)"
              >
                <span v-if="section.icon" :class="section.icon" />
                <template v-if="section.icon"> - </template>
                {{ section.name | fromTextKey }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <ObservingContainer :options="observerOptions">
            <PostList :posts="currentPosts" @edit="editPost" @delete="showDeleteModal" @report="reportPostClicked" />
            <aula-spinner v-if="loading" />
            <ObservedTarget v-if="currentPosts.length > 0" @onIntersecting="onBottomReached" />
          </ObservingContainer>
          <template v-if="posts[parent] && posts[parent].length === 0 && !loading">
            <div class="m-3">
              {{ noPosts }}
            </div>
          </template>
        </div>
        <important-date-overview v-if="postPageComponent == 'planning'" :is-aside="false" />
        <daily-overview v-if="postPageComponent == 'myday'" :is-aside="false" />
        <birthdays v-if="postPageComponent == 'birthdays'" class="m-3" />
        <widget-placeholder v-if="postPageComponent == 'widgets'" :list-of-widgets="getListWidgets" placement="full" />
      </div>
    </div>
    <aula-modal ref="warningPreventModal" :show-cancel="false" @okClicked="$refs.warningPreventModal.hide()">
      {{ 'POST_CREATE_CONTENT_WARNING_POST' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="warningDialogVisible" :show-cancel="false" @okClicked="$refs.warningDialogVisible.hide()">
      {{ 'POST_CREATE_CONTENT_WARNING_POST' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteThisPost()"
    >
      {{ 'POST_DELETE_POST_INFO' | fromTextKey }}<br />
      {{ 'POST_DELETE_POST_INFO_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="reportContentModal"
      ok-text="REPORTED_CONTENT_SEND"
      header-text="REPORTED_CONTENT_HEADER"
      @okClicked="saveReportPost()"
      @cancelClicked="
        $refs.reportContentModal.hide();
        $refs.cancelReportModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_WRITE_A_REASON' | fromTextKey }}
      <span class="mandatory">*</span>
      <b-form-textarea
        v-model="reportedContentReason"
        :placeholder="'REPORTED_CONTENT_FIELDS_REASON' | fromTextKey"
        @input="showWarningReasonLength = false"
      />
      <b-alert variant="danger" :show="showWarningReasonLength">
        {{ warningReasonLength | fromTextKey }}
      </b-alert>
      {{ 'REPORTED_CONTENT_NOTE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelReportModal"
      @okClicked="$refs.cancelReportModal.hide()"
      @cancelClicked="
        $refs.cancelReportModal.hide();
        $refs.reportContentModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_1' | fromTextKey }}<br />
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelCommentModal"
      ok-text="YES"
      @okClicked="
        updateWritingComment(false);
        nextRoute();
      "
      @cancelClicked="$refs.cancelCommentModal.hide()"
    >
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT' | fromTextKey }}<br />
      {{ 'COMMENTS_CONFIRM_CANCEL_COMMENT_2' | fromTextKey }}
    </aula-modal>
    <drawer :show="drawerShow" @change-show="changeDrawerShow">
      <template slot="drawer-title">
        <div class="title">
          <i class="icon-Aula_calendar hide-tablet-down mr-3" aria-hidden="true" style="font-size: 1.3em" />
          <template v-if="editPostId">
            {{ 'POSTS_EDIT_DRAWER_TITLE' | fromTextKey }}
          </template>
          <template v-else>
            {{ 'POSTS_NEW_DRAWER_TITLE' | fromTextKey }}
          </template>
        </div>
      </template>
      <template slot="drawer-content">
        <new-post
          v-if="drawerShow"
          :id="editPostId"
          :parent="parent"
          @cancel="closeDrawer"
          @postSubmitted="postSubmitted"
        />
      </template>
    </drawer>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { institutionRole } from '../../../shared/enums/institutionRole';
import NewPost from './NewPost.vue';
import Birthdays from '../shared/Birthdays.vue';
import moment from 'moment-timezone';
import Alerts from '../../../shared/components/Alerts.vue';
import MultiButtonMobile from '../shared/MultiButtonMobile.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { displayMode } from '../../../shared/enums/displayMode';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import ImportantDateOverview from '../shared/ImportantDateOverview';
import DailyOverview from '../shared/DailyOverview';
import WidgetPlaceholder from '../widgets/WidgetPlaceholder';
import Vue from 'vue';
import { institutionTypes } from '../../../shared/enums/institutionTypes';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import AulaNavigationToolbar from '../../../shared/components/navigation/AulaNavigationToolbar';
import AulaButton from '../../../shared/components/AulaButton';
import AulaButtons from '../../../shared/components/AulaButtons';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';
import { isEarlyStudent } from '../../../shared/functions/is-early-student';
import { postFilterTypes } from '../../../shared/enums/postFilterTypes';
import PostList from './PostList.vue';

export default {
  components: {
    PostList,
    ObservedTarget,
    ObservingContainer,
    AulaButtons,
    AulaButton,
    AulaNavigationToolbar,
    DailyOverview,
    ImportantDateOverview,
    alerts: Alerts,
    NewPost,
    Birthdays,
    MultiButtonMobile,
    WidgetPlaceholder,
  },
  provide() {
    return {
      [providerKeyTypes.onRemoveMediaFromAlbum]: attachment => {
        const post = this.posts[this.parent].find(post => post.id == this.$route.params.postId);
        this.removeAttachmentFromPost({ parent: this.parent, post, attachment });
      },
      [providerKeyTypes.postObserver]: () => this.postObserver,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.writingComment) {
      this.nextRoute = next;
      this.$refs.cancelCommentModal.show();
    } else {
      next();
    }
  },
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
  },
  data: function () {
    return {
      loading: false,
      editPostId: undefined,
      loadingMore: false,
      moment: moment,
      permissionEnum: permissionEnum,
      institutionRole: institutionRole,
      portalRoles: portalRoles,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      drawerShow: false,
      title: 'Seneste',
      newPostTitle: '',
      newPostText: '',
      newPostSharedWith: '',
      showUsersToShareWith: false,
      chosenFilter: postFilterTypes.ALL,
      reportedContentReason: '',
      nextRoute: '',
      showWarningReasonLength: false,
      warningReasonLength: null,
      postIdToDelete: '',
      postIndex: 0,
      postObserver: null,
      postPageComponent: 'overview',
    };
  },
  computed: {
    ...mapGetters({
      date: types.GET_DATE,
      time: types.GET_TIME,
      posts: types.GET_POSTS,
      morePostsExist: types.GET_MORE_POSTS_EXIST,
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      userGroups: types.GET_USER_GROUPS,
      hasPermission: types.HAS_PERMISSION,
      group: types.GET_ACTIVE_GROUP,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      moduleWidgetConfig: types.GET_PAGE_CONFIGURATION,
      menuItems: types.GET_MENU_ITEMS,
      isMobile: types.GET_IS_MOBILE,
      writingComment: types.GET_WRITING_COMMENT,
      notifications: types.GET_NOTIFICATIONS,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
    }),
    showCreateButton() {
      if (this.parent === parentTypes.PROFILE) {
        const isBlockCommunication = this.profile.communicationBlock && !this.hasNonBlockingProfiles;
        return !isBlockCommunication && !this.isPreviewMode;
      }
      if (this.parent === parentTypes.GROUP) {
        const activeInstitutionProfile = this.institutions.find(
          institution => institution.institutionCode === this.group.institutionCode
        );
        return (
          !activeInstitutionProfile.communicationBlock &&
          this.group != null &&
          (this.hasPermissionGroup(permissionEnum.WRITE_POST, this.group.id) ||
            this.hasPermissionOnInstitution(
              permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
              this.group.institutionCode
            ))
        );
      }
      return false;
    },
    notificationAreas() {
      return [
        notificationAreas.POSTS,
        notificationAreas.CALENDAR,
        notificationAreas.PRESENCE,
        notificationAreas.MESSAGES,
      ];
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
    currentPosts() {
      return this.posts[this.parent] || [];
    },
    isOnNoticeBoard() {
      return this.$route.name === 'notice-board';
    },
    postPageLayout() {
      if (this.parent === parentTypes.GROUP) {
        return '';
      }
      return 'posts';
    },
    configSections() {
      const defaultSections = [
        {
          name: 'POSTS_FILTER_ALL',
          filter: postFilterTypes.ALL,
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP],
        },
        {
          name: 'POSTS_FILTER_UNREAD',
          filter: postFilterTypes.UNREAD,
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP],
        },
        {
          name: 'POSTS_FILTER_MARKED',
          filter: postFilterTypes.MARKED,
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP],
        },
        {
          name: 'POSTS_FILTER_OTHER_EMPLOYEES',
          filter: postFilterTypes.EMPLOYEES,
          portalRoles: [portalRoles.EMPLOYEE],
        },
        {
          name: 'POSTS_FILTER_EMPLOYEES',
          filter: postFilterTypes.EMPLOYEES,
          portalRoles: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP],
        },
        {
          name: 'POSTS_FILTER_OTHER_PARENTS',
          filter: postFilterTypes.PARENTS,
          portalRoles: [portalRoles.GUARDIAN, portalRoles.OTP],
        },
        {
          name: 'POSTS_FILTER_OTHER_STUDENTS',
          filter: postFilterTypes.STUDENTS,
          portalRoles: [portalRoles.CHILD],
        },
        {
          name: 'POSTS_FILTER_OWN',
          filter: postFilterTypes.OWN_POST,
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN],
        },
      ];
      return defaultSections.filter(section => section.portalRoles.includes(this.profile.role));
    },
    noPosts() {
      const suffix = this.chosenFilter === 'all' ? '' : '_' + toUpper(this.chosenFilter);
      return this.$options.filters.fromTextKey('NO_POSTS' + suffix);
    },
    multiButtonItems() {
      const multiButtonItems = [
        {
          icon: 'icon-Aula_home',
          text: 'POSTS_MENU_LABEL_HOME',
          label: 'POSTS_MENU_LABEL_CREATE_POST',
          action: 'newPost',
          permissions: [permissionEnum.WRITE_POST, permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS],
          module: moduleTypes.OVERVIEW,
        },
        {
          icon: 'icon-Aula_calendar',
          text: 'POSTS_MENU_LABEL_CALENDARPOST',
          label: 'POSTS_MENU_LABEL_CREATE_CALENDARPOST',
          action: 'newEvent',
          permissions: [permissionEnum.HANDLE_EVENTS],
          module: moduleTypes.CALENDAR,
        },
        {
          icon: 'icon-Aula_calendar',
          text: 'POSTS_CREATE_LABEL_MEETING',
          label: 'POSTS_MENU_LABEL_CREATE_PARENT_MEETING_POST',
          action: 'newMeetingEvent',
          permissions: [
            permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL,
            permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE,
            permissionEnum.HANDLE_PERFORMANCE_MEETING,
          ],
          module: moduleTypes.CALENDAR,
        },
        {
          icon: isEarlyStudent(this.profile) ? 'icon-Aula_write' : 'icon-Aula_conversation',
          text: 'POSTS_MENU_LABEL_NEW_MESSAGE',
          label: 'POSTS_MENU_LABEL_CREATE_MESSAGE',
          action: 'newMessage',
          permissions: [permissionEnum.WRITE_MESSAGE],
          module: moduleTypes.MESSAGES,
        },
        {
          icon: 'icon-Aula_picture',
          text: 'POSTS_MENU_LABEL_ALBUM',
          label: 'POSTS_MENU_LABEL_CREATE_ALBUM',
          action: 'newAlbum',
          permissions: [permissionEnum.HANDLE_MEDIA],
          module: moduleTypes.GALLERY,
        },
        {
          icon: 'icon-Aula_paper',
          text: 'POSTS_MENU_LABEL_SECURE_FILE_SHARING',
          label: 'POSTS_MENU_LABEL_SECURE_FILE_SHARING',
          action: 'checkStepUp',
          permissions: [permissionEnum.HANDLE_SECURE_FILES],
          module: moduleTypes.DOCUMENTS,
        },
        {
          icon: 'icon-Aula_paper',
          text: 'POSTS_MENU_LABEL_SECURE_FILE_NOTE',
          label: 'POSTS_MENU_LABEL_SECURE_FILE_NOTE',
          action: 'checkStepUpForNote',
          permissions: [permissionEnum.HANDLE_SECURE_FILES],
          module: moduleTypes.DOCUMENTS,
        },
      ];

      return multiButtonItems;
    },
    multiButtonGroupItems() {
      const multiButtonItems = [
        {
          icon: 'icon-Aula_home',
          text: 'POSTS_MENU_LABEL_HOME',
          label: 'POSTS_MENU_LABEL_CREATE_POST',
          action: 'newPost',
        },
      ];
      return multiButtonItems;
    },
    getListWidgets() {
      return this.menuItems != undefined ? this.menuItems[moduleWidgetPlacements.RIGHT_OF_OVERVIEW] : [];
    },
  },
  watch: {
    activeChildren() {
      if (this.profile.role === portalRoles.GUARDIAN || this.profile.role === portalRoles.OTP) {
        this.reload();
      }
    },
    activeInstitutions() {
      if (
        this.institutions.length > 1 &&
        this.activeInstitutions.length > 0 &&
        this.profile.role === portalRoles.EMPLOYEE
      ) {
        this.reload();
      }
    },
    profile(to, from) {
      if (!isEqual(to, from) && !this.loading) {
        this.reload();
      }
    },
    userGroups(to) {
      if (to.length && !this.loading) {
        this.reload();
      }
    },
    group(to) {
      isEmpty(to) && this.reload();
    },
  },
  created() {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const postId = entry.target.dataset.postId;
          const postNotifications = this.notifications
            .filter(
              notification => notification.notificationArea === notificationAreas.POSTS && notification.postId == postId
            )
            .map(notification => ({
              notificationId: notification.notificationId,
              institutionProfileId: notification.institutionProfileId,
            }));
          if (postNotifications.length > 0) {
            this.deleteNotifications({ notifications: postNotifications, skipMutate: true });
          }
          observer.unobserve(entry.target);
        }
      });
    };

    this.postObserver = new IntersectionObserver(observerCallback);
  },
  mounted() {
    if (this.parent !== parentTypes.PROFILE && this.$router.currentRoute.query.filter) {
      this.chosenFilter = this.$router.currentRoute.query.filter;
    }
    if (!this.loading) {
      this.getPosts();
    }
    if (!this.isMobile && this.parent == parentTypes.PROFILE) {
      setTimeout(() => {
        const postTitle = this.$el.querySelector('.post-header-container .posts-title');
        if (postTitle) {
          postTitle.focus();
        }
      }, 500);
    }
  },
  destroyed() {
    this.postObserver.disconnect();
    this.resetPosts();
  },
  methods: {
    isEarlyStudent,
    onBottomReached() {
      if (this.loading || !this.morePostsExist) {
        return;
      }
      this.getPosts();
    },
    onTabItemClicked(section) {
      this.chosenFilter = section.filter;
      this.reload();
    },
    hasOneOfTheFollowingPermissions(permissions) {
      if (!permissions) {
        return true;
      }
      for (const permission of permissions) {
        if (this.hasPermission(permission)) {
          return true;
        }
      }
      return false;
    },
    hasModule(moduleType) {
      if (!moduleType) {
        return true;
      }
      if (this.moduleWidgetConfig.moduleConfigurations != null) {
        for (const item of this.moduleWidgetConfig.moduleConfigurations) {
          if (item.module.type == moduleType && item.aggregatedDisplayMode == displayMode.SHOWN) {
            return true;
          }
        }
      }
      return false;
    },
    executeMethodDynamically(action) {
      this[action]();
    },
    getFilterAriaLabel(name) {
      return Vue.filter('fromTextKey')('ARIA_LABEL_SHOW_POSTS_FROM') + Vue.filter('fromTextKey')(name);
    },
    checkStepUp() {
      if (this.isSteppedUp) {
        this.$router.push({
          name: 'documentsSecureNew',
          params: { institutionCode: this.profile.institutionCode },
        });
      } else {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      }
    },
    checkStepUpForNote() {
      if (this.isSteppedUp) {
        this.$router.push({
          name: 'createDocumentNote',
          params: { institutionCode: this.profile.institutionCode },
        });
      } else {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      }
    },
    newAlbum() {
      this.$router.push({ name: 'createAlbum' });
    },
    newEvent() {
      this.$router.push('/kalender/opretbegivenhed');
    },
    newPost() {
      this.editPostId = undefined;
      if (!this.isPreviewMode) {
        if (
          !(
            this.hasPermission(permissionEnum.WRITE_POST) ||
            this.hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS)
          )
        ) {
          this.$refs.warningPreventModal.show();
        } else {
          this.drawerShow = true;
        }
      }
    },
    newMessage() {
      this.$router.push({ name: 'newMessage' });
    },
    newMeetingEvent() {
      this.$router.push({ name: 'createMeetingEvent' });
    },
    editPost(postId) {
      if (this.parent === parentTypes.PROFILE) {
        if (!this.isPreviewMode) {
          if (
            !(
              this.hasPermission(permissionEnum.WRITE_POST) ||
              this.hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS)
            )
          ) {
            this.$refs.warningPreventModal.show();
          } else {
            this.editPostId = postId;
            this.drawerShow = true;
          }
        }
      } else {
        this.$router.push({ name: 'group-edit-post', params: { id: postId }, query: { filter: this.chosenFilter } });
      }
    },
    reportPostClicked(postId) {
      const isBlockCommunication = this.profile.communicationBlock && !this.hasNonBlockingProfiles;
      if (isBlockCommunication) {
        this.$refs.blockedCommunicationModal.show();
      } else {
        this.postIdToDelete = postId;
        this.reportedContentReason = '';
        this.resetCommentForm();
        this.$refs.reportContentModal.show();
      }
    },
    resetCommentForm() {
      this.showWarningReasonLength = false;
      this.warningReasonLength = null;
    },
    saveReportPost() {
      if (this.reportedContentReason.length < 10 || this.reportedContentReason.length > 255) {
        this.showWarningReasonLength = true;
        this.warningReasonLength =
          this.reportedContentReason.length < 10
            ? 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT'
            : 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG';
      } else {
        this.resetCommentForm();
        this.reportPost({
          id: this.postIdToDelete,
          reportReason: this.reportedContentReason,
        }).then(() => {
          this.$refs.reportContentModal.hide();
          this.reportedContentReason = '';
          this.reload();
        });
      }
    },
    changeDrawerShow() {
      this.drawerShow = !this.drawerShow;
    },
    closeDrawer() {
      this.drawerShow = false;
      this.editPostId = undefined;
    },
    postSubmitted() {
      this.closeDrawer();
      this.reload();
    },
    newInlinePost() {
      if (!this.isPreviewMode) {
        if (
          !(
            this.hasPermission(permissionEnum.WRITE_POST) ||
            this.hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS)
          )
        ) {
          this.$refs.warningDialogVisible.show();
        } else {
          this.$router.push({ name: 'group-create-post', query: { filter: this.chosenFilter } });
        }
      }
    },
    async getPosts() {
      const parent = this.parent;
      let institutionProfileIds = [];
      let groupId = null;
      let isImportant = null;
      let ownPost = null;
      let isUnread = null;
      let creatorPortalRole = null;
      const limit = 10;

      if (this.profile.role == portalRoles.EMPLOYEE) {
        institutionProfileIds = this.institutions
          .filter(inst => this.activeInstitutions.includes(inst.institutionCode))
          .map(inst => inst.institutionProfileId);
      } else if (this.profile.role == portalRoles.GUARDIAN) {
        institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
        institutionProfileIds = institutionProfileIds.concat(
          this.children.filter(child => this.activeChildren.includes(child.id)).map(child => child.id)
        );
      } else if (this.profile.role == portalRoles.CHILD) {
        institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
      } else if (this.profile.role == portalRoles.OTP) {
        institutionProfileIds = this.activeChildren;
        institutionProfileIds = institutionProfileIds.concat(
          this.institutions
            .filter(inst => inst.children.filter(child => this.activeChildren.includes(child.id)).length > 0)
            .map(inst => inst.institutionProfileId)
        );
      }

      if (this.parent === parentTypes.GROUP) {
        groupId = this.$route.params.groupId;
      }

      this.loading = true;

      switch (this.chosenFilter) {
        case postFilterTypes.MARKED:
          isImportant = true;
          break;
        case postFilterTypes.UNREAD:
          isUnread = true;
          break;
        case postFilterTypes.EMPLOYEES:
          creatorPortalRole = portalRoles.EMPLOYEE;
          break;
        case postFilterTypes.STUDENTS:
          creatorPortalRole = portalRoles.CHILD;
          break;
        case postFilterTypes.PARENTS:
          creatorPortalRole = portalRoles.GUARDIAN;
          break;
        case postFilterTypes.OWN_POST:
          ownPost = true;
          break;
      }

      const payload = {
        groupId,
        parent,
        index: this.postIndex,
        isImportant,
        isUnread,
        ownPost,
        creatorPortalRole,
        institutionProfileIds,
        limit,
      };
      if (Cookie.Read('fromAdminGroup') && this.parent == parentTypes.GROUP && groupId != null) {
        if (
          this.hasPermissionOnInstitution(this.permissionEnum.SEARCH_ACCESS_GROUPS, this.group.institutionCode) ||
          (this.hasPermission(this.permissionEnum.SEARCH_ACCESS_GROUPS) &&
            this.institutions.find(institution => institution.institutionType == institutionTypes.MUNICIPALITY))
        ) {
          await this.loadAdminPosts(payload);
        }
      } else if (this.parent === parentTypes.NOTICE_BOARDS) {
        await this.loadNoticeBoardPosts(payload);
      } else {
        await this.loadPosts(payload);
      }
      this.loading = false;
      this.postIndex += limit;
    },
    deleteThisPost() {
      this.deletePost({ id: this.postIdToDelete }).then(() => {
        this.$refs.deleteModal.hide();
        this.reload();
      });
    },
    showDeleteModal(id) {
      this.postIdToDelete = id;
      this.$refs.deleteModal.show();
    },
    reload() {
      this.postIndex = 0;
      this.resetPosts();
      this.getPosts();
    },
    ...mapActions({
      toggleEventForm: types.TOGGLE_EVENT_FORM,
      loadPosts: types.ACTION_GET_POSTS,
      loadAdminPosts: types.ACTION_GET_POSTS_ADMIN,
      loadNoticeBoardPosts: types.LOAD_NOTICE_BOARD_POSTS,
      deletePost: types.ACTIONS_DELETE_POST,
      reportPost: types.REPORT_POST,
      removeAttachmentFromPost: types.SLICE_ATTACHMENT_FROM_POST,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      updateWritingComment: types.MUTATE_WRITING_COMMENT,
      resetPosts: types.MUTATE_EMPTY_POSTS,
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/posts/_posts.scss';

#aula-posts {
  > * {
    max-width: 60rem;
    margin: auto;
  }
  .posts-header {
    --dropdown-select-padding: 0;
  }
}
.primary.cancel-link {
  background: $color-primary-light;
  color: $color-white;
}

.hide-tablet-up {
  .post-item-area {
    overflow-x: hidden;
  }
}

img.menu-icon {
  width: 28px;
  margin-left: -4px;
  margin-top: -4px;
}

.post-item-area {
  @include breakpoint-lg-down() {
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 200px;
  }
}
.config-sections {
  .dropdown-select {
    /deep/ .dropdown-toggle {
      z-index: 1003;
    }
    /deep/ .dropdown-menu-right {
      z-index: 1002;
    }
  }
}
</style>
