<template>
  <b-form class="drawer-container">
    <b-row v-if="isVisibleRepeatingEventAlert">
      <b-col>
        <AulaAlert>
          {{ repeatingEventAlert }}
        </AulaAlert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- INSTITUTION START -->
        <template
          v-if="institutionsThatCanBeSeen.length > 1 && delegatedContext.institutionProfileId == null && !isCoOrganizer"
        >
          <b-row>
            <b-col class="form-margin-bottom col-12 col-md-6">
              <label>{{ 'CALENDAR_TITLE_CHOOSE_INSTITUTION' | fromTextKey }}</label>
              <aula-select
                v-model="eventForm.institutionCode"
                placeholder="Select"
                :disabled="eventForm.type == eventTypes.LESSON || isEditMode"
                @change="handleResetRecipientsInput"
              >
                <aula-option
                  v-for="institution in institutionsThatCanBeSeen"
                  :id="institution.institutionCode"
                  :key="institution.institutionCode"
                  :label="institution.name"
                  :value="institution.institutionCode"
                />
              </aula-select>
            </b-col>
          </b-row>
        </template>
        <!-- INSTITUTION END -->
        <b-row>
          <!-- SUBJECT START -->
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_TITLE' | fromTextKey }} <span class="mandatory">*</span></label>
            <b-form-input
              id="eventTitle"
              v-model="eventForm.title"
              :placeholder="'CALENDAR_LABEL_EVENT_PLACEHOLDER_TITLE' | fromTextKey"
              type="text"
              :disabled="hasLimitedEditRight || isHandlingOthersPrivateEvent"
              :class="showMissingTitleAlert ? 'red-border' : ''"
              @input="showMissingTitleAlert = false"
            />
            <b-alert variant="danger" :show="showMissingTitleAlert">
              {{ 'CALENDAR_ALERT_TITLE' | fromTextKey }}
            </b-alert>
          </b-col>
          <!-- SUBJECT END -->
          <!-- TYPE START -->
          <b-col v-if="profile.role === portalRoles.EMPLOYEE" class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_TYPE' | fromTextKey }}</label>
            <b-form-input
              v-if="isHandlingOthersPrivateEvent"
              type="text"
              disabled
              :value="'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey"
            />
            <aula-select v-else v-model="eventForm.type" :disabled="hasLimitedEditRight">
              <template v-for="eventType in basicEventTypes">
                <aula-option
                  v-if="eventType.type !== eventTypes.LESSON || eventForm.type === eventTypes.LESSON"
                  :key="eventType.type"
                  :label="eventType.label"
                  :value="eventType.type"
                />
              </template>
            </aula-select>
          </b-col>
          <!-- TYPE END -->
          <!-- CLASS START -->
          <b-col
            v-if="eventForm.type === eventTypes.LESSON && eventForm.invitedGroups != null"
            class="form-margin-bottom col-12 col-md-6"
          >
            <label>{{ 'CALENDAR_LABEL_EVENT_CLASS' | fromTextKey }}</label>
            <b-form-input
              v-if="eventForm.invitedGroups.find(group => group.type === groupTypes.UNI) != null"
              disabled
              :value="invitedGroupsName"
            />
          </b-col>
          <!-- CLASS END -->
          <!-- LESSON START -->
          <b-col v-if="eventForm.type === eventTypes.LESSON" class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_TEACHER' | fromTextKey }}</label>
            <AulaSearchRecipients :existing-participants="primaryTeachers" disabled />
          </b-col>
          <!-- LESSON END -->
        </b-row>
        <b-row v-if="eventForm.type !== eventTypes.LESSON">
          <!-- PARTICIPANTS START -->
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>
              {{ 'CALENDAR_LABEL_EVENT_PERSONS' | fromTextKey }}
            </label>
            <help-box
              v-if="!hasPermission(permissionEnum.INVITE_TO_EVENT) || parent === parentTypes.GROUP"
              id="blocking-comunication"
              :title="Vue.filter('fromTextKey')('CALENDAR_RECIPIENT_NOT_ABLE_TO_ADD_OR_REMOVE_RECIPIENTS_TITLE')"
              :body="Vue.filter('fromTextKey')('CALENDAR_RECIPIENT_NOT_ABLE_TO_ADD_OR_REMOVE_RECIPIENTS_BODY')"
            />
            <b-form-input
              v-if="isHandlingOthersPrivateEvent"
              type="text"
              disabled
              :value="'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey"
            />
            <b-input-group v-else class="search-module" @click="checkUserWithBlockedCommunication()">
              <aula-search-recipients
                id="eventInvitees"
                :include-otp-from-guardian-in-sub-group="true"
                :reset-input="resetRecipientsInput"
                :disabled="!canInviteToEvent || hasLimitedEditRight"
                :existing-participants="existingInvitees"
                :invite-members-when-selecting-group="true"
                :invite-members-when-selecting-group-portal-role="true"
                :invite-group-when-adding-members="true"
                :close-dropdown-after-select="false"
                :enable-relatives-for-students="true"
                :institution-code="eventForm.institutionCode"
                :scope-employees-to-institution="false"
                placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
                hover-text="ARIA_LABEL_CALENDAR_SEARCH_FOR_INVITEES"
                :include-self="true"
                :from-module="moduleTypes.EVENT"
                :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
                :select-all="false"
                @emitSelected="updateInvitees"
              />
              <b-input-group-addon
                :class="{
                  disabledAddon: !hasPermission(permissionEnum.INVITE_TO_EVENT),
                  disabledAddon: parent == parentTypes.GROUP,
                }"
              >
                <i class="icon-Aula_search input-group-search-addon" />
              </b-input-group-addon>
            </b-input-group>
            <div v-if="invitedGroupNames !== ''" class="mt-2">
              {{ 'CALENDAR_INVITED_GROUPS' | fromTextKey }}
              <span class="invited-group-names">{{ invitedGroupNames }}</span>
            </div>
          </b-col>
          <!-- PARTICIPANTS END -->
          <!-- CO ORGANIZER START -->
          <b-col
            v-if="(canEditCoOrganizers || isHandlingOthersPrivateEvent) && !isRepeatingOccurrence"
            class="form-margin-bottom col-12 col-md-6"
          >
            <label for="eventOrganizers">{{ 'CALENDAR_LABEL_ORGANIZERS' | fromTextKey }}</label>
            <b-form-input
              v-if="isHandlingOthersPrivateEvent"
              type="text"
              disabled
              :value="'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey"
            />
            <b-input-group v-else class="search-module">
              <aula-search-recipients
                id="eventOrganizers"
                :doc-types-api="[docTypes.PROFILE]"
                :portal-roles="[portalRoles.EMPLOYEE]"
                :existing-participants="selectedOrganizers"
                :is-multiple-institution-profile-allowed="false"
                :disabled="isCommunicationBlock || hasLimitedEditRight"
                :hidden-participants="hiddenOrganizers"
                :close-dropdown-after-select="false"
                :institution-code="eventForm.institutionCode"
                :scope-employees-to-institution="true"
                placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
                hover-text="ARIA_LABEL_CALENDAR_SEARCH_FOR_ORGANIZERS"
                :include-self="false"
                :from-module="moduleTypes.EVENT"
                :select-all="false"
                @emitSelected="checkCoOrganizerList"
              />
              <b-input-group-addon>
                <i class="icon-Aula_search input-group-search-addon" />
              </b-input-group-addon>
            </b-input-group>
          </b-col>
          <!-- CO ORGANIZER END -->
        </b-row>
        <hr class="line" :class="eventForm.type === eventTypes.LESSON ? 'mb-0' : ''" />
        <!-- ALL DAY START -->
        <b-row v-if="eventForm.type !== eventTypes.LESSON">
          <b-col class="col-12">
            <b-form-checkbox
              v-if="!isMobile"
              id="allDay"
              v-model="eventForm.allDay"
              :disabled="hasLimitedEditRight"
              @change="allDayChange"
            >
              {{ 'CALENDAR_LABEL_EVENT_ALL_DAY' | fromTextKey }}
            </b-form-checkbox>
            <div v-else class="mobile-switch">
              <label>{{ 'CALENDAR_LABEL_EVENT_ALL_DAY' | fromTextKey }}</label>
              <el-switch
                v-model="eventForm.allDay"
                :aria-label="'CALENDAR_LABEL_EVENT_ALL_DAY' | fromTextKey"
                :width="(width = 48)"
                class="pull-right"
                :disabled="hasLimitedEditRight"
                @change="allDayChange"
              />
            </div>
          </b-col>
        </b-row>
        <!-- ALL DAY END -->
        <b-row>
          <b-col class="form-margin-bottom col-12 col-md-6">
            <!-- START DATE START-->
            <label>{{ 'CALENDAR_LABEL_EVENT_START' | fromTextKey }}</label>
            <b-row>
              <b-col cols="6">
                <el-date-picker
                  id="startDate"
                  v-model="startDate"
                  :format="$parent.defaultDateFormat"
                  :value-format="$parent.defaultDateValueFormat"
                  type="date"
                  :aria-label="'ARIA_LABEL_CALENDAR_START_DATE' | fromTextKey"
                  :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  :clearable="false"
                  :disabled="hasLimitedEditRight"
                  :class="{
                    'red-border': showMissingStartDateAlert || isBeforeFirstOccurrence || isAfterLastOccurrence,
                    elPickerActive: isStartDateClicked,
                  }"
                  @input="showMissingStartDateAlert = false"
                  @change="handleStartDateChanged"
                  @focus="
                    datePickerModifier();
                    isStartDateClicked = true;
                  "
                  @blur="checkValidDateTime('startDate')"
                />
                <b-alert variant="danger" :show="showMissingStartDateAlert">
                  {{ messageAlertStartDate }}
                </b-alert>
              </b-col>
              <b-col cols="6">
                <label for="startTime" class="sr-only">
                  {{ 'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey }}
                </label>
                <AulaTimepicker
                  id="startTime"
                  v-model="startTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                  :aria-label="'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey"
                  :disabled="eventForm.allDay || hasLimitedEditRight"
                  :class="{
                    'red-border': showMissingStartTimeAlert || showMissingStartDateAlert,
                  }"
                  hide-clear-button
                  :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                  advanced-keyboard
                  @input="
                    showMissingStartTimeAlert = false;
                    showMissingStartDateAlert = false;
                  "
                  @change="checkForExistingResourcesWhenChangingDateTime"
                  @open="setLayer(true)"
                  @close="setLayer(false)"
                />
                <b-alert variant="danger" :show="showMissingStartTimeAlert">
                  {{ 'CALENDAR_ALERT_START_TIME' | fromTextKey }}
                </b-alert>
              </b-col>
            </b-row>
            <!-- START DATE END-->
            <!-- END DATE START-->
            <label>{{ 'CALENDAR_LABEL_EVENT_END' | fromTextKey }}</label>
            <b-row>
              <b-col v-if="isVisibleEndDate" cols="6">
                <el-date-picker
                  id="endDate"
                  v-model="endDate"
                  :format="$parent.defaultDateFormat"
                  :value-format="$parent.defaultDateValueFormat"
                  type="date"
                  :picker-options="availableEndDate"
                  :clearable="false"
                  :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                  :aria-label="'ARIA_LABEL_CALENDAR_END_DATE' | fromTextKey"
                  :disabled="isDisabledEndDate"
                  :class="{
                    'red-border': showMissingEndDateAlert,
                    elPickerActive: isEndDateClicked,
                  }"
                  @change="checkForExistingResourcesWhenChangingDateTime"
                  @focus="
                    datePickerModifier();
                    isEndDateClicked = true;
                  "
                  @blur="checkValidDateTime('endDate')"
                />
                <b-alert variant="danger" :show="showMissingEndDateAlert">
                  {{ 'CALENDAR_ALERT_END_DATETIME' | fromTextKey }}
                </b-alert>
              </b-col>
              <b-col cols="6">
                <label class="sr-only" for="endTime">
                  {{ 'ARIA_LABEL_CALENDAR_END_TIME' | fromTextKey }}
                </label>
                <AulaTimepicker
                  id="endTime"
                  v-model="endTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                  :aria-label="'ARIA_LABEL_CALENDAR_END_TIME' | fromTextKey"
                  :disabled="eventForm.allDay || hasLimitedEditRight"
                  :class="{
                    'red-border': showMissingEndTimeAlert,
                  }"
                  hide-clear-button
                  :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                  advanced-keyboard
                  @input="showMissingEndTimeAlert = false"
                  @change="checkForExistingResourcesWhenChangingDateTime"
                  @open="setLayer(true)"
                  @close="setLayer(false)"
                />
                <b-alert variant="danger" :show="showMissingEndTimeAlert">
                  {{ 'CALENDAR_ALERT_END_DATETIME' | fromTextKey }}
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="isVisibleEventInThePastAlert" class="mt-2">
              <b-col>
                <b-alert class="m-0" variant="info" show>
                  {{ eventInThePastAlertText }}
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="isBeforeFirstOccurrence || isAfterLastOccurrence">
              <b-col>
                <b-alert class="m-0" variant="danger" show>
                  <template v-if="isBeforeFirstOccurrence">{{
                    'CALENDAR_ALERT_OCCURENCE_DATE_BEFORE_FIRST_OCCURRENCE' | fromTextKey
                  }}</template>
                  <template v-if="isAfterLastOccurrence">{{
                    'CALENDAR_ALERT_OCCURENCE_DATE_AFTER_LAST_OCCURRENCE' | fromTextKey
                  }}</template>
                </b-alert>
              </b-col>
            </b-row>
            <!-- END DATE END -->
          </b-col>
          <b-col class="form-margin-bottom col-12 col-md-6">
            <!-- REPEAT START -->
            <b-row>
              <b-col v-if="canEditRepeatingPattern" cols="12">
                <label>{{ 'CALENDAR_LABEL_EVENT_REPEAT' | fromTextKey }}</label>
                <aula-select
                  v-if="eventForm.repeating"
                  id="repeatEvent"
                  v-model="eventForm.repeating.pattern"
                  :disabled="(isEditMode && eventForm.repeating == null) || hasLimitedEditRight"
                  :class="showMissingSeriesEventAlert ? 'red-border' : ''"
                  @change="onRepeatingPatternChange"
                >
                  <template v-for="pattern in eventRepeatPatterns">
                    <aula-option
                      v-if="pattern.id"
                      :key="pattern.id"
                      :disabled="isEditMode && pattern.id === repeatingEventPatternEnum.NEVER"
                      :label="pattern.label"
                      :value="pattern.id"
                    >
                      <div @click="emitRepeatEventSelected(false, true)">
                        {{ pattern.label }}
                      </div>
                    </aula-option>
                  </template>
                </aula-select>
                <b-alert variant="danger" :show="showMissingSeriesEventAlert">
                  {{ 'CALENDAR_ALERT_EVENT_REPEAT' | fromTextKey }}
                </b-alert>
                <div class="el-dialog-container">
                  <el-dialog
                    v-if="showSeriesEventForm"
                    :title="seriesEventTitle"
                    :visible.sync="showSeriesEventForm"
                    :modal="(showModal = false)"
                    :width="seriesEventFormWidth"
                    :show-close="(showClose = false)"
                    :lock-scroll="true"
                    :close-on-click-modal="false"
                    top="0vh"
                  >
                    <div class="series_form">
                      <div class="form-inline">
                        {{ 'CALENDAR_LABEL_EVENT_START_LOWER' | fromTextKey }}
                        <el-date-picker
                          id="startDate"
                          v-model="startDate"
                          :format="$parent.defaultDateFormat"
                          :value-format="$parent.defaultDateValueFormat"
                          type="date"
                          class="maxDatePicker"
                          :aria-label="'ARIA_LABEL_CALENDAR_START_DATE' | fromTextKey"
                          :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                          :clearable="false"
                          :picker-options="{ firstDayOfWeek: 1 }"
                          :class="{
                            'red-border': showMissingStartDateAlert,
                            elPickerActive: isStartDateClicked,
                          }"
                          @input="showMissingStartDateAlert = false"
                          @change="checkForExistingResourcesWhenChangingDateTime"
                          @focus="datePickerModifier()"
                        />
                      </div>
                      <template v-if="eventForm.repeating.pattern === repeatingEventPatternEnum.DAILY">
                        <div class="form-inline">
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_EACH' | fromTextKey }}
                          <b-form-input
                            id="daily.interval"
                            v-model="daily.interval"
                            v-numeric
                            class="text_inline"
                            aria-describedby="daily.intervalFeedback"
                            :class="isClickedOK && invalidDailyInterval ? 'red-border' : ''"
                            @change="checkForExistingResourcesWhenChangingDateTime"
                          />
                          {{ 'DATETIME_DAY' | fromTextKey }}
                        </div>
                        <div class="form-inline">
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_END_AFTER' | fromTextKey }}
                          <b-form-input
                            id="daily.occurenceLimit"
                            v-model="daily.occurenceLimit"
                            v-numeric
                            class="text_inline"
                            :disabled="selectedMaxDate || eventNeverEnd"
                            aria-describedby="daily.occurenceLimitFeedback"
                            :class="isClickedOK && invalidDailyoccurenceLimit ? 'red-border' : ''"
                            @change="checkForExistingResourcesWhenChangingDateTime"
                          />
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_REPETITION' | fromTextKey }}
                          {{ 'OR' | fromTextKey }}
                        </div>
                        <b-alert
                          variant="danger"
                          :show="isClickedOK && (invalidDailyInterval || invalidDailyoccurenceLimit)"
                        >
                          {{
                            daily.occurenceLimit > 2000
                              ? 'CALENDAR_ALERT_EVENT_REPEAT_OCCURENCE_MAX'
                              : 'CALENDAR_ALERT_EVENT_REPEAT' | fromTextKey
                          }}
                        </b-alert>
                      </template>
                      <template v-if="eventForm.repeating.pattern === repeatingEventPatternEnum.WEEKLY">
                        <b-form-checkbox-group
                          id="checkboxes2"
                          v-model="weekly.weekdayMask"
                          name="flavour2"
                          class="margin-bottom"
                          :class="isClickedOK && invalidWeeklyWeekdayMask ? 'red-border' : ''"
                          @change="checkForExistingResourcesWhenChangingDateTime"
                        >
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox :value="weekdayEnum.MONDAY">
                                {{ 'DATETIME_MONDAY' | fromTextKey }}
                              </b-form-checkbox>
                              <b-form-checkbox :value="weekdayEnum.TUESDAY">
                                {{ 'DATETIME_TUESDAY' | fromTextKey }}
                              </b-form-checkbox>
                              <b-form-checkbox :value="weekdayEnum.WEDNESDAY">
                                {{ 'DATETIME_WEDNESDAY' | fromTextKey }}
                              </b-form-checkbox>
                              <b-form-checkbox :value="weekdayEnum.THURSDAY">
                                {{ 'DATETIME_THURSDAY' | fromTextKey }}
                              </b-form-checkbox>
                            </b-col>
                            <b-col cols="6">
                              <b-form-checkbox :value="weekdayEnum.FRIDAY">
                                {{ 'DATETIME_FRIDAY' | fromTextKey }}
                              </b-form-checkbox>
                              <b-form-checkbox :value="weekdayEnum.SATURDAY">
                                {{ 'DATETIME_SATURDAY' | fromTextKey }}
                              </b-form-checkbox>
                              <b-form-checkbox :value="weekdayEnum.SUNDAY">
                                {{ 'DATETIME_SUNDAY' | fromTextKey }}
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                        </b-form-checkbox-group>
                        <div class="form-inline">
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_END_AFTER' | fromTextKey }}
                          <b-form-input
                            id="weekly.occurenceLimit"
                            v-model="weekly.occurenceLimit"
                            class="text_inline"
                            :disabled="selectedMaxDate || eventNeverEnd"
                            :class="isClickedOK && invalidWeeklyOccurenceLimit ? 'red-border' : ''"
                            @change="checkForExistingResourcesWhenChangingDateTime"
                          />
                          {{ 'DATETIME_WEEK' | fromTextKey }}
                          {{ 'OR' | fromTextKey }}
                        </div>
                        <b-alert variant="danger" :show="isClickedOK && invalidWeeklyOccurenceLimit">
                          {{
                            weekly.occurenceLimit > 2000
                              ? 'CALENDAR_ALERT_EVENT_REPEAT_OCCURENCE_MAX'
                              : 'CALENDAR_ALERT_EVENT_REPEAT' | fromTextKey
                          }}
                        </b-alert>
                      </template>
                      <template v-if="eventForm.repeating.pattern === repeatingEventPatternEnum.MONTHLY">
                        <div class="form-inline">
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_END_AFTER' | fromTextKey }}
                          <b-form-input
                            id="monthly.occurenceLimit"
                            v-model="monthly.occurenceLimit"
                            class="text_inline"
                            :disabled="selectedMaxDate || eventNeverEnd"
                            :class="isClickedOK && invalidMonthlyOccurenceLimit ? 'red-border' : ''"
                            @change="checkForExistingResourcesWhenChangingDateTime"
                          />
                          {{ 'CALENDAR_LABEL_EVENT_REPEAT_REPETITION' | fromTextKey }}
                          {{ 'OR' | fromTextKey }}
                        </div>
                        <b-alert variant="danger" :show="isClickedOK && invalidMonthlyOccurenceLimit">
                          {{
                            monthly.occurenceLimit > 2000
                              ? 'CALENDAR_ALERT_EVENT_REPEAT_OCCURENCE_MAX'
                              : 'CALENDAR_ALERT_EVENT_REPEAT' | fromTextKey
                          }}
                        </b-alert>

                        <b-alert variant="danger" :show="showAlertOutOfMonthlyDate">
                          {{ 'CALENDAR_ALERT_SERIES_MONTHLY_END_DATE_OF_MONTH' | fromTextKey }}
                        </b-alert>
                      </template>
                      <div class="form-inline">
                        {{ 'CALENDAR_LABEL_EVENT_REPEAT_END_ON_DATE' | fromTextKey }}
                        <el-date-picker
                          id="maxDate"
                          v-model="eventForm.repeating.maxDate"
                          prefix-icon="false"
                          :disabled="enterOccurenceLimit || eventNeverEnd"
                          :format="$parent.defaultDateFormat"
                          :value-format="$parent.defaultDateValueFormat"
                          type="date"
                          :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                          :picker-options="recurringDatePickerOptions"
                          class="maxDatePicker"
                          :class="{ elPickerActive: isMaxDateClicked }"
                          @change="
                            selectMaxDate();
                            checkForExistingResourcesWhenChangingDateTime();
                          "
                          @focus="
                            datePickerModifier();
                            isMaxDateClicked = true;
                          "
                          @blur="isMaxDateClicked = false"
                        />
                      </div>
                      <div class="margin-top">
                        <b-form-checkbox
                          id="eventNeverEnd"
                          v-model="eventNeverEnd"
                          @change="checkForExistingResourcesWhenChangingDateTime"
                        >
                          {{ 'CALENDAR_LABEL_SERIES_EVENT_NEVER_END' | fromTextKey }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <hr class="line" />
                    <div slot="footer" class="dialog-footer">
                      <b-button variant="link" class="mr-4" @click="closeRepeatingDialog()">
                        {{ 'CANCEL' | fromTextKey }}
                      </b-button>
                      <b-button variant="primary" class="send-btn" @click="parseRepeatingEventData()">
                        {{ 'OK' | fromTextKey }}
                      </b-button>
                    </div>
                  </el-dialog>
                </div>
              </b-col>
            </b-row>
            <!-- REPEATS END-->
            <!-- DEADLINE REQUEST START-->
            <b-row v-if="canEditDeadline">
              <b-col cols="12">
                <label>{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}</label>
                <el-date-picker
                  id="deadline-date"
                  v-model="eventForm.responseDeadline"
                  :format="$parent.defaultDateFormat"
                  :value-format="$parent.defaultDateValueFormat"
                  type="date"
                  :aria-label="'ARIA_LABEL_CALENDAR_START_DATE' | fromTextKey"
                  :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                  :picker-options="deadlinePickerOptions"
                  :disabled="hasLimitedEditRight || isResponseDeadlineDisabled"
                  @change="showInvalidDeadlineAlert = false"
                  @focus="datePickerModifier()"
                />
                <b-alert variant="danger" :show="showInvalidDeadlineAlert">
                  {{ 'CALENDAR_LABEL_EVENT_INVALID_DEADLINE' | fromTextKey }}
                </b-alert>
              </b-col>
            </b-row>
            <!-- DEADLINE REQUEST END -->
          </b-col>
        </b-row>
        <hr class="line mb-0" />
        <!-- PRIMARY LOCATION START -->
        <b-row v-if="eventForm.type === eventTypes.LESSON">
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_PRIMARY_LOCATION' | fromTextKey }}</label>
            <b-input :value="primaryResourceText" disabled />
          </b-col>
        </b-row>
        <!-- PRIMARY LOCATION END -->
        <b-row>
          <!-- ROOMS START -->
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_LOCATIONS' | fromTextKey }}</label>
            <help-box
              id="locations"
              :title="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_LOCATIONS')"
              :body="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_LOCATIONS_HELP_TEXT')"
            />
            <b-input-group class="search-module">
              <aula-search
                :search-result-loader="searchLocations"
                :results="locationSearchResults"
                :existing-participants="existingLocations"
                :close-dropdown-after-select="false"
                :select-all="false"
                :multiple="true"
                :placeholder-textkey="'CALENDAR_LABEL_EVENT_PLACEHOLDER_PLACE'"
                :disabled="showSeriesEventForm"
                css-class="wraptext"
                style="width: 100%"
                :reset-search-input="resetPrimaryResources"
                popper-class="resource-search"
                @emitFocus="searchLocations('')"
                @emitSelected="updateLocations"
              >
                <template #optionContent="optionData">
                  <span v-html="optionData.label" />
                  <div v-if="getIsResourceFullyAvailable(optionData)" class="resource-availability text-grey">
                    {{ 'CALENDAR_AVAILABLE' | fromTextKey }}
                  </div>
                  <div v-else class="resource-availability text-alert">
                    {{
                      'CALENDAR_AVAILABLE_PARTIALLY'
                        | fromTextKey({
                          available: optionData.numberOfAvailableOccurrences,
                          occurrences: optionData.numberOfOccurrences,
                        })
                    }}
                  </div>
                </template>
              </aula-search>
              <b-input-group-addon>
                <i class="icon-Aula_search input-group-search-addon" />
              </b-input-group-addon>
            </b-input-group>
            <ul class="partially-available-resource-list">
              <li v-for="location of partiallyAvailableLocations" :key="location.id">
                {{
                  'CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_1'
                    | fromTextKey({
                      resource: location.label,
                    })
                }}
                <strong>
                  {{
                    'CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_2'
                      | fromTextKey({
                        available: location.numberOfAvailableOccurrences,
                        occurrences: location.numberOfOccurrences,
                      })
                  }}
                </strong>
              </li>
            </ul>
          </b-col>
          <!-- ROOMS END -->
          <!-- MEETING PLACE START -->
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_MEETING_PLACE' | fromTextKey }}</label>
            <help-box
              id="freeTextLocation"
              :title="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_MEETING_PLACE')"
              :body="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_MEETING_PLACE_HELP_TEXT')"
            />
            <b-input
              v-model="eventForm.additionalResourceText"
              maxlength="255"
              :placeholder="'CALENDAR_LABEL_EVENT_MEETING_PLACE_PLACEHOLDER' | fromTextKey"
            />
          </b-col>
          <!-- MEETING PLACE END -->
        </b-row>
        <!-- RESOURCE START -->
        <b-row>
          <b-col class="form-margin-bottom col-12 col-md-6">
            <label>{{ 'CALENDAR_LABEL_EVENT_RESOURCES' | fromTextKey }}</label>
            <help-box
              id="resources"
              :title="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_RESOURCES')"
              :body="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_RESOURCES_HELP')"
            />
            <b-input-group class="search-module" style="width: 100%">
              <aula-search
                :search-result-loader="$parent.loadResourceSearchResults"
                :results="resourceResults"
                :close-dropdown-after-select="false"
                :existing-participants="existingResources"
                :enable-relatives-for-students="false"
                :placeholder-textkey="'CALENDAR_LABEL_EVENT_RESOURCES_PLACEHOLDER'"
                style="width: 100%"
                :disabled="showSeriesEventForm"
                :reset-search-input="resetResources"
                popper-class="resource-search"
                @emitFocus="$parent.loadResourceSearchResults('')"
                @emitSelected="addResourceToList"
              >
                <template #optionContent="optionData">
                  <span v-html="optionData.label" />
                  <div v-if="getIsResourceFullyAvailable(optionData)" class="resource-availability text-grey">
                    {{ 'CALENDAR_AVAILABLE' | fromTextKey }}
                  </div>
                  <div v-else class="resource-availability text-alert">
                    {{
                      'CALENDAR_AVAILABLE_PARTIALLY'
                        | fromTextKey({
                          available: optionData.numberOfAvailableOccurrences,
                          occurrences: optionData.numberOfOccurrences,
                        })
                    }}
                  </div>
                </template>
              </aula-search>
              <b-input-group-addon>
                <i class="icon-Aula_search input-group-search-addon" />
              </b-input-group-addon>
            </b-input-group>
            <ul class="partially-available-resource-list">
              <li v-for="resource of partiallyAvailableResources" :key="resource.id">
                {{
                  'CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_1'
                    | fromTextKey({
                      resource: resource.label,
                    })
                }}
                <strong>
                  {{
                    'CALENDAR_AVAILABLE_PARTIALLY_DESCRIPTION_2'
                      | fromTextKey({
                        available: resource.numberOfAvailableOccurrences,
                        occurrences: resource.numberOfOccurrences,
                      })
                  }}
                </strong>
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- RESOURCE END -->
        <!-- PLANNING ASSISTANT START -->
        <b-btn
          v-if="canUsePlanningAssistant"
          variant="link"
          size="sm"
          :disabled="isPlanningAssistantDisabled"
          class="planning-assistant-button d-flex py-1"
          data-color="base"
          @click="$refs.planningAssistantModal.show()"
        >
          <i class="icon icon-Aula_export" aria-hidden="true" />
          <span>{{ 'PLANNING_ASSISTANT_BUTTON' | fromTextKey }} </span>
        </b-btn>
        <!-- PLANNING ASSISTANT END -->
      </b-col>
    </b-row>
    <!-- NOTES START -->
    <template v-if="eventForm.type === eventTypes.LESSON">
      <b-row class="form-margin-bottom">
        <b-col cols="12">
          <hr class="line" />
          <span class="note-label">
            {{ 'CALENDAR_LABEL_EVENT_MESSAGE_CLASS' | fromTextKey }}
            <help-box
              id="tooltip-lesson-note"
              class="mb-1"
              :title="$options.filters.fromTextKey('LESSON_NOTE_TO_CLASS_TITLE')"
              :body="$options.filters.fromTextKey('LESSON_NOTE_TO_CLASS_TEXT')"
            />
          </span>
          <PluginTextEditor
            id="noteToClass"
            v-model="eventForm.lesson.noteToClass"
            :max-length="65535"
            :draggable="true"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :institution-code="eventForm.institutionCode"
          />
          <upload
            ref="uploaderFiles"
            class="upload"
            :limit-file-upload="200"
            :parent="parentTypes.CALENDAR"
            :upload-from-filesharing="canAttachFromSecureFiles"
            :upload-from-my-computer="canCreateAttachments"
            :upload-from-one-drive="canCreateAttachments"
            :upload-from-google-drive="canCreateAttachments"
            :existing-media="existingAttachments"
            :institution-code-for-tagging="eventForm.institutionCode"
            @mediaUpdated="filesChanged"
            @attachSecureDocuments="attachSecureDocuments"
          />
        </b-col>
        <b-col cols="12">
          <hr class="line" />
          <span class="note-label pt-1">
            {{ 'CALENDAR_LABEL_EVENT_MESSAGE_TEACHER' | fromTextKey }}
          </span>
          <PluginTextEditor
            id="noteToTeacher"
            v-model="eventForm.lesson.noteToTeacher"
            :max-length="65535"
            :draggable="true"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :institution-code="eventForm.institutionCode"
          />
        </b-col>
        <b-col cols="12">
          <hr class="line" />
          <span class="note-label">
            {{ 'CALENDAR_LABEL_EVENT_MESSAGE_SUBSTITUTE' | fromTextKey }}
            <help-box
              id="tooltip-lesson-note-2"
              :title="$options.filters.fromTextKey('LESSON_NOTE_TO_CLASS_TITLE')"
              :body="$options.filters.fromTextKey('LESSON_NOTE_TO_CLASS_TEXT')"
            />
          </span>
          <PluginTextEditor
            id="noteToSubstitute"
            v-model="eventForm.lesson.noteToSubstitute"
            :max-length="65535"
            :draggable="true"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :institution-code="eventForm.institutionCode"
          />
        </b-col>
      </b-row>
    </template>
    <!-- NOTES END-->
    <!-- TEXT START -->
    <b-row class="mb-2">
      <b-col v-if="eventForm.type !== eventTypes.LESSON">
        <hr class="line mb-0" />
        <label>{{ 'CALENDAR_LABEL_EVENT_DESCRIPTION' | fromTextKey }}</label>
        <PluginTextEditor
          v-if="!isLoadingDescription"
          :id="editorId"
          ref="eventContent"
          v-model="eventForm.description"
          :max-length="10000"
          :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
          :aria-label="'ARIA_LABEL_WRITE_EVENT_CONTENT'"
          :disabled="hasLimitedEditRight"
          :institution-code="eventForm.institutionCode"
        />
      </b-col>
    </b-row>
    <!-- TEXT END -->
    <template v-if="eventForm.type !== eventTypes.LESSON">
      <div v-if="isHandlingOthersEvent">
        <attachment-thumbnails :attachments="existingAttachments" :parent="parentTypes.CALENDAR" />
        <attachment-list :attachments="existingAttachments" :show-media="true" />
      </div>
      <upload
        v-else
        ref="uploaderFiles"
        class="upload"
        :limit-file-upload="200"
        :parent="parentTypes.CALENDAR"
        :upload-from-filesharing="canAttachFromSecureFiles"
        :upload-from-my-computer="canCreateAttachments"
        :upload-from-one-drive="canCreateAttachments"
        :upload-from-google-drive="canCreateAttachments"
        :existing-media="existingAttachments"
        :institution-code-for-tagging="eventForm.institutionCode"
        @mediaUpdated="filesChanged"
        @attachSecureDocuments="attachSecureDocuments"
      />
    </template>
    <!-- EVENT SETTINGS START -->
    <template v-if="isSettingSectionVisible">
      <hr class="line mb-0" />
      <label class="font-weight-bold"> {{ 'CALENDAR_LABEL_EVENT_SETTINGS_FOR_SIMPLE_EVENT' | fromTextKey }}</label>
      <div v-if="!isMobile">
        <b-row>
          <b-col
            v-if="
              profile.role === portalRoles.EMPLOYEE && hasPermission(permissionEnum.INVITE_TO_EVENT) && !isCoOrganizer
            "
            cols="6"
          >
            <div class="event-settings">
              <b-form-checkbox
                id="responseRequired"
                v-model="eventForm.responseRequired"
                :disabled="disableResponseRequire"
              >
                {{ 'CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER' | fromTextKey }}
              </b-form-checkbox>
              <help-box
                id="tooltip-send-notification-help"
                :title="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_TITLE')"
                :body="
                  $options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_1') +
                  '<br/><br/>' +
                  $options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_2')
                "
                is-html-content
              />
            </div>
          </b-col>
          <b-col v-if="!isRepeatingOccurrence && profile.role === portalRoles.EMPLOYEE" cols="6" class="event-settings">
            <b-form-checkbox
              v-model="simpleEventModel.doRequestNumberOfParticipants"
              :disabled="!canRequestNumberOfParticipant"
              @change="onChangeRequestNumberOfParticipants"
            >
              {{ 'CALENDAR_LABEL_EVENT_PARTICIPANT_NUMBER_REQUIRED' | fromTextKey }}
            </b-form-checkbox>
          </b-col>
          <b-col v-if="eventForm.type !== eventTypes.LESSON && !isRepeatingOccurrence" cols="6">
            <div class="event-settings">
              <b-form-checkbox
                id="isPrivate"
                v-model="eventForm.private"
                :disabled="hasLimitedEditRight"
                @input="validatePrivateAndAddToInstCalendar()"
              >
                {{ 'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey }}
              </b-form-checkbox>
            </div>
            <div v-if="canAddEventOnlyToInstitutionCalendar" class="event-settings">
              <b-form-checkbox
                v-model="eventForm.hideInOwnCalendar"
                :disabled="!canEditRequestForAnswer"
                @change="
                  validatePrivateAndAddToInstCalendar();
                  validateHideInOwnCalendar();
                "
              >
                {{ 'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey }}
                <template
                  v-if="
                    delegatedContext.institutionCode != null &&
                    institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                  "
                >
                  {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
                </template>
                <template
                  v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >
                  {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
                </template>
              </b-form-checkbox>
            </div>
            <div v-if="canAddEventToInstitutionCalendar" class="event-settings">
              <b-form-checkbox
                v-model="eventForm.addToInstitutionCalendar"
                :disabled="!canEditRequestForAnswer"
                @change="
                  validatePrivateAndAddToInstCalendar();
                  validateAddToInstitutionCalendar();
                "
              >
                {{ 'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey }}
                <template
                  v-if="
                    delegatedContext.institutionCode != null &&
                    institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                  "
                >
                  {{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
                </template>
                <template
                  v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >
                  {{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
                </template>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col v-if="!isRepeatingOccurrence" cols="6">
            <div>
              <b-form-checkbox
                class="event-settings"
                :checked="simpleEventModel.maxParticipantsEnabled"
                :disabled="hasLimitedEditRight || hasRepeatingPattern"
                @change="onChangeDefineParticipantSeats"
              >
                {{ 'CALENDAR_LABEL_EVENT_DEFINE_PARTICIPANT_SEATS' | fromTextKey }}
              </b-form-checkbox>
              <div v-if="simpleEventModel.maxParticipantsEnabled && !hasRepeatingPattern" class="mt-2">
                <b-form-input
                  v-numeric
                  :placeholder="'CALENDAR_LABEL_EVENT_WRITE_PARTICIPANT_SEATS' | fromTextKey"
                  :value="simpleEventModel.maximumNumberOfParticipants"
                  :disabled="hasLimitedEditRight"
                  @input="onInputMaximumNumberOfParticipants"
                />
                <b-alert variant="danger" :show="!simpleEventModel.validator.isValidMaximumParticipants" class="mt-1">
                  {{ 'CALENDAR_ALERT_INVALID_MAXIMUM_PARTICIPANTS' | fromTextKey }}
                </b-alert>
                <b-alert
                  variant="danger"
                  :show="!simpleEventModel.validator.isMaximumParticipantsHigherThanActualParticipants"
                  class="mt-1"
                >
                  {{
                    'CALENDAR_ALERT_TOO_FEW_MAXIMUM_PARTICIPANTS'
                      | fromTextKey({ participants: simpleEventModel.actualNumberOfParticipants || '' })
                  }}
                </b-alert>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <template v-else>
        <div class="mobile-settings-section">
          <div
            v-if="profile.role === portalRoles.EMPLOYEE && hasPermission(permissionEnum.INVITE_TO_EVENT)"
            class="mobile-switch mb-4"
          >
            <div>
              <label>{{ 'CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER' | fromTextKey }}</label>
              <help-box
                id="tooltip-send-notification-help"
                :title="$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_TITLE')"
                :body="
                  $options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_1') +
                  '<br/><br/>' +
                  $options.filters.fromTextKey('CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER_HELP_2')
                "
                is-html-content
              />
            </div>
            <el-switch
              v-model="eventForm.responseRequired"
              :aria-label="'CALENDAR_LABEL_EVENT_REQUEST_FOR_ANSWER' | fromTextKey"
              :width="(width = 48)"
              :disabled="disableResponseRequire"
              class="pull-right"
            />
          </div>
          <div v-if="eventForm.type !== eventTypes.LESSON && !isRepeatingOccurrence" class="mobile-switch">
            <label>{{ 'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey }}</label>
            <el-switch
              v-model="eventForm.private"
              :aria-label="'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey"
              :width="(width = 48)"
              class="pull-right"
              :disabled="hasLimitedEditRight"
              @input="validatePrivateAndAddToInstCalendar()"
            />
          </div>
        </div>
        <div class="mobile-settings-section">
          <div v-if="!isRepeatingOccurrence" class="d-flex flex-column mb-4">
            <div class="mobile-switch">
              <label>{{ 'CALENDAR_LABEL_EVENT_DEFINE_PARTICIPANT_SEATS' | fromTextKey }}</label>
              <AulaSwitch
                :value="simpleEventModel.maxParticipantsEnabled"
                :aria-label="'CALENDAR_LABEL_EVENT_DEFINE_PARTICIPANT_SEATS' | fromTextKey"
                class="pull-right"
                :disabled="hasLimitedEditRight || hasRepeatingPattern"
                @input="onChangeDefineParticipantSeats"
              />
            </div>
            <div class="d-flex flex-column">
              <b-form-input
                v-if="simpleEventModel.maxParticipantsEnabled && !hasRepeatingPattern"
                v-numeric
                :placeholder="'CALENDAR_LABEL_EVENT_WRITE_PARTICIPANT_SEATS' | fromTextKey"
                :value="simpleEventModel.maximumNumberOfParticipants"
                :disabled="hasLimitedEditRight"
                class="mt-3"
                @input="onInputMaximumNumberOfParticipants"
              />
              <b-alert
                variant="danger"
                :show="!simpleEventModel.validator.isValidMaximumParticipants"
                class="mt-1 mb-0"
              >
                {{ 'CALENDAR_ALERT_INVALID_MAXIMUM_PARTICIPANTS' | fromTextKey }}
              </b-alert>
              <b-alert
                variant="danger"
                :show="!simpleEventModel.validator.isMaximumParticipantsHigherThanActualParticipants"
                class="mt-1 mb-0"
              >
                {{
                  'CALENDAR_ALERT_TOO_FEW_MAXIMUM_PARTICIPANTS'
                    | fromTextKey({ participants: simpleEventModel.actualNumberOfParticipants || '' })
                }}
              </b-alert>
            </div>
          </div>
          <div v-if="!isRepeatingOccurrence && profile.role === portalRoles.EMPLOYEE" class="mobile-switch">
            <label> {{ 'CALENDAR_LABEL_EVENT_PARTICIPANT_NUMBER_REQUIRED' | fromTextKey }}</label>
            <el-switch
              v-model="simpleEventModel.doRequestNumberOfParticipants"
              :aria-label="'CALENDAR_LABEL_EVENT_PARTICIPANT_NUMBER_REQUIRED' | fromTextKey"
              :width="(width = 48)"
              class="pull-right"
              :disabled="!canRequestNumberOfParticipant"
              @input="onChangeRequestNumberOfParticipants"
            />
          </div>
        </div>
        <div class="mobile-settings-section">
          <div v-if="canAddEventOnlyToInstitutionCalendar" class="mobile-switch mb-3 mb-sm-4">
            <label
              >{{ 'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey }}
              <template
                v-if="
                  delegatedContext.institutionCode != null &&
                  institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                "
                >{{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
              </template>
              <template
                v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >{{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
              </template>
            </label>
            <el-switch
              v-model="eventForm.hideInOwnCalendar"
              :aria-label="'CALENDAR_LABEL_EVENT_ADD_ONLY_TO_INSTITUTION_CALENDAR' | fromTextKey"
              :width="(width = 48)"
              :disabled="!canEditRequestForAnswer"
              class="pull-right"
              @change="
                validatePrivateAndAddToInstCalendar();
                validateHideInOwnCalendar();
              "
            />
          </div>
          <div v-if="canAddEventToInstitutionCalendar" class="mobile-switch">
            <label
              >{{ 'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey }}
              <template
                v-if="
                  delegatedContext.institutionCode != null &&
                  institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode) != null
                "
                >{{ institutions.find(inst => inst.institutionCode === delegatedContext.institutionCode).name }}
              </template>
              <template
                v-else-if="institutions.find(inst => inst.institutionCode === eventForm.institutionCode) != null"
                >{{ institutions.find(inst => inst.institutionCode === eventForm.institutionCode).name }}
              </template>
            </label>
            <el-switch
              v-model="eventForm.addToInstitutionCalendar"
              :aria-label="'CALENDAR_LABEL_EVENT_ADD_TO_INSTITUTION_CALENDAR' | fromTextKey"
              :width="(width = 48)"
              :disabled="!canEditRequestForAnswer"
              class="pull-right"
              @change="
                validatePrivateAndAddToInstCalendar();
                validateAddToInstitutionCalendar();
              "
            />
          </div>
        </div>
      </template>
    </template>
    <!-- EVENT SETTINGS END -->
    <aula-form-footer
      :is-loading="isLoading"
      :show-delete="
        isEditMode && eventForm.type !== eventTypes.LESSON && eventForm.type !== eventTypes.PRESENCE_HOLIDAY
      "
      :show-line="false"
      :submit-text="isEditMode ? 'BUTTON_SAVE' : 'BUTTON_CREATE'"
      :submit-disabled="sendDisabled || showSeriesEventForm"
      @deleteClicked="$refs.deleteModal.show()"
      @cancelClicked="closeEventForm()"
      @submitClicked="onSaveClicked"
    />
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelForm()"
    >
      {{ 'CALENDAR_CANCEL_INFO' | fromTextKey }} <br />
      {{ 'CALENDAR_CANCEL_INFO_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="conflictingEventsModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.conflictingEventsModal.hide()"
      @okClicked="handleOkDialogConflict(), $refs.conflictingEventsModal.hide()"
    >
      {{ 'CALENDAR_ALERT_CONFLICTINT_INVITEES' | fromTextKey }}<br /><br />
      <template v-for="(profile, i) in conflictingAttendees">
        {{ profile | displayProfileNameWithMetadata }}<br :key="i" />
      </template>
      <br />{{ 'CALENDAR_ALERT_CONFLICTINT_CONFIRM' | fromTextKey }}<br />
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      :is-loading="isLoading"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="$parent.removeEvent(activeEvent, $route.query.occurrence)"
    >
      <template v-if="isHandlingOthersEvent">
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_1' | fromTextKey }}<br />
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_2' | fromTextKey }}
      </template>
      <template v-else>
        {{ 'CALENDAR_DELETE_EVENT_TEXT' | fromTextKey }}
      </template>
    </aula-modal>
    <aula-modal
      ref="editOthersEventWarningModal"
      @cancelClicked="$refs.editOthersEventWarningModal.hide()"
      @okClicked="onConfirmEditOthersEvent"
    >
      {{ 'CALENDAR_EDIT_OTHERS_EVENT_WARNING_1' | fromTextKey }}<br />
      {{ 'CALENDAR_EDIT_OTHERS_EVENT_WARNING_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="editRepeatingSeriesWarningModal"
      @cancelClicked="$refs.editRepeatingSeriesWarningModal.hide()"
      @okClicked="onConfirmEditRepeatingSeries"
    >
      {{ 'CALENDAR_EDIT_REPEATING_SERIES_WARNING_1' | fromTextKey }}<br />
      {{ 'CALENDAR_SAVE_EVENT_CONFIRMATION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      header-text="CALENDAR_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="removeResourcesWhenChangingAllDayModal"
      ok-text="BUTTON_YES"
      :disable-close="true"
      @okClicked="
        $refs.removeResourcesWhenChangingAllDayModal.hide();
        removeResources();
      "
      @cancelClicked="
        $refs.removeResourcesWhenChangingAllDayModal.hide();
        resetAllDay();
      "
    >
      {{ 'CALENDAR_REMOVE_RESOURCES_WHEN_CHANGING_DATE_TIME' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="removeResourcesWhenChangingDateTimeModal"
      ok-text="BUTTON_YES"
      :disable-close="true"
      @okClicked="
        $refs.removeResourcesWhenChangingDateTimeModal.hide();
        removeResources();
      "
      @cancelClicked="
        $refs.removeResourcesWhenChangingDateTimeModal.hide();
        resetDateTime();
      "
    >
      {{ 'CALENDAR_REMOVE_RESOURCES_WHEN_CHANGING_DATE_TIME' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="bothPrivateAndAddedToInstCalendarModal"
      :show-cancel="false"
      @okClicked="$refs.bothPrivateAndAddedToInstCalendarModal.hide()"
    >
      {{ 'CALENDAR_BOTH_PRIVATE_AND_ADDED_TO_INST_CALENDAR' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="tooManyRecipientsModal" :show-cancel="false" @okClicked="$refs.tooManyRecipientsModal.hide()">
      {{ 'CALENDAR_ALERT_TOO_MANY_RECIPIENTS' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="NO"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="
        $refs.theCommunicationChannelIsBlocked.hide();
        removeBlocked();
      "
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS' | fromTextKey }}
      <div class="mt-2">
        {{
          blockedProfiles.map(u => u.institutionProfile.name).join(', ') +
          (blockedProfiles.length > 0 && blockedOtps.length > 0 ? ', ' : '') +
          blockedOtps.map(u => u.otpInbox.displayName).join(', ')
        }}
      </div>
    </aula-modal>
    <aula-modal
      ref="removingCoOrganizerWarning"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="CANCEL"
      @cancelClicked="$refs.removingCoOrganizerWarning.hide()"
      @okClicked="acceptRemovalOfExistingOrganizer()"
    >
      {{ 'CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_SIMPLE_EVENT' | fromTextKey }}<br />
      {{ 'CALENDAR_WARNING_REMOVE_ORGANIZER_FROM_EVENT_CONFIRM' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="unavailableResourcesModal"
      :disable-close="true"
      ok-text="BUTTON_YES"
      @cancelClicked="
        $refs.unavailableResourcesModal.hide();
        removeUnavailableResources();
      "
      @okClicked="
        $refs.unavailableResourcesModal.hide();
        removeUnavailableResources(true);
      "
    >
      {{ 'CALENDAR_ALERT_UNAVAILABLE_RESOURCES_1' | fromTextKey }} ({{
        unavailableResources.map(r => r.name).join(', ')
      }}) {{ 'CALENDAR_ALERT_UNAVAILABLE_RESOURCES_2' | fromTextKey }}<br />
      {{ 'CALENDAR_ALERT_UNAVAILABLE_RESOURCES_3' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cannotMoveRepeatingEventToExistingRepetitionWarningModal"
      :show-cancel="false"
      @okClicked="$refs.cannotMoveRepeatingEventToExistingRepetitionWarningModal.hide()"
    >
      {{ 'CALENDAR_ALERT_REPEATING_EVENT_CANNOT_MOVE_TO_EXISTING_REPETITION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="partiallyAvailableResourcesWarning"
      @cancelClicked="$refs.partiallyAvailableResourcesWarning.hide()"
      @okClicked="onConfirmPartiallyAvailableResources"
    >
      {{ 'CALENDAR_PARTIALLY_AVAILABLE_RESOURCES_WARNING' | fromTextKey }}<br />
      {{ 'CALENDAR_SAVE_EVENT_CONFIRMATION' | fromTextKey }}
    </aula-modal>
    <planning-assistant
      ref="planningAssistantModal"
      :event-form="eventForm"
      :parent="parent"
      :show-invitees="!isHandlingOthersEvent"
      :can-invite-invitees="!isHandlingOthersEvent && !isCommunicationBlock"
      :can-edit-time="!(eventForm.allDay || hasLimitedEditRight)"
      :locations="selectedLocations"
      @resetEventForm="resetEventFormFromPlanningAssistant"
      @updateLocations="updateLocationsFromPlanningAssistant"
      @updateResources="updateResourcesFromPlanningAssistant"
      @updateInvitees="updateInviteesFromPlanningAssistant"
      @updateStartTime="updateStartTimeFromPlanningAssistant"
      @updateEndTime="updateEndTimeFromPlanningAssistant"
      @updateStartDate="updateStartDateFromPlanningAssistant"
    />
    <toastr ref="externalRecipientsToastr" variant="warning" icon-font-size="1em" circle-background="white">
      {{ 'MESSAGE_ADD_RECIPIENTS_EXTERNAL' | fromTextKey }}
    </toastr>
    <div ref="clickLayer" class="clickLayer" @click="setLayer(false)" />
  </b-form>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import Vue from 'vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { teacherTypes } from '../../../shared/enums/teacherTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import upload from '../../../shared/components/Upload.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import AulaTimepicker from '../../../shared/components/Timepicker';
import cloneDeep from 'lodash/cloneDeep';
import isEqualWith from 'lodash/isEqualWith';
import isEqual from 'lodash/isEqual';
import PlanningAssistant from './PlanningAssistant';
import { docTypes } from '../../../shared/enums/docTypes';
import { getPortalRoleText } from '../../../shared/functions/getPortalRoleText';
import { filterGroupMembershipInstitutions, getDefaultInstitutionCode } from '../../../shared/utils/institutionUtil';
import { isoWeekdayEnum } from '../../../shared/enums/isoWeekdayEnum';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import AttachmentList from '../../../shared/components/AttachmentList';
import { repeatingEventPatternEnum } from '../../../shared/enums/repeatingEventPatternEnum';
import { NewSimpleEvent } from '../../business/newSimpleEvent';
import {
  now,
  isBefore,
  add as addToDate,
  isDateBetween,
  isSame,
  format,
  getIsoWeekday,
  getDate,
  isAfter,
} from '../../../shared/utils/dateUtil';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes';
import Numeric from '../../../shared/directives/numeric';
import AulaAlert from '../../../shared/components/AulaAlert';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import { resourceTypes } from '../../../shared/enums/resourceTypes';
import { weekdayEnum } from '../../../shared/enums/weekdayEnum';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import AulaSwitch from '../../../shared/components/AulaSwitch.vue';
import PluginTextEditor from '../text_editor/PluginTextEditor.vue';
import isEmpty from 'lodash/isEmpty';

export default {
  directives: {
    Numeric,
  },
  components: {
    PluginTextEditor,
    AulaSwitch,
    AulaAlert,
    AttachmentList,
    AttachmentThumbnails,
    PlanningAssistant,
    upload,
    AulaSearchRecipients,
    AulaTimepicker,
  },
  inject: {
    getIsCreator: providerKeyTypes.isCurrentEventCreator,
    getIsCoOrganizer: providerKeyTypes.isCurrentEventCoOrganizer,
    getIsCurrentEventInvitee: providerKeyTypes.isCurrentEventInvitee,
    getIsCurrentEventInviteeAsOtp: providerKeyTypes.isCurrentEventInviteeAsOtp,
    getCanHandleOthersEvents: { from: providerKeyTypes.canHandleOthersEvents, default: () => () => false },
    loadEventLocations: providerKeyTypes.loadEventLocations,
  },
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
    canEditCoOrganizers: { type: Boolean, default: false },
  },
  data() {
    return {
      simpleEventModel: new NewSimpleEvent({
        maximumNumberOfParticipants: null,
        doRequestNumberOfParticipants: false,
        maxParticipantsEnabled: false,
        actualNumberOfParticipants: null,
      }),
      docTypes,
      invitedGroups: [],
      Vue: Vue,
      emptyEventForm: {},
      resetRecipientsInput: false,
      availableEndDate: {},
      recurringDatePickerOptions: {},
      hiddenOrganizers: [],
      eventForm: {
        id: '',
        maximumNumberOfParticipants: null,
        doRequestNumberOfParticipants: false,
        coOrganizerIds: [],
        institutionCode: null,
        creatorInstProfileId: '',
        editorInstProfileId: '',
        title: '',
        type: '',
        startDateTime: '',
        endDateTime: '',
        allDay: false,
        private: false,
        primaryResource: {},
        additionalLocations: [],
        additionalResourceText: '',
        invitees: [],
        invitedGroups: [],
        invitedGroupIds: [],
        invitedOtpInboxIds: [],
        responseRequired: true,
        responseDeadline: null,
        resources: [],
        description: '',
        attachments: [],
        oldStartDateTime: '',
        oldEndDateTime: '',
        isEditEvent: false,
        addToInstitutionCalendar: false,
        hideInOwnCalendar: false,
        lesson: {
          lessonId: '',
          lessonStatus: '',
          noteToClass: '',
          noteToSubstitute: '',
          participants: [],
        },
        repeating: {
          pattern: repeatingEventPatternEnum.NEVER,
          interval: 0,
          weekdayMask: [false, false, false, false, false, false, false],
          occurenceLimit: 0,
          maxDate: null,
        },
      },
      resourceResults: [],
      locationSearchResults: [],
      additionalLocationResults: [],
      existingLocations: [],
      blockedProfiles: [],
      blockedGroups: [],
      blockedOtps: [],
      existingResources: [],
      existingInvitees: [],
      existingOrganizers: [],
      tmpOrganizers: [],
      selectedOrganizers: [],
      existingAttachments: [],
      portalRoles: portalRoles,
      groupTypes: groupTypes,
      eventTypes: eventTypeEnum,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      timepickerSettings: timepickerSettings,
      isStartDateClicked: false,
      isEndDateClicked: false,
      isMaxDateClicked: false,
      isLoading: false,
      isLoadingDescription: true,
      permissionEnum: permissionEnum,
      repeatingEventPatternEnum,
      startDate:
        moment().minute() > 30
          ? moment().add(1, 'hours').minute(0).format('YYYY-MM-DD')
          : moment().minute(30).format('YYYY-MM-DD'),
      startTime:
        moment().minute() > 30
          ? moment().add(1, 'hours').minute(0).format('HH:mm:ss')
          : moment().minute(30).format('HH:mm:ss'),
      endDate:
        moment().minute() > 30
          ? moment().add(2, 'hours').minute(0).format('YYYY-MM-DD')
          : moment().add(1, 'hours').minute(30).format('YYYY-MM-DD'),
      endTime:
        moment().minute() > 30
          ? moment().add(2, 'hours').minute(0).format('HH:mm:ss')
          : moment().add(1, 'hours').minute(30).format('HH:mm:ss'),
      seriesEndDateTime: '',
      seriesEventFormWidth: '',
      basicEventTypes: null,
      eventRepeatPatterns: [
        {
          id: repeatingEventPatternEnum.NEVER,
          label: Vue.filter('fromTextKey')('CALENDAR_TITLE_NEVER_REPEAT'),
        },
        {
          id: repeatingEventPatternEnum.DAILY,
          label: Vue.filter('fromTextKey')('CALENDAR_TITLE_DAILY_EVENT'),
        },
        {
          id: repeatingEventPatternEnum.WEEKLY,
          label: Vue.filter('fromTextKey')('CALENDAR_TITLE_WEEKLY_EVENT'),
        },
        {
          id: repeatingEventPatternEnum.MONTHLY,
          label: Vue.filter('fromTextKey')('CALENDAR_TITLE_MONTHLY_EVENT'),
        },
      ],
      seriesEventTitle: '',
      selectedMaxDate: false,
      eventNeverEnd: false,
      resetSeriesForm: {},
      resetRepeatingObject: {},
      resetEventNeverEnd: false,
      resetMaxDate: null,
      daily: {
        interval: '',
        occurenceLimit: '',
      },
      weekly: {
        occurenceLimit: '',
        weekdayMask: [],
      },
      monthly: {
        occurenceLimit: '',
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        days: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
        ],
      },
      isClickedOK: false,
      showSeriesEventForm: false,
      showMissingTitleAlert: false,
      showMissingStartDateAlert: false,
      messageAlertStartDate: '',
      showMissingStartTimeAlert: false,
      showMissingEndTimeAlert: false,
      showMissingEndDateAlert: false,
      showInvalidDeadlineAlert: false,
      showMissingSeriesEventAlert: false,
      disableResponseRequire: false,
      resetResources: false,
      resetPrimaryResources: false,
      maxAmountOfInviteesWhenGettingConflictingEvents: 100,
      hasExternalRecipient: false,
      unavailableResources: [],
      selectedLocations: [],
      weekdayEnum,
      isBeforeFirstOccurrence: false,
      isAfterLastOccurrence: false,
    };
  },
  computed: {
    ...mapGetters({
      getDateTimeSelectedByClickedTimeslot: types.GET_DATETIME_SELECTED_BY_CLICKED_TIMESLOT,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      conflictEventByAttendees: types.GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      group: types.GET_ACTIVE_GROUP,
      profile: types.GET_CURRENT_PROFILE,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      activeEvent: types.GET_CURRENT_EVENT,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      conflictingAttendees: types.GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      sameAuthorityInstitutions: types.GET_SAME_AUTHORITY_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      unavailableResourceIds: types.GET_UNAVAILABLE_RESOURCES,
      hasPermissionOnInstitutionGroup: types.HAS_PERMISSION_ON_INSTITUTION_GROUP,
    }),
    isCommunicationBlock() {
      const institutionProfile = this.institutions.find(
        institution => institution.institutionCode === this.eventForm.institutionCode
      );
      return institutionProfile?.communicationBlock ?? false;
    },
    canInviteToEvent() {
      return (
        !this.isCommunicationBlock &&
        this.hasPermissionOnInstitution(permissionEnum.INVITE_TO_EVENT, this.eventForm.institutionCode) &&
        this.parent !== parentTypes.GROUP
      );
    },
    canCreateAttachments() {
      return !this.isCommunicationBlock;
    },
    partiallyAvailableLocations() {
      return this.selectedLocations.filter(
        location => location?.numberOfAvailableOccurrences < location?.numberOfOccurrences
      );
    },
    partiallyAvailableResources() {
      return this.existingResources.filter(
        resource => resource.numberOfAvailableOccurrences < resource.numberOfOccurrences
      );
    },
    isSettingSectionVisible() {
      if (this.eventForm.type === eventTypeEnum.LESSON) {
        return false;
      }
      return (
        this.canRequestForAnswer ||
        this.canRequireParticipantNumber ||
        this.canSetPrivate ||
        this.canAddEventOnlyToInstitutionCalendar ||
        this.canAddEventToInstitutionCalendar ||
        !this.isRepeatingEvent
      );
    },
    canRequestForAnswer() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        this.hasPermission(permissionEnum.INVITE_TO_EVENT) &&
        !this.isCoOrganizer
      );
    },
    canRequireParticipantNumber() {
      return !this.isRepeatingOccurrence && this.profile.role === portalRoles.EMPLOYEE;
    },
    canSetPrivate() {
      return this.eventForm.type !== eventTypeEnum.LESSON && !this.isRepeatingOccurrence;
    },
    canRequestNumberOfParticipant() {
      return !this.isRepeatingEvent && !this.hasRepeatingPattern && !this.isEditMode;
    },
    primaryResourceText() {
      if (this.eventForm.primaryResource) {
        return `${this.eventForm.primaryResource.name} (${this.eventForm.primaryResource.institutionName})`;
      }
      return this.eventForm.primaryResourceText || '';
    },
    repeatingEventAlert() {
      let alert = this.$options.filters.fromTextKey('CALENDAR_ALERT_EDIT_REPEATING_SERIES');
      if (this.isRepeatingOccurrence) {
        alert = this.$options.filters.fromTextKey('CALENDAR_ALERT_EDIT_SINGLE_OCCURRENCE');
      }
      return alert;
    },
    isVisibleEndDate() {
      return !this.isRepeatingEvent || this.isRepeatingOccurrence;
    },
    isDisabledEndDate() {
      return this.hasLimitedEditRight || this.isRepeatingOccurrence || this.hasRepeatingPattern;
    },
    isVisibleRepeatingEventAlert() {
      return this.isEditMode && this.activeEvent.repeating;
    },
    isVisibleEventInThePastAlert() {
      return this.isEndDateInThePast && this.isClickedOK && !this.validateRepeatingEvent;
    },
    canEditRepeatingPattern() {
      return (
        this.eventForm.type !== eventTypeEnum.LESSON && this.eventForm.repeating != null && !this.isRepeatingOccurrence
      );
    },
    canAddEventToInstitutionCalendar() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        this.hasPermissionOnInstitution(
          permissionEnum.CREATE_EVENTS_IN_INSTITUTION_CALENDAR,
          this.eventForm.institutionCode
        ) &&
        !this.isCoOrganizer &&
        !this.isRepeatingOccurrence
      );
    },
    canAddEventOnlyToInstitutionCalendar() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        this.hasPermissionOnInstitution(
          permissionEnum.CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR,
          this.eventForm.institutionCode
        ) &&
        !this.isCoOrganizer &&
        !this.isRepeatingOccurrence
      );
    },
    canEditDeadline() {
      return (
        this.eventForm.responseRequired && this.profile.role === portalRoles.EMPLOYEE && !this.isRepeatingOccurrence
      );
    },
    isCreator() {
      return this.getIsCreator();
    },
    isCoOrganizer() {
      return this.getIsCoOrganizer();
    },
    isInvitee() {
      return this.getIsCurrentEventInvitee();
    },
    isInviteeAsOtp() {
      return this.getIsCurrentEventInviteeAsOtp();
    },
    isParticipant() {
      return this.isInvitee || this.isInviteeAsOtp;
    },
    hasLimitedEditRight() {
      return this.eventForm.type === eventTypeEnum.LESSON || this.isHandlingOthersEvent;
    },
    isResponseDeadlineDisabled() {
      return isAfter(new Date(), this.eventForm.startDateTime);
    },
    canHandleOthersEvents() {
      return this.getCanHandleOthersEvents();
    },
    canHandlePrivateEvent() {
      const isPrivateEventCreator =
        this.institutions.filter(inst => inst.institutionProfileId == this.activeEvent?.creator?.id).length > 0;

      const institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
      const isPrivateEventCoOrganizer = this.activeEvent.coOrganizers.some(coOrganizer =>
        institutionProfileIds.includes(coOrganizer.instProfile.id)
      );
      return isPrivateEventCreator || isPrivateEventCoOrganizer;
    },
    isHandlingOthersEvent() {
      return this.canHandleOthersEvents && !this.isCreator && !this.isCoOrganizer;
    },
    isHandlingOthersPrivateEvent() {
      return this.eventForm.private && this.canHandleOthersEvents && !this.isParticipant && !this.canHandlePrivateEvent;
    },
    deadlinePickerOptions() {
      const startDate = this.startDate;
      return {
        disabledDate(time) {
          return moment(time).isBefore(moment().subtract(1, 'days')) || moment(time).isAfter(moment(startDate));
        },
        firstDayOfWeek: isoWeekdayEnum.MONDAY,
      };
    },
    isTransversalGroup() {
      return this.group.type === groupTypes.CROSS_INSTITUTIONAL || this.group.type === this.groupTypes.MUNICIPAL;
    },
    institutionsThatCanBeSeen() {
      let institutions = this.institutions;
      const group = this.group;
      if (this.delegatedContext.institutionCode != null) {
        institutions = institutions.filter(
          institution => institution.institutionCode === this.delegatedContext.institutionCode
        );
      } else if (this.parent === parentTypes.GROUP) {
        institutions = filterGroupMembershipInstitutions(institutions, group).filter(
          institution =>
            this.hasPermissionOnInstitutionGroup({
              permissionId: permissionEnum.INVITE_TO_EVENT,
              institution,
              group,
            }) ||
            this.hasPermissionOnInstitution(
              permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
              institution.institutionCode
            )
        );
      }
      return institutions;
    },
    additionalLocationSearchResults() {
      let searchResults = this.additionalLocationResults;
      if (Number.isInteger(this.eventForm.primaryResource?.id)) {
        searchResults = this.filterResourcesFromSearchResult(searchResults, [this.eventForm.primaryResource]);
      }
      return searchResults;
    },
    canSelectInstitutionInGroup() {
      return this.parent === parentTypes.GROUP && this.group.membershipInstitutions?.length > 1;
    },
    canUsePlanningAssistant() {
      return (
        !this.isMobile &&
        this.profile.role === portalRoles.EMPLOYEE &&
        this.eventForm.type !== eventTypeEnum.LESSON &&
        (this.isRepeatingOccurrence || (!this.isRepeatingEvent && !this.hasRepeatingPattern))
      );
    },
    isPlanningAssistantDisabled() {
      return moment(this.startDate).diff(this.endDate) !== 0;
    },
    invitedGroupsName() {
      return this.eventForm.invitedGroups
        .filter(group => group.type === groupTypes.UNI)
        .map(group => group.name)
        .join(', ');
    },
    canAttachFromSecureFiles() {
      return (
        this.profile.role == portalRoles.EMPLOYEE &&
        (this.hasPermission(permissionEnum.HANDLE_SECURE_FILES) ||
          this.hasPermission(permissionEnum.HANDLE_SECURE_FILES_LIMITED))
      );
    },
    canEditRequestForAnswer() {
      // NOTE: Edit mode is how you test if you're using the edit route...
      if (this.isEditMode === true) {
        let canEditAsOwner = this.isCreator;
        if (
          canEditAsOwner === false &&
          this.delegatedContext != null &&
          this.delegatedContext.institutionProfileId != null
        ) {
          canEditAsOwner = this.eventForm.creator.id === this.delegatedContext.institutionProfileId;
        }

        return canEditAsOwner && this.eventForm.private === false;
      }

      return true;
    },
    listBlockedCommunication() {
      const result = [];
      for (const key in this.getblockedCommunicationProfiles) {
        if (this.getblockedCommunicationProfiles[key]) {
          result.push(key);
        }
      }
      return result;
    },
    primaryTeachers() {
      if (this.eventForm.type !== eventTypeEnum.LESSON || !this.eventForm.lesson?.participants) {
        return [];
      }

      return this.eventForm.lesson.participants
        .filter(participant => participant.participantRole === teacherTypes.PRIMARY)
        .map(teacher => teacher.participantProfile);
    },
    editorId() {
      return this.parent == parentTypes.GROUP ? 'editorInGroupPage' : 'editorInCalendar';
    },
    isEditMode() {
      return this.$route.path.includes('redigerbegivenhed');
    },
    enterOccurenceLimit() {
      switch (this.eventForm.repeating.pattern) {
        case repeatingEventPatternEnum.NEVER:
          return false;
        case repeatingEventPatternEnum.DAILY:
          return !!this.daily.occurenceLimit;
        case repeatingEventPatternEnum.WEEKLY:
          return !!this.weekly.occurenceLimit;
        case repeatingEventPatternEnum.MONTHLY:
          return !!this.monthly.occurenceLimit;
      }
      return false;
    },
    sendDisabled() {
      return !this.startDate || (this.isCommunicationBlock && this.canInviteToEvent);
    },
    validateRepeatingEvent() {
      switch (this.eventForm.repeating.pattern) {
        case repeatingEventPatternEnum.NEVER:
          return false;
        case repeatingEventPatternEnum.DAILY:
          return this.invalidDailyInterval || this.invalidDailyoccurenceLimit;
        case repeatingEventPatternEnum.WEEKLY:
          return this.invalidWeeklyWeekdayMask || this.invalidWeeklyOccurenceLimit;
        case repeatingEventPatternEnum.MONTHLY:
          return this.invalidMonthlyOccurenceLimit;
      }
      return true;
    },
    invalidDailyInterval() {
      return !this.daily.interval || isNaN(this.daily.interval) || this.daily.interval <= 0;
    },
    invalidDailyoccurenceLimit() {
      return (
        (!this.daily.occurenceLimit ||
          isNaN(this.daily.occurenceLimit) ||
          this.daily.occurenceLimit <= 0 ||
          this.daily.occurenceLimit > 2000) &&
        !this.selectedMaxDate &&
        !this.eventNeverEnd
      );
    },
    showAlertOutOfMonthlyDate() {
      if (moment(this.startDate).date() > 28 && this.eventForm.repeating.pattern !== repeatingEventPatternEnum.NEVER) {
        if (this.eventNeverEnd) {
          return true;
        } else {
          let differenceInMonths = 0;
          if (this.eventForm.repeating.maxDate != null && this.eventForm.repeating.maxDate != '') {
            differenceInMonths = moment(this.eventForm.repeating.maxDate).diff(moment(this.startDate), 'months');
          } else if (this.monthly.occurenceLimit != null && this.monthly.occurenceLimit > 0) {
            differenceInMonths = this.monthly.occurenceLimit;
          }
          for (let i = 0; i < differenceInMonths; i++) {
            if (moment(this.startDate).add(i, 'months').daysInMonth() < moment(this.startDate).date()) {
              return true;
            }
          }
        }
      }
      return false;
    },
    invalidWeeklyWeekdayMask() {
      return this.weekly.weekdayMask.length == 0;
    },
    invalidWeeklyOccurenceLimit() {
      return (
        (!this.weekly.occurenceLimit ||
          isNaN(this.weekly.occurenceLimit) ||
          this.weekly.occurenceLimit <= 0 ||
          this.weekly.occurenceLimit > 2000) &&
        !this.selectedMaxDate &&
        !this.eventNeverEnd
      );
    },
    invalidMonthlyOccurenceLimit() {
      return (
        (!this.monthly.occurenceLimit ||
          isNaN(this.monthly.occurenceLimit) ||
          this.monthly.occurenceLimit <= 0 ||
          this.monthly.occurenceLimit > 2000) &&
        !this.selectedMaxDate &&
        !this.eventNeverEnd
      );
    },
    invitedGroupNames() {
      const groupDict = {};
      const groupIdDict = {};
      const allowedGroupTypes = [groupTypes.UNI, groupTypes.INSTITUTIONAL, 'group'];

      if (this.isEditMode) {
        this.activeEvent.invitedGroups?.forEach(group => {
          groupIdDict[group.id] = true;
          groupDict[group.id] = {
            name: group.name,
          };
        });
        this.activeEvent.inviteeGroups?.forEach(invitedGroup => {
          groupDict[invitedGroup.group.id] = {
            name: invitedGroup.group.name,
            portalRoles: new Set(invitedGroup.invitedPortalRoles),
          };
        });
      }

      const uniqueGroups = this.eventForm.invitedGroups.filter(
        group => groupIdDict[group.id] !== true && allowedGroupTypes.includes(group.type) === true
      );

      for (const group of uniqueGroups) {
        const map = groupDict[group.id] || {};
        const portalRoles = map.portalRoles || new Set();

        if (group.portalRole != null) {
          portalRoles.add(group.portalRole);
        }

        groupDict[group.id] = {
          name: group.name,
          portalRoles,
        };
      }

      const names = Object.values(groupDict).map(groupMap => {
        const portalRoles = groupMap.portalRoles;
        const hasAllPortalRoles = !(portalRoles instanceof Set) || portalRoles.size === 0 || portalRoles.size >= 3;

        if (hasAllPortalRoles) {
          return groupMap.name;
        }

        return this.getGroupNameWithPortalRoleNames(groupMap.name, portalRoles);
      });

      return names.join(', ');
    },
    endDateInThePastAlertText() {
      if (this.isEditMode) {
        return this.$options.filters.fromTextKey('CALENDAR_ALERT_EDIT_END_TIME_IN_THE_PAST');
      }
      return this.$options.filters.fromTextKey('CALENDAR_ALERT_CREATE_END_TIME_IN_THE_PAST');
    },
    repeatingInThePastAlertText() {
      if (this.isEditMode) {
        return this.$options.filters.fromTextKey('CALENDAR_ALERT_EDIT_REPEATING_IN_THE_PAST');
      }
      return this.$options.filters.fromTextKey('CALENDAR_ALERT_CREATE_REPEATING_IN_THE_PAST');
    },
    eventInThePastAlertText() {
      return this.isRepeatingEvent ? this.repeatingInThePastAlertText : this.endDateInThePastAlertText;
    },
    isRepeatingEvent() {
      return this.activeEvent.repeating != null;
    },
    hasRepeatingPattern() {
      return this.eventForm.repeating && this.eventForm.repeating.pattern !== repeatingEventPatternEnum.NEVER;
    },
    isRepeatingOccurrence() {
      return this.$route.query.occurrence != null;
    },
    isEndDateInThePast() {
      const currentTime = now();
      const endDateTime = moment(this.endDate + ' ' + this.endTime);
      return isBefore(endDateTime, currentTime);
    },
    hasSameRepeatingPattern() {
      const hasSameStartDate = isSame(this.eventForm.startDateTime, this.activeEvent.startDateTime, 'minute');
      const hasSameMaxDate =
        isSame(this.eventForm.repeating.maxDate, this.activeEvent.repeating.maxDate, 'minute') ||
        this.activeEvent.repeating.maxDate === this.eventForm.repeating.maxDate;
      const hasSameWeekdayMask = isEqual(this.eventForm.repeating.weekdayMask, this.activeEvent.repeating.weekdayMask);
      const hasSameDayInMonth = this.eventForm.repeating.dayInMonth === this.activeEvent.repeating.dayInMonth;
      const hasSameInterval = this.eventForm.repeating.interval === this.activeEvent.repeating.interval;
      const hasSameOccurrenceLimit =
        this.eventForm.repeating.occurenceLimit === this.activeEvent.repeating.occurenceLimit;
      const hasSameEndDate = isSame(this.eventForm.endDateTime, this.activeEvent.endDateTime, 'minute');

      return (
        hasSameDayInMonth &&
        hasSameMaxDate &&
        hasSameInterval &&
        hasSameOccurrenceLimit &&
        hasSameStartDate &&
        hasSameWeekdayMask &&
        hasSameEndDate
      );
    },
  },
  watch: {
    eventNeverEnd: function (val) {
      if (val) {
        this.eventForm.repeating.maxDate = null;
        this.selectedMaxDate = false;
        this.resetOccurrenceLimit();
      }
    },
    unavailableResourceIds() {
      if (Array.isArray(this.unavailableResourceIds) && this.unavailableResourceIds.length > 0) {
        this.showUnavailableResources();
      }
    },
  },
  created() {
    this.populateEventTypes();
  },
  mounted() {
    this.resetForm();
    this.setAtrributesForTimepicker();
    this.resetRepeatingObject = Object.assign({}, this.eventForm.repeating);
    if (this.basicEventTypes.length > 0) {
      this.eventForm.type = this.basicEventTypes[0].type;
    }
    if (this.isEditMode) {
      this.loadEditedEvent();
    } else {
      this.resetActiveEvent();
      this.bindingStartAndEndDateTime(this.getDateTimeSelectedByClickedTimeslot);
      this.eventForm.startDateTime = moment(this.startDate + ' ' + this.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      this.eventForm.endDateTime = moment(this.endDate + ' ' + this.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      if (this.parent === parentTypes.GROUP) {
        let existingAttendees = [];
        if (this.group.id != null) {
          this.loadGroupMemberships({
            groupId: this.group.id,
            portalRoles: [portalRoles.CHILD, portalRoles.OTP, portalRoles.EMPLOYEE, portalRoles.GUARDIAN],
          }).then(() => {
            for (const groupMember of this.groupMemberships) {
              for (const institutionProfile of groupMember.institutionProfiles) {
                const isProfileMe = this.institutions.some(
                  inst => inst.institutionProfileId === institutionProfile.institutionProfileId
                );
                if (this.isTransversalGroup || !isProfileMe) {
                  this.addExistingAttendee(existingAttendees, institutionProfile, groupMember.portalRole);
                }
              }
            }
            if (existingAttendees.length > 2000) {
              this.$refs.tooManyRecipientsModal.show();
              existingAttendees = existingAttendees.slice(0, 2000);
            }
            this.existingInvitees = existingAttendees;
            this.eventForm.invitees = existingAttendees;
          });
        }
        const groupItem = {
          label: this.group.name,
          name: this.group.name,
          value: parentTypes.GROUP + this.group.id + this.group.institutionCode,
          id: this.group.id,
          portalRole: null,
          type: parentTypes.GROUP,
        };
        this.eventForm.invitedGroups = [groupItem];
        this.eventForm.invitedGroupIds = [groupItem.id];
      }
      this.isLoadingDescription = false;
    }
    // Make sure user can only create an event that last a month
    const startDate = this.startDate;
    this.availableEndDate = {
      disabledDate(time) {
        return !isDateBetween(time, startDate, addToDate(startDate, 1, 'month'));
      },
      firstDayOfWeek: 1,
    };
    this.recurringDatePickerOptions = {
      disabledDate(time) {
        return isBefore(time, startDate, 'days');
      },
      firstDayOfWeek: 1,
    };

    this.emptyEventForm = cloneDeep(this.eventForm);
  },
  methods: {
    getIsResourceFullyAvailable(resource) {
      const numberOfOccurrences = resource.numberOfOccurrences || 1;
      const numberOfAvailableOccurrences = resource.numberOfAvailableOccurrences || 1;
      return numberOfAvailableOccurrences === numberOfOccurrences;
    },
    handleStartDateChanged() {
      this.checkForExistingResourcesWhenChangingDateTime();
      this.adjustEndDate();
    },
    adjustEndDate() {
      if (this.hasRepeatingPattern) {
        this.endDate = this.startDate;
        return;
      }
      const maxEndDate = addToDate(this.startDate, 1, 'month');
      if (isBefore(maxEndDate, this.endDate)) {
        this.endDate = maxEndDate;
      }
    },
    onRepeatingPatternChange() {
      if (this.eventForm.repeating.pattern !== repeatingEventPatternEnum.NEVER) {
        this.simpleEventModel.setDoRequestNumberOfParticipants(false);
        this.simpleEventModel.setMaxParticipantsEnabled(false);
        this.eventForm.doRequestNumberOfParticipants = this.simpleEventModel.getDoRequestNumberOfParticipants();
        if (this.weekly.weekdayMask.length === 0) {
          this.weekly.weekdayMask.push(moment(this.startDate).isoWeekday() % 7);
        }
      }
    },
    getFirstWeeklyOccurrence(weekdayMasks, startDate) {
      let firstOccurrence = getDate(startDate);
      for (let i = 0; i < weekdayMasks.length; i++) {
        const currentWeekday = getIsoWeekday(firstOccurrence) % 7;
        if (weekdayMasks[currentWeekday] === true) {
          break;
        }
        firstOccurrence = addToDate(firstOccurrence, 1, 'days');
      }
      return firstOccurrence;
    },
    updateLocations(locations) {
      if (this.eventForm.type === eventTypeEnum.LESSON) {
        this.eventForm.additionalLocations = locations;
        return;
      }
      const [primaryLocation, ...additionalLocations] = locations;
      this.selectedLocations = locations;
      this.eventForm.primaryResource = primaryLocation;
      this.eventForm.additionalLocations = additionalLocations;
    },
    searchLocations(query) {
      this.loadEventLocations(query).then(locations => {
        this.locationSearchResults = locations;
      });
    },
    onChangeDefineParticipantSeats(value) {
      this.simpleEventModel.setMaxParticipantsEnabled(value);
      this.eventForm.maximumNumberOfParticipants = this.simpleEventModel.getMaximumNumberOfParticipants();
    },
    onChangeRequestNumberOfParticipants() {
      this.eventForm.doRequestNumberOfParticipants = this.simpleEventModel.getDoRequestNumberOfParticipants();
    },
    onInputMaximumNumberOfParticipants(maximumNumberOfParticipants) {
      this.simpleEventModel.setMaximumNumberOfParticipants(maximumNumberOfParticipants);
      this.eventForm.maximumNumberOfParticipants = this.simpleEventModel.getMaximumNumberOfParticipants();
    },
    addExistingAttendee(existingAttendees, member, portalRole) {
      let profile = {
        label: Vue.filter('displayProfileNameWithMetadata')(member),
        name: Vue.filter('displayProfileNameWithMetadata')(member),
        value: parentTypes.PROFILE + member.institutionProfileId,
        id: member.institutionProfileId,
        profileId: member.profileId,
        portalRole,
        institutionCode: member.institutionCode,
        type: parentTypes.PROFILE,
      };

      if (member.otpInboxId != null) {
        profile = {
          label: Vue.filter('displayProfileNameWithMetadata')(member),
          name: Vue.filter('displayProfileNameWithMetadata')(member),
          value: parentTypes.PROFILE + '_OTP_' + member.otpInboxId,
          id: member.otpInboxId,
          otpInboxId: member.otpInboxId,
          portalRole: portalRoles.OTP,
          institutionCode: member.institutionCode,
          type: portalRoles.OTP,
        };
      }

      existingAttendees.push(profile);
    },
    filterResourcesFromSearchResult(searchResults, resources) {
      const resourcesSet = new Set(resources.map(resource => resource.id));
      return searchResults.map(searchResult => ({
        ...searchResult,
        options: searchResult.options.filter(({ id }) => !resourcesSet.has(id)),
      }));
    },
    getGroupNameWithPortalRoleNames(name, portalRolesList) {
      const portalRoleTexts = [];
      for (const portalRole of portalRolesList) {
        portalRoleTexts.push(getPortalRoleText(portalRole));
      }
      return `${name} (${portalRoleTexts.join(', ')})`;
    },
    handleResetRecipientsInput() {
      if (this.parent !== parentTypes.GROUP) {
        this.resetRecipientsInput = !this.resetRecipientsInput;
      }
      this.resetPrimaryResources = !this.resetPrimaryResources;
      this.resetResources = !this.resetResources;

      this.invitedGroups = [];
      this.existingInvitees = [];
      this.eventForm.resources = [];
      this.eventForm.additionalLocations = [];
      this.eventForm.primaryResource = {};
      this.eventForm.invitees = [];
      this.eventForm.invitedGroups = [];
      this.eventForm.invitedOtpInboxIds = [];
    },
    mapPlanningAssistantRecipients() {
      const existingAttendees = [];
      if (this.eventForm.invitees != null && this.eventForm.invitees.length > 0) {
        this.eventForm.invitees.forEach(function (invitee) {
          invitee.label = Vue.filter('displayProfileNameWithMetadata')(invitee);
          invitee.value = 'profile' + invitee.id;

          existingAttendees.push({
            label: Vue.filter('displayProfileNameWithMetadata')(invitee),
            name: Vue.filter('displayProfileNameWithMetadata')(invitee),
            institutionCode: invitee.institutionCode,
            value: 'profile' + invitee.id,
            id: invitee.id,
            portalRole: invitee.role,
            type: 'profile',
          });
        });
      }
      this.existingInvitees = existingAttendees;
    },
    updateLocationsFromPlanningAssistant(locations) {
      this.existingLocations = locations;
      this.updateLocations(locations);
    },
    attachSecureDocuments(documents) {
      this.$refs.uploaderFiles.addNewMedia(documents);
    },
    resetEventFormFromPlanningAssistant(eventForm) {
      this.eventForm = eventForm;

      this.resetPrimaryResources = !this.resetPrimaryResources;
      this.selectedLocations = [];
      if (eventForm.primaryResource) {
        this.selectedLocations.push(eventForm.primaryResource, ...eventForm.additionalLocations);
      }
      this.existingLocations = this.selectedLocations;

      this.resetResources = !this.resetResources;
      this.existingResources = eventForm.resources;
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.updateInvitees(eventForm.invitees);
      this.mapPlanningAssistantRecipients();
      this.startTime = moment(eventForm.startDateTime).format('HH:mm');
      this.endTime = moment(eventForm.endDateTime).format('HH:mm');
      this.startDate = moment(eventForm.startDateTime).format('YYYY-MM-DD');
      this.endDate = moment(eventForm.endDateTime).format('YYYY-MM-DD');
    },
    updateResourcesFromPlanningAssistant(resources) {
      this.resetResources = !this.resetResources;
      this.addResourceToList(resources);
    },
    updateInviteesFromPlanningAssistant(invitees) {
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.updateInvitees(invitees);
      this.mapPlanningAssistantRecipients();
    },
    updateStartTimeFromPlanningAssistant(startTime) {
      this.removeResources();
      this.startTime = startTime;
    },
    updateStartDateFromPlanningAssistant(startDate) {
      if (startDate != this.startDate) {
        this.removeResources();
      }
      this.startDate = startDate;
      this.endDate = startDate;
    },
    updateEndTimeFromPlanningAssistant(endTime) {
      this.removeResources();
      this.eventForm.endDateTime = moment(`${this.endDate} ${endTime}`).format();
      this.endTime = endTime;
    },
    validateAddToInstitutionCalendar() {
      Vue.nextTick(() => {
        if (this.eventForm.addToInstitutionCalendar) {
          this.eventForm.hideInOwnCalendar = false;
        }
        this.$forceUpdate();
      });
    },
    validateHideInOwnCalendar() {
      Vue.nextTick(() => {
        if (this.eventForm.hideInOwnCalendar) {
          this.eventForm.addToInstitutionCalendar = false;
        }
        this.$forceUpdate();
      });
    },
    validatePrivateAndAddToInstCalendar() {
      Vue.nextTick(() => {
        if (this.eventForm.private && (this.eventForm.addToInstitutionCalendar || this.eventForm.hideInOwnCalendar)) {
          this.$refs.bothPrivateAndAddedToInstCalendarModal.show();
          this.eventForm.private = false;
          this.eventForm.addToInstitutionCalendar = false;
          this.eventForm.hideInOwnCalendar = false;
        }
      });
    },
    cancelForm() {
      this.resetForm();
      this.$refs.cancelModal.hide();
      if (this.parent === parentTypes.PROFILE) {
        this.$router.push({ path: '/kalender' });
      } else {
        this.$router.push({ name: 'group-calendar' });
      }
    },
    filterInviteeGroups(inviteeGroups, blockedGroups) {
      const filteredInviteeGroups = [];
      for (const inviteeGroup of inviteeGroups) {
        const filteredBlockedGroups = blockedGroups.filter(
          blockedGroup => blockedGroup.blockedGroup.id === inviteeGroup.groupId
        );
        const blockedPortalRoles = filteredBlockedGroups.map(blockedGroup => blockedGroup.portalRole);
        const filteredPortalRoles = inviteeGroup.portalRoles.filter(
          portalRole => blockedPortalRoles.indexOf(portalRole) === -1
        );

        if (filteredPortalRoles.length === 0) {
          continue;
        }

        filteredInviteeGroups.push({
          ...inviteeGroup,
          portalRoles: filteredPortalRoles,
        });
      }
      return filteredInviteeGroups;
    },
    isTheSameAuthorityInstitutions(invitee) {
      if (this.eventForm.institutionCode != invitee.institutionCode) {
        if (
          this.sameAuthorityInstitutions.length == 0 ||
          (this.sameAuthorityInstitutions.length > 0 &&
            this.sameAuthorityInstitutions.filter(institution => institution.institutionCode == invitee.institutionCode)
              .length == 0)
        ) {
          return false;
        }
      }
      return true;
    },
    updateInvitees(invitees) {
      this.hasExternalRecipient = false;
      const eventInvitees = [];
      const groupInvitees = [];
      const groupHomeInvitees = [];

      for (const invitee of invitees) {
        if (invitee.type === 'group') {
          groupInvitees.push(invitee);
        } else if (invitee.type === 'groupHome' || invitee.otpInboxId) {
          groupHomeInvitees.push(invitee);
          eventInvitees.push(invitee);
        } else {
          if (eventInvitees.some(inv => inv.id === invitee.id) === false) {
            eventInvitees.push(invitee);
          }
        }
      }

      if (this.eventForm.invitees.length < invitees.length) {
        this.hasExternalRecipient = !this.isTheSameAuthorityInstitutions(invitees[invitees.length - 1]);
      }

      if (this.hasExternalRecipient) {
        this.$refs.externalRecipientsToastr.show();
      }
      if (eventInvitees.length > 2000) {
        this.$refs.tooManyRecipientsModal.show();
      }

      this.eventForm.invitees = eventInvitees;
      this.eventForm.invitedGroups = groupInvitees;
      const groupHomeInviteeOtpInboxIds = groupHomeInvitees.map(
        groupHomeInvitee => groupHomeInvitee.otpInboxId || groupHomeInvitee.id
      );
      this.eventForm.invitedOtpInboxIds = [...new Set([...groupHomeInviteeOtpInboxIds])];
    },
    checkCoOrganizerList(organizers) {
      let removedExistingOrganizer;

      if (this.isEditMode) {
        const coOrganizerSet = new Set(organizers.map(organizer => `${organizer.profileId},${organizer.id}`));
        removedExistingOrganizer = this.existingOrganizers.find(
          existingOrganizer => coOrganizerSet.has(`${existingOrganizer.profileId},${existingOrganizer.id}`) === false
        );
      }

      if (removedExistingOrganizer != null) {
        this.tmpOrganizers = organizers;
        this.selectedOrganizers = [...organizers, removedExistingOrganizer];
        this.$refs.removingCoOrganizerWarning.show();
      } else {
        this.setOrganizers(organizers);
      }
    },
    acceptRemovalOfExistingOrganizer() {
      const coOrganizerSet = new Set();
      for (const organizer of this.tmpOrganizers) {
        coOrganizerSet.add(`${organizer.profileId},${organizer.id}`);
      }

      this.existingOrganizers = this.existingOrganizers.filter(
        existingOrganizer => coOrganizerSet.has(`${existingOrganizer.profileId},${existingOrganizer.id}`) === true
      );

      this.selectedOrganizers = this.tmpOrganizers;
      this.setOrganizers(this.tmpOrganizers);
      this.$refs.removingCoOrganizerWarning.hide();
    },
    setOrganizers(organizers) {
      this.eventForm.coOrganizerIds = organizers.map(organizer => organizer.id);
      this.eventForm.coOrganizers = organizers;
    },
    filesChanged(attachments) {
      this.eventForm.attachments = attachments;
      this.existingAttachments = attachments;
    },
    closeEventForm() {
      if (isEqualWith(this.emptyEventForm, this.eventForm)) {
        this.resetForm();
        if (this.parent === parentTypes.PROFILE) {
          this.$router.push({ path: '/kalender' });
        } else {
          this.$router.push({ name: 'group-calendar' });
        }
      } else {
        this.$refs.cancelModal.show();
      }
    },
    checkUserWithBlockedCommunication() {
      if (this.isCommunicationBlock) {
        this.$refs.blockedCommunicationModal.show();
      }
    },
    handleOkDialogConflict() {
      this.clearConflictingAttendees();
      this.checkForAttachments();
    },
    selectMaxDate() {
      if (this.eventForm.repeating.maxDate == null) {
        this.selectedMaxDate = false;
      } else {
        if (moment(this.eventForm.repeating.maxDate).isSameOrBefore(moment(this.startDate))) {
          this.eventForm.repeating.maxDate = moment(this.startDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        }
        this.selectedMaxDate = true;
        this.resetOccurrenceLimit();
      }
    },
    resetOccurrenceLimit() {
      this.daily.occurenceLimit = '';
      this.weekly.occurenceLimit = '';
      this.monthly.occurenceLimit = '';
    },
    removeResources() {
      this.softRemoveResources();
    },
    softRemoveResources() {
      this.eventForm.primaryResource = {};
      this.eventForm.additionalLocations = [];
      this.eventForm.resources = [];
      this.existingResources = [];
      this.existingLocations = [];
      this.selectedLocations = [];
      this.resetResources = !this.resetResources;
      this.resetPrimaryResources = !this.resetPrimaryResources;
      this.populateDateTimePicker();
    },
    resetAllDay() {
      this.eventForm.allDay = !this.eventForm.allDay;
    },
    resetDateTime() {
      if (this.isEditMode && this.eventForm.repeating != null) {
        this.startDate = moment(this.eventForm.repeating.originalStartDateTime).format('YYYY-MM-DD');
        this.endDate = moment(this.eventForm.repeating.originalEndDateTime).format('YYYY-MM-DD');
        this.startTime = moment(this.eventForm.repeating.originalStartDateTime).format('HH:mm');
        this.endTime = moment(this.eventForm.repeating.originalEndDateTime).format('HH:mm');
        this.mapRepeatingEvent();
      } else {
        this.startDate = moment(this.eventForm.startDateTime).format('YYYY-MM-DD');
        this.endDate = moment(this.eventForm.endDateTime).format('YYYY-MM-DD');
        this.startTime = moment(this.eventForm.startDateTime).format('HH:mm');
        this.endTime = moment(this.eventForm.endDateTime).format('HH:mm');
      }
    },
    checkValidDateTime(field) {
      switch (field) {
        case 'startDate':
          this.isStartDateClicked = false;
          if (!this.startDate || !moment(this.startDate, 'YYYY-MM-DD').isValid()) {
            this.startDate = moment().add(30, 'minutes').format('YYYY-MM-DD');
          }
          break;
        case 'endDate':
          this.isEndDateClicked = false;
          if (!this.endDate || !moment(this.endDate, 'YYYY-MM-DD').isValid()) {
            this.endDate = moment().add(60, 'minutes').format('YYYY-MM-DD');
          }
          break;
      }
    },
    allDayChange() {
      Vue.nextTick(() => {
        if (
          (this.eventForm.primaryResource != null && Object.keys(this.eventForm.primaryResource).length > 0) ||
          (this.eventForm.additionalLocations != null && this.eventForm.additionalLocations.length > 0) ||
          (this.eventForm.resources != null && this.eventForm.resources.length > 0)
        ) {
          this.$refs.removeResourcesWhenChangingAllDayModal.show();
        } else {
          this.showMissingStartDateAlert = false;
        }
      });
    },
    checkForExistingResourcesWhenChangingDateTime() {
      if (
        (this.eventForm.primaryResource != null && Object.keys(this.eventForm.primaryResource).length > 0) ||
        (this.eventForm.additionalLocations != null && this.eventForm.additionalLocations.length > 0) ||
        (this.eventForm.resources != null && this.eventForm.resources.length > 0)
      ) {
        this.$refs.removeResourcesWhenChangingDateTimeModal.show();
      } else {
        this.softRemoveResources();
      }
      this.setLayer(false);
    },
    setLayer(show) {
      if (show) {
        this.$refs.clickLayer.style.display = 'block';
      } else {
        this.$refs.clickLayer.style.display = 'none';
      }
    },
    populateDateTimePicker() {
      if (this.isRepeatingEvent) {
        this.endDate = this.startDate;
        if (this.selectedMaxDate && moment(this.eventForm.repeating.maxDate).isSameOrBefore(moment(this.startDate))) {
          this.eventForm.repeating.maxDate = moment(this.startDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        }
      }
      // compare startDateTime vs endDatetime
      const startDateTime = this.startDate + ' ' + this.startTime;
      let endDateTime = this.endDate + ' ' + this.endTime;
      if (moment(startDateTime).isSameOrAfter(moment(endDateTime))) {
        if (moment(startDateTime).date() != moment(startDateTime).add(30, 'minutes').date()) {
          endDateTime = moment(startDateTime).hour('23').minute('59').second('59');
        } else {
          endDateTime = moment(startDateTime).add(30, 'minutes');
        }
        this.endDate = endDateTime.format('YYYY-MM-DD');
        this.endTime = endDateTime.format('HH:mm:ss');
      }

      const startDate = format(this.startDate, dateFormatEnum.COMPLETE_DATE);
      const endDate = format(this.endDate, dateFormatEnum.COMPLETE_DATE);

      this.eventForm.startDateTime = moment(startDate + ' ' + this.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      this.eventForm.endDateTime = moment(endDate + ' ' + this.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');

      this.availableEndDate = {
        disabledDate(time) {
          return !isDateBetween(time, addToDate(startDateTime, -1, 'days'), addToDate(startDateTime, 1, 'month'));
        },
        firstDayOfWeek: 1,
      };
      this.recurringDatePickerOptions = {
        disabledDate(time) {
          return isBefore(time, startDateTime, 'days');
        },
        firstDayOfWeek: 1,
      };
      if (
        this.eventForm.responseDeadline != null &&
        this.eventForm.responseDeadline != '' &&
        moment(this.eventForm.responseDeadline).isAfter(moment(startDateTime))
      ) {
        this.showInvalidDeadlineAlert = true;
      }
    },
    bindingStartAndEndDateTime(dateObjSelected) {
      if (dateObjSelected) {
        this.eventForm.startDateTime = dateObjSelected.startDateTime;
        this.startDate = moment(dateObjSelected.startDateTime).format('YYYY-MM-DD');
        this.startTime = moment(dateObjSelected.startDateTime).format('HH:mm:ss');
        this.eventForm.endDateTime = dateObjSelected.endDateTime;
        this.endDate = moment(dateObjSelected.endDateTime).format('YYYY-MM-DD');
        this.endTime = moment(dateObjSelected.endDateTime).format('HH:mm:ss');
        if (dateObjSelected.resourceType == 'institution') {
          this.eventForm.addToInstitutionCalendar = true;
        }
      }
      this.modifyDateTimeSelectedByClickedTimeslot(null);
    },
    addResourceToList(resources) {
      this.eventForm.resources = resources;
      this.existingResources = resources;
    },
    removeResource: function (value) {
      const index = this.eventForm.resources.findIndex(o => o.id === value);
      if (index > -1) {
        this.eventForm.resources.splice(index, 1);
      }
    },
    onSaveClicked() {
      let hasWarnings = false;
      if (this.partiallyAvailableLocations.length > 0 || this.partiallyAvailableResources.length > 0) {
        this.$refs.partiallyAvailableResourcesWarning.show();
        hasWarnings = true;
      }
      if (this.isRepeatingEvent && !this.isRepeatingOccurrence && !this.hasSameRepeatingPattern) {
        this.$refs.editRepeatingSeriesWarningModal.show();
        hasWarnings = true;
      }
      if (this.isHandlingOthersEvent && this.eventForm.type !== eventTypeEnum.LESSON) {
        this.$refs.editOthersEventWarningModal.show();
        hasWarnings = true;
      }

      if (!hasWarnings) {
        this.validateEvent();
      }
    },
    onConfirmEditOthersEvent() {
      this.$refs.editOthersEventWarningModal.hide();
      this.validateEvent();
    },
    onConfirmEditRepeatingSeries() {
      this.$refs.editRepeatingSeriesWarningModal.hide();
      if (this.partiallyAvailableLocations.length === 0 && this.partiallyAvailableResources.length === 0) {
        this.validateEvent();
      }
    },
    onConfirmPartiallyAvailableResources() {
      this.$refs.partiallyAvailableResourcesWarning.hide();
      this.validateEvent();
    },
    validateEvent() {
      this.isLoading = true;
      let saveEvent = true;

      // VALIDATE NUMBERS OF PARTICIPANTS DOES NOT EXCEED 2000
      if (this.eventForm.invitees.length > 2000) {
        this.$refs.tooManyRecipientsModal.show();
        document.getElementById('eventInvitees').scrollIntoView(true);
        saveEvent = false;
      }

      // VALIDATE EVENT FORM
      if (!this.eventForm.title.trim()) {
        this.showMissingTitleAlert = true;
        document.getElementById('eventTitle').scrollIntoView(true);
        saveEvent = false;
      } else {
        this.showMissingTitleAlert = false;
      }

      if (this.endDate == '' || this.endDate == null) {
        this.messageAlertStartDate = Vue.filter('fromTextKey')('CALENDAR_ALERT_START_DATE');
        this.showMissingEndDateAlert = true;
        document.getElementById('endDate').scrollIntoView(true);
        saveEvent = false;
      } else {
        this.showMissingEndDateAlert = false;
      }

      if (this.endTime == '' || this.endTime == null) {
        this.showMissingEndTimeAlert = true;
        document.getElementById('endTime').scrollIntoView(true);
        saveEvent = false;
      } else {
        this.showMissingEndTimeAlert = false;
      }

      if (this.startDate == '' || this.startDate == null) {
        this.messageAlertStartDate = Vue.filter('fromTextKey')('CALENDAR_ALERT_START_DATE');
        this.showMissingStartDateAlert = true;
        document.getElementById('startDate').scrollIntoView(true);
        saveEvent = false;
      } else {
        this.showMissingStartDateAlert = false;
      }

      const startDate = format(this.startDate, dateFormatEnum.COMPLETE_DATE);
      const endDate = format(this.endDate, dateFormatEnum.COMPLETE_DATE);
      if (this.eventForm.allDay) {
        this.eventForm.startDateTime = moment(startDate + ' ' + this.startTime).format('YYYY-MM-DD');
        this.eventForm.endDateTime = moment(endDate + ' ' + this.endTime).format('YYYY-MM-DD');
      } else {
        this.eventForm.startDateTime = moment(startDate + ' ' + this.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        this.eventForm.endDateTime = moment(endDate + ' ' + this.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      }

      if (this.isRepeatingEvent && !this.isRepeatingOccurrence) {
        if (this.validateRepeatingEvent) {
          this.showMissingSeriesEventAlert = true;
          document.getElementById('repeatEvent').scrollIntoView(true);
          saveEvent = false;
        } else {
          this.showMissingSeriesEventAlert = false;
        }
        if (this.eventForm.repeating.maxDate != null) {
          this.eventForm.repeating.maxDate = moment(this.eventForm.repeating.maxDate).format('YYYY-MM-DD');
        }
      }

      if (!this.isRepeatingEvent && moment(this.eventForm.endDateTime).isBefore(moment(this.eventForm.startDateTime))) {
        this.showMissingEndDateAlert = true;
        document.getElementById('endDate').scrollIntoView(true);
        saveEvent = false;
      }

      if (
        this.eventForm.responseDeadline != null &&
        this.eventForm.responseDeadline != '' &&
        moment(this.eventForm.responseDeadline).isAfter(moment(this.eventForm.startDateTime))
      ) {
        this.showInvalidDeadlineAlert = true;
        document.getElementById('deadline-date').scrollIntoView(true);
        saveEvent = false;
      }

      if (this.startTime == null && this.eventForm.allDay === false) {
        this.messageAlertStartDate = Vue.filter('fromTextKey')('CALENDAR_ALERT_START_DATE');
        this.showMissingStartTimeAlert = true;
        document.getElementById('startTime').scrollIntoView(true);
        saveEvent = false;
      }

      if (!this.simpleEventModel.isValid()) {
        saveEvent = false;
      }

      if (saveEvent) {
        this.checkForBlockedComChannels();
      } else {
        this.isLoading = false;
      }
    },
    removeBlocked() {
      this.eventForm.inviteeGroups = this.filterInviteeGroups(this.eventForm.inviteeGroups, this.blockedGroups);
      const nonBlockedProfiles = this.eventForm.invitees.filter(
        profile =>
          profile.otpInboxId == null &&
          !this.blockedProfiles.some(
            user => user.portalRole !== portalRoles.OTP && profile.id === user.institutionProfile.id
          )
      );
      let nonBlockedOtps = [];
      if (this.blockedOtps.length > 0) {
        this.eventForm.invitedOtpInboxIds = [];
        nonBlockedOtps = this.eventForm.invitees.filter(
          profile => profile.otpInboxId != null && !this.blockedOtps.some(otp => profile.otpInboxId === otp.otpInbox.id)
        );
      }

      this.eventForm.invitees = [...nonBlockedProfiles, ...nonBlockedOtps];
      this.eventForm.inviteeIds = nonBlockedProfiles
        .filter(invitee => {
          const isOtp = invitee.portalRole === portalRoles.OTP; // This checks for selected users from the search field
          const hasOtpInboxId = invitee.otpInboxId != null;
          const hasId = invitee.id != null;
          return !isOtp && !hasOtpInboxId && hasId;
        })
        .map(invitee => invitee.id);
      this.eventForm.invitedOtpInboxIds = nonBlockedOtps.map(profile => profile.otpInboxId);
      this.$nextTick(() => {
        this.resetRecipientsInput = !this.resetRecipientsInput;
        this.existingInvitees = this.eventForm.invitees;
        this.checkForConflictingEvents();
      });
    },
    async checkForBlockedComChannels() {
      this.prepareEventForm();
      this.blockedProfiles = [];
      this.blockedGroups = [];
      this.blockedOtps = [];

      let results = [];
      if (this.eventForm.inviteeGroups.length > 0 || this.eventForm.invitees.length > 0) {
        results = await this.resolveBlockedUsers({
          creator: this.profile.id,
          profiles: this.eventForm.invitees.filter(invitee => invitee.otpInboxId == null),
          groupSharings: this.eventForm.inviteeGroups,
          otpInboxIds: this.eventForm.invitedOtpInboxIds,
        });
      }

      // Both can contain OTP portal role, OTP subgroup is not for displaying
      const blockedUsers = results.filter(x => x.institutionProfile || x.otpInbox);
      const blockedGroups = results.filter(x => !x.institutionProfile && !x.otpInbox);
      if (blockedGroups.length > 0) {
        this.blockedGroups = blockedGroups.filter(x => x.portalRole !== portalRoles.OTP);
      }
      if (blockedUsers.length > 0) {
        this.blockedProfiles = blockedUsers.filter(
          (value, index, self) =>
            value.portalRole !== portalRoles.OTP &&
            self.map(x => x.institutionProfile?.id).indexOf(value.institutionProfile?.id) === index
        );
      }

      const isOtpBlocked =
        blockedGroups.some(x => x.portalRole === portalRoles.OTP) ||
        blockedUsers.some(x => x.portalRole === portalRoles.OTP);
      if (isOtpBlocked) {
        this.blockedOtps = [...new Set(blockedUsers.filter(x => x.portalRole === portalRoles.OTP || x.otpInbox))];
      }
      if (results.length > 0) {
        this.$refs.theCommunicationChannelIsBlocked.show();
        this.isLoading = false;
      } else {
        this.checkForConflictingEvents();
      }
    },
    checkForConflictingEvents() {
      if (
        this.eventForm.invitees.length > 0 &&
        this.eventForm.invitees.length < this.maxAmountOfInviteesWhenGettingConflictingEvents &&
        this.eventForm.responseRequired &&
        this.hasPermission(permissionEnum.INVITE_TO_EVENT) &&
        this.profile.role == portalRoles.EMPLOYEE
      ) {
        let profileIds = [];
        let excludeEventId = null;
        if (this.eventForm.id != null && this.eventForm.id != '') {
          excludeEventId = this.eventForm.id;
        }
        profileIds = this.eventForm.invitees.filter(invitee => invitee.id != null).map(invitee => invitee.id);
        const params = {
          start: this.eventForm.startDateTime,
          end: this.eventForm.endDateTime,
          allDay: this.eventForm.allDay,
          institutionProfileIds: profileIds,
          excludeEventId: excludeEventId,
        };
        if (this.eventForm.repeating != null && this.eventForm.repeating.pattern !== repeatingEventPatternEnum.NEVER) {
          params.occurenceLimit = this.eventForm.repeating.occurenceLimit;
          params.weekdayMask = this.eventForm.repeating.weekdayMask;
          params.pattern = this.eventForm.repeating.pattern;
          params.interval = this.eventForm.repeating.interval;
          params.maxDate = this.eventForm.repeating.maxDate;
        }
        this.warningConflictEventByAttendees(params).then(response => {
          if (response.length === 0) {
            this.checkForAttachments();
          } else {
            this.isLoading = false;
            this.$refs.conflictingEventsModal.show();
          }
        });
      } else {
        this.checkForAttachments();
      }
    },
    async checkForAttachments() {
      const preparedAttachments = Vue.filter('prepareAttachments')(this.eventForm.attachments);
      // Update existing attachments
      if (preparedAttachments.existingMedia.length > 0) {
        const updatedMedia = preparedAttachments.existingMedia.filter(att => att.media != null && att.media.updated);
        if (updatedMedia.length > 0) {
          try {
            await this.updateAttachments({
              media: Vue.filter('prepareMedia')(updatedMedia),
            });
          } catch (error) {
            this.isLoading = false;
            return;
          }
        }
      }
      if (preparedAttachments.media != null || preparedAttachments.files != null || preparedAttachments.links != null) {
        preparedAttachments.ownerInstitutionProfileId = this.institutions.find(
          inst => inst.institutionCode == this.eventForm.institutionCode
        ).institutionProfileId;
        preparedAttachments.institutionCode = this.institutions.find(
          inst => inst.institutionCode == this.eventForm.institutionCode
        ).institutionCode;
        try {
          let attachmentIds = [];
          if (
            preparedAttachments.attachedSecureDocumentIds &&
            preparedAttachments.attachedSecureDocumentIds.length > 0
          ) {
            const createLinksPayload = {
              documentIds: preparedAttachments.attachedSecureDocumentIds,
              ownerInstitutionProfileId: preparedAttachments.ownerInstitutionProfileId,
            };
            const createLinksRsp = await this.createDocumentLinks(createLinksPayload);
            attachmentIds = attachmentIds.concat(createLinksRsp.map(item => item.id));
          }
          preparedAttachments.attachedSecureDocumentIds = [];
          const createAttachmentsRsp = await this.createAttachments(preparedAttachments);
          const attachments = createAttachmentsRsp.files
            .concat(createAttachmentsRsp.links)
            .concat(createAttachmentsRsp.media)
            .concat(preparedAttachments.existingMedia);
          attachmentIds = attachmentIds.concat(attachments.map(att => att.id));
          this.eventForm.attachmentIds = attachmentIds;
          this.determineEventTypeToSave();
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        this.eventForm.attachmentIds = preparedAttachments.existingMedia.map(att => att.id);
        this.determineEventTypeToSave();
      }
    },
    determineEventTypeToSave() {
      if (this.hasRepeatingPattern) {
        this.saveRepeatingEvent();
      } else if (this.eventForm.type == eventTypeEnum.LESSON) {
        this.saveLessonEvent();
      } else {
        this.saveSimpleEvent();
      }
    },
    prepareEventForm() {
      if (this.eventForm.repeating != null) {
        this.eventForm.pattern = this.eventForm.repeating.pattern;
        this.eventForm.occurenceLimit = this.eventForm.repeating.occurenceLimit;
        this.eventForm.weekdayMask = this.eventForm.repeating.weekdayMask;
        this.eventForm.maxDate = this.eventForm.repeating.maxDate;
        this.eventForm.interval = this.eventForm.repeating.interval;
      }

      if (this.eventForm.lesson != null) {
        this.eventForm.lessonId = this.eventForm.id;
        this.eventForm.noteToClass = this.eventForm.lesson.noteToClass;
        this.eventForm.noteToSubstitute = this.eventForm.lesson.noteToSubstitute;
        this.eventForm.noteToTeacher = this.eventForm.lesson.noteToTeacher;
      }
      if (!this.eventForm.responseRequired) {
        this.eventForm.responseDeadline = null;
      }
      if (!this.isEditMode) {
        this.eventForm.creatorInstProfileId = this.delegatedContext.institutionProfileId
          ? this.delegatedContext.institutionProfileId
          : this.institutions.find(inst => inst.institutionCode == this.eventForm.institutionCode).institutionProfileId;
      }

      this.eventForm.eventId = this.eventForm.id;
      this.eventForm.isPrivate = this.eventForm.private;
      this.eventForm.inviteeIds =
        this.eventForm.invitees != null
          ? this.eventForm.invitees
              .filter(invitee => invitee.otpInboxId == null && invitee.portalRole != portalRoles.OTP)
              .map(invitee => invitee.id)
          : [];

      this.inviteEntireGroups();

      this.eventForm.resourceIds = this.eventForm.resources != null ? this.eventForm.resources.map(res => res.id) : [];
      this.eventForm.additionalLocationIds = [];
      if (this.eventForm.additionalLocations != null) {
        for (const location of this.eventForm.additionalLocations) {
          if (location.id != null) {
            this.eventForm.additionalLocationIds.push(location.id);
          }
        }
      }
      this.eventForm.additionalResourceIds = this.eventForm.resourceIds.concat(this.eventForm.additionalLocationIds);
      if (this.eventForm.primaryResource != null) {
        this.eventForm.primaryResourceId = this.eventForm.primaryResource.id;
      }
      if (this.parent === parentTypes.GROUP) {
        this.eventForm.invitedOtpInboxIds = this.eventForm.invitees
          .filter(invitee => invitee.portalRole === portalRoles.OTP)
          .map(invitee => invitee.id);
      }
    },
    inviteEntireGroups() {
      // If user invited all three portal roles of a group, we show the event on the group dashboard
      // Groups with portal roles
      const groupDict = {};
      // Groups without portal roles
      const groupIds = new Set();

      if (Array.isArray(this.eventForm.invitedGroups) === true) {
        for (const group of this.eventForm.invitedGroups) {
          const groupDictItem = groupDict[group.id] || {};
          const portalRoles = groupDictItem.portalRoles || [];
          const portalRole = group.portalRole;
          const groupId = +group.id;

          if (portalRole == null) {
            groupIds.add(groupId);
          } else {
            if (portalRoles.indexOf(portalRole) === -1) {
              portalRoles.push(portalRole);
            }
            groupDict[groupId] = {
              groupId,
              portalRoles: [...portalRoles],
            };
          }
        }
      }
      this.eventForm.inviteeGroups = Object.values(groupDict);
      const oldInvteeGroups = cloneDeep(this.activeEvent.inviteeGroups);
      if (oldInvteeGroups && oldInvteeGroups.length > 0) {
        const formattedInviteeGroups = oldInvteeGroups.map(group => ({
          groupId: group.group.id,
          portalRoles: group.invitedPortalRoles,
        }));
        formattedInviteeGroups.forEach(group => {
          const duplicatedGroup = this.eventForm.inviteeGroups.find(gr => gr.groupId === group.groupId);
          if (!duplicatedGroup) {
            this.eventForm.inviteeGroups.push(group);
          } else {
            duplicatedGroup.portalRoles.push(...group.portalRoles);
          }
        });
      }
      this.eventForm.invitedGroupIds = [...groupIds];
    },
    saveSimpleEvent() {
      delete this.eventForm.repeating;
      delete this.eventForm.lesson;

      const eventInvitees = this.eventForm.invitees;
      delete this.eventForm.invitees;
      if (this.isEditMode) {
        this.eventForm.isEditEvent = true;
        this.updateSimpleEvent(this.eventForm)
          .then(() => {
            this.resetForm();
            this.$parent.updatedEvent();
          })
          .catch(error => {
            const data = error.response?.data;
            const errorInformation = data.status?.errorInformation;
            const subCode = data.status?.subCode;
            if (subCode === errorSubCodeTypes.exceedingMaximumParticipants) {
              this.simpleEventModel.setActualNumberOfParticipants(errorInformation.actualNumberOfRecipients);
              this.simpleEventModel.isValid();
            }
            this.isLoading = false;
            this.eventForm.invitees = eventInvitees;
          });
      } else {
        this.createSimpleEvent(this.eventForm)
          .then(() => {
            this.resetForm();
            this.$parent.updatedEvent();
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    saveRepeatingEvent() {
      delete this.eventForm.lesson;
      if (this.isEditMode) {
        const occurrenceDateTime = this.$route.query.occurrence;
        this.eventForm.isEditEvent = true;
        this.updateRepeatingEvent({ ...this.eventForm, occurrenceDateTime })
          .then(() => {
            this.resetForm();
            this.$parent.updatedEvent();
          })
          .catch(error => {
            const subCode = error.response?.data.status?.subCode;
            if (subCode === errorSubCodeTypes.conflictingRepeatingEventInSameSeries) {
              this.$refs.cannotMoveRepeatingEventToExistingRepetitionWarningModal.show();
            }
            this.isBeforeFirstOccurrence = subCode === errorSubCodeTypes.firstRepeatingEventExceptionOutOfRange;
            this.isAfterLastOccurrence = subCode === errorSubCodeTypes.lastRepeatingEventExceptionOutOfRange;
            this.isLoading = false;
          });
      } else {
        this.createRepeatingEvent(this.eventForm)
          .then(() => {
            this.resetForm();
            this.$parent.updatedEvent();
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    saveLessonEvent() {
      delete this.eventForm.repeating;
      this.eventForm.isEditEvent = true;
      this.updateLessonEvent(this.eventForm).then(() => {
        this.resetForm();
        this.$parent.updatedEvent();
      });
    },
    parseRepeatingEventData() {
      this.isClickedOK = true;
      this.resetMaxDate = this.eventForm.repeating.maxDate;
      this.resetEventNeverEnd = this.eventNeverEnd;
      this.endDate = this.startDate;
      switch (this.eventForm.repeating.pattern) {
        case repeatingEventPatternEnum.DAILY:
          this.resetSeriesForm = Object.assign({}, this.daily);
          this.daily.occurenceLimit = this.daily.occurenceLimit.toString().split('.').join('');
          if (!(this.invalidDailyInterval || this.invalidDailyoccurenceLimit)) {
            this.eventForm.repeating.interval = parseInt(this.daily.interval) || 0;
            this.eventForm.repeating.occurenceLimit = parseInt(this.daily.occurenceLimit) || 0;
            this.showMissingSeriesEventAlert = false;
            this.showSeriesEventForm = false;
            this.isClickedOK = false;
          }
          break;
        case repeatingEventPatternEnum.WEEKLY:
          this.resetSeriesForm = Object.assign({}, this.weekly);
          this.weekly.occurenceLimit = this.weekly.occurenceLimit.toString().split('.').join('');
          if (!(this.invalidWeeklyOccurenceLimit || this.invalidWeeklyWeekdayMask)) {
            this.eventForm.repeating.occurenceLimit = parseInt(this.weekly.occurenceLimit) || 0;
            this.eventForm.repeating.weekdayMask = [];
            for (let i = 0; i < 7; i++) {
              if (
                (i == 0 && this.weekly.weekdayMask.includes(weekdayEnum.SUNDAY)) ||
                (i == 1 && this.weekly.weekdayMask.includes(weekdayEnum.MONDAY)) ||
                (i == 2 && this.weekly.weekdayMask.includes(weekdayEnum.TUESDAY)) ||
                (i == 3 && this.weekly.weekdayMask.includes(weekdayEnum.WEDNESDAY)) ||
                (i == 4 && this.weekly.weekdayMask.includes(weekdayEnum.THURSDAY)) ||
                (i == 5 && this.weekly.weekdayMask.includes(weekdayEnum.FRIDAY)) ||
                (i == 6 && this.weekly.weekdayMask.includes(weekdayEnum.SATURDAY))
              ) {
                this.eventForm.repeating.weekdayMask.push(true);
              } else {
                this.eventForm.repeating.weekdayMask.push(false);
              }
            }
            const startDate = getDate(this.startDate);
            this.startDate = format(
              this.getFirstWeeklyOccurrence(this.eventForm.repeating.weekdayMask, startDate),
              dateFormatEnum.COMPLETE_DATE
            );
            this.endDate = this.startDate;
            this.showMissingSeriesEventAlert = false;
            this.showSeriesEventForm = false;
            this.isClickedOK = false;
          }
          break;
        case repeatingEventPatternEnum.MONTHLY:
          this.resetSeriesForm = Object.assign({}, this.monthly);
          this.monthly.occurenceLimit = this.monthly.occurenceLimit.toString().split('.').join('');
          if (!this.invalidMonthlyOccurenceLimit) {
            this.eventForm.repeating.occurenceLimit = parseInt(this.monthly.occurenceLimit) || 0;
            this.showMissingSeriesEventAlert = false;
            this.showSeriesEventForm = false;
            this.isClickedOK = false;
          }
          break;
      }
      if (!this.isClickedOK) {
        this.resetRepeatingObject = Object.assign({}, this.eventForm.repeating);
      }
    },
    closeRepeatingDialog() {
      this.eventForm.repeating.maxDate = this.resetMaxDate;
      this.eventNeverEnd = this.resetEventNeverEnd;
      this.weekly.weekdayMask.length = 0;
      this.resetDateTime();
      if (this.eventForm.repeating.maxDate == null) {
        this.selectedMaxDate = false;
      } else {
        this.selectedMaxDate = true;
      }
      switch (this.eventForm.repeating.pattern) {
        case repeatingEventPatternEnum.DAILY:
          this.daily = Object.assign({}, this.resetSeriesForm);
          break;
        case repeatingEventPatternEnum.WEEKLY:
          this.weekly = Object.assign({}, this.resetSeriesForm);
          break;
        case repeatingEventPatternEnum.MONTHLY:
          this.monthly = Object.assign({}, this.resetSeriesForm);
          break;
      }
      if (!this.isEditMode) {
        this.eventForm.repeating = Object.assign({}, this.resetRepeatingObject);
      }
      this.isClickedOK = false;
      this.showSeriesEventForm = false;
    },
    emitRepeatEventSelected(visible, optionClicked) {
      setTimeout(() => {
        // This is forcing the method to run after the model is changed to get the correct value of the select.
        if (!visible && optionClicked) {
          this.resetMaxDate = this.eventForm.repeating.maxDate;
          this.resetEventNeverEnd = this.eventNeverEnd;
          switch (this.eventForm.repeating.pattern) {
            case repeatingEventPatternEnum.NEVER:
              this.resetRepeatingObject = Object.assign({}, this.eventForm.repeating);
              this.showSeriesEventForm = false;
              break;
            case repeatingEventPatternEnum.DAILY:
              this.resetSeriesForm = Object.assign({}, this.daily);
              this.seriesEventFormWidth = '100%';
              this.seriesEventTitle = Vue.filter('fromTextKey')('CALENDAR_TITLE_DAILY_EVENT');
              this.showSeriesEventForm = true;
              break;
            case repeatingEventPatternEnum.WEEKLY:
              this.resetSeriesForm = Object.assign({}, this.weekly);
              this.seriesEventFormWidth = '310px';
              this.seriesEventTitle = Vue.filter('fromTextKey')('CALENDAR_TITLE_WEEKLY_EVENT');
              this.showSeriesEventForm = true;
              break;
            case repeatingEventPatternEnum.MONTHLY:
              this.resetSeriesForm = Object.assign({}, this.monthly);
              this.seriesEventFormWidth = '100%';
              this.seriesEventTitle = Vue.filter('fromTextKey')('CALENDAR_TITLE_MONTHLY_EVENT');
              this.showSeriesEventForm = true;
              break;
          }
        } else {
          this.resetRepeatingObject = Object.assign({}, this.eventForm.repeating);
        }
      });
    },
    mapRepeatingEvent() {
      if (this.isRepeatingEvent && !this.isRepeatingOccurrence) {
        this.startDate = moment(this.activeEvent.repeating.originalStartDateTime).format('YYYY-MM-DD');
        this.startTime = moment(this.activeEvent.repeating.originalStartDateTime).format('HH:mm:ss');
        this.endDate = moment(this.activeEvent.repeating.originalEndDateTime).format('YYYY-MM-DD');
        this.endTime = moment(this.activeEvent.repeating.originalEndDateTime).format('HH:mm:ss');
        if (this.activeEvent.repeating.maxDate != null) {
          this.eventForm.repeating.maxDate = moment(this.activeEvent.repeating.maxDate).format('YYYY-MM-DD');
        }
        let index = 0;
        switch (this.activeEvent.repeating.pattern) {
          case repeatingEventPatternEnum.DAILY:
            this.daily.interval = parseInt(this.activeEvent.repeating.interval) || 0;
            this.daily.occurenceLimit = parseInt(this.activeEvent.repeating.occurenceLimit) || 0;
            if (this.daily.occurenceLimit == 0) {
              this.daily.occurenceLimit = '';
              if (this.activeEvent.repeating.maxDate === null) {
                this.eventNeverEnd = true;
              } else {
                this.selectedMaxDate = true;
              }
            }
            break;
          case repeatingEventPatternEnum.WEEKLY:
            this.weekly.occurenceLimit = this.activeEvent.repeating.occurenceLimit
              ? parseInt(this.activeEvent.repeating.occurenceLimit)
              : 0;
            this.weekly.weekdayMask = [];
            for (const maskBool of this.activeEvent.repeating.weekdayMask) {
              if (index == 0 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.SUNDAY);
              }
              if (index == 1 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.MONDAY);
              }
              if (index == 2 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.TUESDAY);
              }
              if (index == 3 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.WEDNESDAY);
              }
              if (index == 4 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.THURSDAY);
              }
              if (index == 5 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.FRIDAY);
              }
              if (index == 6 && maskBool) {
                this.weekly.weekdayMask.push(weekdayEnum.SATURDAY);
              }
              index++;
            }
            if (this.weekly.occurenceLimit == 0) {
              this.weekly.occurenceLimit = '';
              if (this.eventForm.repeating.maxDate === null) {
                this.eventNeverEnd = true;
              } else {
                this.selectedMaxDate = true;
              }
            }
            break;
          case repeatingEventPatternEnum.MONTHLY:
            this.monthly.occurenceLimit = this.activeEvent.repeating.occurenceLimit
              ? parseInt(this.activeEvent.repeating.occurenceLimit)
              : 0;
            if (this.monthly.occurenceLimit == 0) {
              this.monthly.occurenceLimit = '';
              if (this.activeEvent.repeating.maxDate === null) {
                this.eventNeverEnd = true;
              } else {
                this.selectedMaxDate = true;
              }
            }
            break;
        }
      } else {
        this.showSeriesEventForm = false;
      }
    },
    resetForm() {
      this.eventForm.id = '';
      this.eventForm.title = '';
      this.eventForm.type = '';
      this.eventForm.invitees = [];
      this.eventForm.inviteeIds = [];
      this.eventForm.invitedGroups = [];
      this.eventForm.invitedGroupIds = [];
      this.eventForm.invitedOtpInboxIds = [];
      if (!isEmpty(this.activeEvent)) {
        this.eventForm.institutionCode = this.activeEvent.institutionCode;
      } else {
        this.eventForm.institutionCode = getDefaultInstitutionCode(
          this.institutionsThatCanBeSeen,
          this.activeInstitutionCodes,
          this.institutionsThatCanBeSeen[0].institutionCode
        );
      }
      this.eventForm.startDate = moment().add(1, 'hours').minute(0).format('YYYY-MM-DD');
      this.eventForm.endDate = moment().add(1, 'hours').minute(30).format('YYYY-MM-DD');
      this.eventForm.startTime = moment().add(1, 'hours').minute(0).format('HH:mm:ss');
      this.eventForm.endTime = moment().add(1, 'hours').minute(30).format('HH:mm:ss');
      this.eventForm.resources = [];
      this.eventForm.allDay = false;
      this.eventForm.attachments = [];
      this.eventForm.primaryResource = {};
      this.eventForm.additionalLocations = [];
      this.eventForm.additionalResources = [];
      this.eventForm.coOrganizerIds = [];
      this.existingLocations = [];
      this.existingInvitees = [];
      this.existingOrganizers = [];
      this.existingResources = [];
      if (this.eventForm.repeating != null) {
        this.eventForm.repeating.pattern = repeatingEventPatternEnum.NEVER;
      }
      this.eventForm.responseRequired = true;
      this.eventForm.description = '';
      this.existingAttachments = [];
      this.emptyEventForm = {};
    },
    ...mapActions({
      modifyDateTimeSelectedByClickedTimeslot: types.MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT,
      warningConflictEventByAttendees: types.WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
      clearConflictingAttendees: types.CLEAN_WARNING_CONFLICT_EVENT_BY_ATTENDEES,
      createSimpleEvent: types.CREATE_SIMPLE_EVENT,
      updateSimpleEvent: types.UPDATE_SIMPLE_EVENT,
      createRepeatingEvent: types.CREATE_REPEATING_EVENT,
      updateRepeatingEvent: types.UPDATE_REPEATING_EVENT,
      updateLessonEvent: types.UPDATE_LESSON_EVENT,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
      updateEventResources: types.UPDATE_EVENT_RESOURCES,
      createDocumentLinks: types.CREATE_DOCUMENT_LINKS,
      resetActiveEvent: types.RESET_ACTIVE_EVENT,
      getEventById: types.GET_EVENT_BY_ID,
    }),
    ...mapMutations({
      updateUnavailableResources: types.MUTATE_UNAVAILABLE_RESOURCES,
    }),
    async loadEditedEvent() {
      const params = {
        eventId: this.$route.params.eventId,
        occurrenceDateTime: this.$route.query.occurrence,
      };
      await this.getEventById(params);
      if (this.$route.params.eventId == this.activeEvent.id) {
        this.simpleEventModel.setMaximumNumberOfParticipants(this.activeEvent.maximumNumberOfParticipants);
        this.simpleEventModel.setMaxParticipantsEnabled(this.activeEvent.maximumNumberOfParticipants != null);
        this.simpleEventModel.setActualNumberOfParticipants(this.activeEvent.actualNumberOfParticipants);
        this.eventForm = cloneDeep(this.activeEvent);
        this.eventForm.inviteeGroups = [];
        this.hiddenOrganizers = [this.eventForm.creator];
        this.eventForm.oldStartDateTime = this.activeEvent.startDateTime;
        this.eventForm.oldEndDateTime = this.activeEvent.endDateTime;
        this.existingOrganizers = this.eventForm.coOrganizers = this.activeEvent.coOrganizers
          ? this.activeEvent.coOrganizers.map(coOrganizer => coOrganizer.instProfile)
          : [];
        this.selectedOrganizers = [...this.existingOrganizers];
        this.eventForm.invitees = this.calculateCurrentInvitees();
        this.eventForm.coOrganizerIds = this.existingOrganizers.map(coOrganizer => coOrganizer.id);
        if (Array.isArray(this.activeEvent.invitedGroupHomeChildren)) {
          this.eventForm.invitedOtpInboxIds = [
            ...new Set(this.activeEvent.invitedGroupHomeChildren.map(invitedGroupHome => invitedGroupHome.otpInboxId)),
          ];
        }
        this.simpleEventModel.setDoRequestNumberOfParticipants(this.eventForm.doRequestNumberOfParticipants);
        this.eventForm.addToInstitutionCalendar = this.activeEvent.addedToInstitutionCalendar;
        if (
          this.institutions.filter(
            inst => this.eventForm.creator != null && inst.institutionProfileId == this.eventForm.creator.id
          ).length > 0
        ) {
          this.eventForm.institutionCode = this.institutions.find(
            inst => inst.institutionProfileId == this.eventForm.creator.id
          ).institutionCode;
        } else {
          this.eventForm.institutionCode = this.activeEvent.institutionCode;
        }

        this.eventForm.editorInstProfileId = this.institutions.find(
          inst => inst.institutionCode === this.activeEvent.institutionCode
        ).institutionProfileId;

        if (this.eventForm.allDay) {
          this.startDate = moment(this.activeEvent.startDateTime).utc().format('YYYY-MM-DD');
          this.endDate = moment(this.activeEvent.endDateTime).utc().format('YYYY-MM-DD');
        } else {
          this.startDate = moment(this.activeEvent.startDateTime).format('YYYY-MM-DD');
          this.startTime = moment(this.activeEvent.startDateTime).format('HH:mm:ss');
          this.endDate = moment(this.activeEvent.endDateTime).format('YYYY-MM-DD');
          this.endTime = moment(this.activeEvent.endDateTime).format('HH:mm:ss');
        }

        this.existingAttachments = this.activeEvent.attachments;
        if (this.eventForm.description != null && this.eventForm.description.html != null) {
          this.eventForm.description = this.eventForm.description.html;
        }

        if (this.activeEvent.lesson) {
          this.eventForm.lesson.noteToClass = this.activeEvent.lesson.noteToClass.html;
          this.eventForm.lesson.noteToSubstitute = this.activeEvent.lesson.noteToSubstitute.html;
          this.eventForm.lesson.noteToTeacher = this.activeEvent.lesson.noteToTeacher.html;
        }
        if (this.isHandlingOthersPrivateEvent) {
          this.eventForm.description = this.$options.filters.fromTextKey('CALENDAR_LABEL_EVENT_PRIVATE');
        }
        if (this.activeEvent.primaryResource != null && this.eventForm.type !== eventTypeEnum.LESSON) {
          this.existingLocations.unshift(calendarUtil.mapResource(this.activeEvent.primaryResource));
        }
        if (this.activeEvent.additionalResources != null) {
          const additionalLocations = this.activeEvent.additionalResources.filter(
            resource => resource.resourceCategory.resourceType === resourceTypes.LOCATION
          );
          this.existingLocations.push(...additionalLocations.map(resource => calendarUtil.mapResource(resource)));
        }

        this.selectedLocations = this.existingLocations;
        this.existingResources = [];
        this.eventForm.additionalLocations = [];
        this.eventForm.resources = [];

        for (const resource of this.activeEvent.additionalResources) {
          const entry = calendarUtil.mapResource(resource);
          if (resource.resourceCategory.resourceType.toLowerCase() == 'location') {
            this.eventForm.additionalLocations.push(entry);
          }
          if (resource.resourceCategory.resourceType.toLowerCase() != 'location') {
            this.existingResources.push(entry);
            this.eventForm.resources.push(entry);
          }
        }

        this.eventForm.additionalResources = [];

        if (this.eventForm.responseRequired) {
          this.disableResponseRequire = true;
        }

        this.mapRepeatingEvent();

        const existingAttendees = [];
        if (this.eventForm.invitees != null && this.eventForm.invitees.length > 0) {
          this.eventForm.invitees.forEach(function (invitee) {
            if (invitee.type === 'groupHome') {
              invitee.label = Vue.filter('groupHomeEventInviteeWithMetaData')(invitee);

              existingAttendees.push({
                label: invitee.label,
                name: invitee.label,
                regardingChild: invitee.regardingChildId,
                institutionCode: invitee.institutionCode,
                value: invitee.value.value,
                id: invitee.id,
                portalRole: portalRoles.OTP,
                type: invitee.type,
              });
            } else {
              invitee.label = Vue.filter('displayProfileNameWithMetadata')(invitee);
              invitee.value = 'profile' + invitee.id;

              existingAttendees.push({
                label: Vue.filter('displayProfileNameWithMetadata')(invitee),
                name: Vue.filter('displayProfileNameWithMetadata')(invitee),
                institutionCode: invitee.institutionCode,
                value: 'profile' + invitee.id + invitee.institutionCode,
                id: invitee.id,
                portalRole: invitee.role,
                type: 'profile',
              });
            }
          });
        }
        this.existingInvitees = existingAttendees;
        if (this.eventForm.hideInOwnCalendar) {
          this.eventForm.addToInstitutionCalendar = false;
        }
        this.isLoadingDescription = false;
      }
    },
    calculateCurrentInvitees() {
      const currentInvitees = [];
      const activeEvent = this.activeEvent;

      if (activeEvent.invitees != null && activeEvent.invitees.length > 0) {
        activeEvent.invitees.forEach(invitee => currentInvitees.push(invitee.instProfile));
      }

      if (activeEvent.invitedGroupHomeChildren != null && activeEvent.invitedGroupHomeChildren.length > 0) {
        activeEvent.invitedGroupHomeChildren.forEach(invitedGroupHome => {
          currentInvitees.push({
            id: invitedGroupHome.otpInboxId,
            groupHomeId: invitedGroupHome.groupHomeId,
            groupHomeName: invitedGroupHome.groupHomeName,
            regardingChildId: invitedGroupHome.regardingChildId,
            regardingChildDisplayName: invitedGroupHome.regardingChildDisplayName,
            regardingChildMetaData: invitedGroupHome.regardingChildMetaData,
            type: 'groupHome',
            value: 'groupHome-' + invitedGroupHome.groupHomeId + '-' + invitedGroupHome.regardingChildId,
            portalRole: portalRoles.OTP,
          });
        });
      }

      return currentInvitees;
    },
    populateEventTypes() {
      if (this.basicEventTypes == null) {
        const basicEventTypes = [];
        basicEventTypes.push({
          type: this.eventTypes.EVENT,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_event'),
        });
        basicEventTypes.push({
          type: this.eventTypes.EXCURSION,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_excursion'),
        });
        basicEventTypes.push({
          type: this.eventTypes.HOLIDAY,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_holiday'),
        });
        basicEventTypes.push({
          type: this.eventTypes.OTHER,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_other'),
        });
        basicEventTypes.push({
          type: this.eventTypes.LESSON,
          label: Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_lesson'),
        });
        this.basicEventTypes = basicEventTypes;
      }
    },
    removeUnavailableResources(saveEvent = false) {
      this.resetResources = !this.resetResources;
      this.existingResources = [];

      this.existingResources = this.eventForm.resources = this.eventForm.resources.filter(
        r => this.unavailableResourceIds.find(urId => urId === r.id) == null
      );
      this.eventForm.resources = this.eventForm.resources.filter(
        resource => !this.unavailableResourceIds.includes(resource.id)
      );
      this.eventForm.resourceIds = this.eventForm.resources.map(resource => resource.id);
      this.eventForm.additionalResourceIds = this.eventForm.resourceIds.concat(this.eventForm.additionalLocationIds);

      if (
        this.eventForm.primaryResource &&
        this.unavailableResourceIds.find(urId => urId === this.eventForm.primaryResource.id)
      ) {
        this.eventForm.primaryResource = {};
        this.eventForm.primaryResourceId = null;
        this.resetPrimaryResources = !this.resetPrimaryResources;
      }

      this.updateUnavailableResources([]);
      if (saveEvent) {
        this.determineEventTypeToSave();
      }
    },
    showUnavailableResources() {
      this.unavailableResources = [];
      for (const resourceId of this.unavailableResourceIds) {
        if (this.eventForm.resources.find(r => r.id === resourceId) != null) {
          this.unavailableResources.push(this.eventForm.resources.find(r => r.id === resourceId));
        }
        if (this.eventForm.additionalLocations.find(r => r.id === resourceId) != null) {
          this.unavailableResources.push(this.eventForm.additionalLocations.find(r => r.id === resourceId));
        }
        if (this.eventForm.primaryResource?.id === resourceId) {
          this.unavailableResources.push(this.eventForm.primaryResource);
        }
      }
      this.$refs.unavailableResourcesModal.show();
    },
    setAtrributesForTimepicker() {
      $('.vue__time-picker').attr('tabindex', 0);
      $('.display-time').attr('tabindex', -1);
      $('.vue__time-picker').off('keypress');
      $('.vue__time-picker').on('keypress', function (event) {
        if (event.which === 13) {
          $('.display-time', this)[0].focus();
        }
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.partially-available-resource-list {
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
}
.mobile-settings-section {
  margin-bottom: 25px;

  > div:last-child {
    border-bottom: 1px solid var(--color-white);
    padding-bottom: 25px;
  }
}
.mobile-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    @include breakpoint-lg-down() {
      font-weight: normal !important;
    }
  }
}

.event-settings {
  height: 40px;
  display: flex;
  align-items: center;

  label {
    @include breakpoint-lg-down {
      font-weight: normal !important;
    }
  }
}

.planning-assistant-button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  i {
    font-size: 20px;
    margin-right: 10px;
    text-decoration: none;

    &:hover + span {
      text-decoration: none;
    }
  }

  span {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.input-group-search-addon {
  font-size: 1.5em;
}

.padding-bottom-mobile {
  padding-bottom: 20px;
}

.invited-group-names {
  color: $color-primary-darker !important;
  font-weight: bold;
}

.maxDatePicker {
  margin-left: 10px;
  width: 160px !important;
}

#cannot-send-invitation-modal {
  position: absolute;
  left: 35%;
  top: 30%;
  z-index: 9999;
}

.disabledAddon {
  background: #f5f7fa;
}

#cancel-modal .modal-content {
  height: 210px;
}

.drawer-container {
  position: relative;

  .clickLayer {
    display: none;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vue__time-picker:focus-visible {
  border: 0;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0 2px 0.01rem var(--color-primary-darker);
}

.note-label {
  height: 35px;
  display: inline-block;
}
</style>
