











































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types.js';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes';
import { docTypes } from '../../../shared/enums/docTypes';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients.vue';
import MobileTopbar from '../../../shared/components/MobileTopbar.vue';
import { institutionTypes } from '../../../shared/enums/institutionTypes';
import { groupTypes } from '../../../shared/enums/groupTypes.js';
import moment from 'moment-timezone';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes.js';
import { CalendarFeedProfile } from '../../business/calendarFeedProfile';
import Button from '../../../shared/directives/button.js';

export default {
  directives: {
    Button,
  },
  inject: {
    getEventTypeFilters: providerKeyTypes.eventTypeFilters,
    updateEventTypeFilters: providerKeyTypes.onUpdateEventTypeFilters,
    getExistingSharedCalendars: providerKeyTypes.existingSharedCalendars,
    updateExistingSharedCalendars: providerKeyTypes.onUpdateExistingSharedCalendars,
    getInstitutionCalendars: providerKeyTypes.institutionCalendars,
    updateInstitutionCalendars: providerKeyTypes.onUpdateInstitutionCalendars,
    updateDelegatedContext: providerKeyTypes.onUpdateDelegatedContext,
    getDelegatedContext: providerKeyTypes.delegatedContext,
    getDefaultCalendars: providerKeyTypes.defaultCalendars,
  },
  props: {
    parent: { type: String, default: 'profile' },
    group: { type: Object, default: null },
    isMobile: { type: Boolean, default: false },
    calendar: { type: Object, default: null },
  },
  data: function () {
    return {
      profileStorageFilters: {},
      calendarFeedEnabledForMunicipality: true,
      buttonCreateText: 'create',
      isUpdateFeed: false,
      consentSelected: false,
      showNoConsentWarning: false,
      showNoFiltersChosenWarning: false,
      selectedFilters: [],
      selectedCalendarFeedProfile: new CalendarFeedProfile({
        institutionProfileId: -1,
        name: '',
        shortName: '',
        displayName: '',
        role: portalRoles.EMPLOYEE,
        calendarFeedEnabled: false,
        profilePicture: null,
        calendarFeedConfiguration: null,
      }),
      isLoading: false,
      portalRoles: portalRoles,
      parentTypes: parentTypes,
      groupTypes: groupTypes,
      institutionTypes: institutionTypes,
      routerPath: {},
      permissionEnum: permissionEnum,
      assignedDelegatedAccessList: [],
      assignedDelegatedAccessListByInstProfileId: {},
      searchedEmployees: [],
      showMobileMenu: false,
      showMobileCalendarSyncMenu: false,
      setOtherUserFocus: false,
      showOnlyOneGroupWarning: false,
      hideInOwnCalendar: false,
      responseDeadline: null,
      resetSearch: false,
      availableDeadlineDate: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 86400000 < moment().add(1, 'days').toDate().getTime();
        },
        firstDayOfWeek: 1,
      },
      calendarFeeds: [],
      lastSearchQuery: null,
      printFullCalendarSetting: false,
      mobileExistingSharedCalendars: [],
      mobileSelectedInstitutionCalendarIds: [],
    };
  },
  computed: {
    ...mapGetters({
      getConsentSelected: types.GET_CALENDAR_SYNC_CONSENT,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      institutions: types.GET_INSTITUTIONS,
      eventTypesForFilter: types.CALENDAR_GET_EVENT_TYPES_FOR_FILTER,
      events: types.GET_EVENTS,
      profile: types.GET_CURRENT_PROFILE,
      assignedDelegatedAccesses: types.GET_ASSIGNED_DELEGATED_ACCESSES,
      myDelegatedAccesses: types.GET_MY_DELEGATED_ACCESSES,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      children: types.GET_CHILDREN,
      getCalendarFeeds: types.GET_CALENDAR_FEEDS,
      activeEvent: types.GET_CURRENT_EVENT,
      vacationRequestResponses: types.GET_VACATION_REQUEST_RESPONSES,
      calendarFeedEnabled: types.GET_MUNICIPAL_CALENDER_FEED,
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      calendarFeedEvents: types.GET_CALENDAR_FEED_EVENTS,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
    }),
    calendarFeedEventOptions() {
      return this.calendarFeedEvents.eventTypes;
    },
    calendarYearFeedOverviewDeleteText() {
      if (this.calendarFeedProfiles.length === 0) {
        return '';
      }
      return this.profile.role === portalRoles.EMPLOYEE
        ? this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_EMPLOYEE_FEED_DELETE_TEXT')
        : this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_GUARDIAN_FEED_DELETE_TEXT');
    },
    calendarYearFeedOverviewEditText() {
      if (this.calendarFeedProfiles.length === 0) {
        return '';
      }
      return this.profile.role === portalRoles.EMPLOYEE
        ? this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_EMPLOYEE_FEED_EDIT_TEXT')
        : this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_GUARDIAN_FEED_EDIT_TEXT');
    },
    calendarFeedOverviewCreateLinkText() {
      if (this.calendarFeedProfiles.length === 0) {
        return '';
      }
      return this.profile.role === portalRoles.EMPLOYEE
        ? this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_CREATE_LINKS')
        : this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_CREATE_LINK');
    },
    calendarFeedProfiles() {
      let calendarFeedProfiles = [];
      if (this.profile.role === portalRoles.EMPLOYEE) {
        calendarFeedProfiles = this.institutions.map(inst => {
          const calendarFeedConfiguration = this.calendarFeeds.find(
            feed => feed.regardingId === inst.institutionProfileId
          );
          return new CalendarFeedProfile({
            institutionProfileId: inst.institutionProfileId,
            name: inst.name,
            shortName: inst.shortName,
            displayName: inst.name,
            role: portalRoles.EMPLOYEE,
            calendarFeedEnabled: inst.calendarFeedEnabled,
            profilePicture: null,
            calendarFeedConfiguration: calendarFeedConfiguration || null,
          });
        });
      } else if (this.profile.role === portalRoles.GUARDIAN) {
        const institutionCalendarFeedProfiles = this.institutions.map(inst => {
          const calendarFeedConfiguration = this.calendarFeeds.find(
            feed => feed.regardingId === inst.institutionProfileId
          );
          return new CalendarFeedProfile({
            institutionProfileId: inst.institutionProfileId,
            name: this.profile.fullName,
            shortName: this.profile.shortName,
            displayName: `${this.profile.fullName} (${inst.name})`,
            role: portalRoles.GUARDIAN,
            calendarFeedEnabled: inst.calendarFeedEnabled,
            profilePicture: this.profile.profilePicture,
            calendarFeedConfiguration: calendarFeedConfiguration || null,
          });
        });
        const childCalendarFeedProfiles = this.children.map(child => {
          const calendarFeedConfiguration = this.calendarFeeds.find(feed => feed.regardingId === child.id);
          return new CalendarFeedProfile({
            institutionProfileId: child.id,
            name: child.name,
            shortName: child.shortName,
            displayName: child.name,
            role: portalRoles.CHILD,
            calendarFeedEnabled: child.calendarFeedEnabled,
            profilePicture: child.profilePicture,
            calendarFeedConfiguration: calendarFeedConfiguration || null,
          });
        });
        calendarFeedProfiles = [...institutionCalendarFeedProfiles, ...childCalendarFeedProfiles];
      }
      return calendarFeedProfiles;
    },
    delegatedContext() {
      return this.getDelegatedContext();
    },
    selectedInstitutionCalendarIds() {
      return this.institutionCalendars.map(({ id }) => id);
    },
    institutionCalendars() {
      return this.getInstitutionCalendars();
    },
    existingSharedCalendars() {
      return this.getExistingSharedCalendars();
    },
    defaultCalendars() {
      return this.getDefaultCalendars();
    },
    eventTypeFilters() {
      return this.getEventTypeFilters();
    },
    canCreateEvent() {
      return this.hasPermission(this.permissionEnum.HANDLE_EVENTS) && this.parent == parentTypes.PROFILE;
    },
    canCreateEventInGroupPage() {
      if (this.group) {
        const group = this.group;
        const canInviteToEvent = this.hasPermissionGroup(this.permissionEnum.INVITE_TO_EVENT, group.id);
        const hasDistributionRights = this.hasPermissionInstitution(
          permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
          group.institutionCode
        );
        const activeInstitutionProfile = this.institutions.find(
          institution => institution.institutionCode === this.group.institutionCode
        );

        if (activeInstitutionProfile?.communicationBlock) {
          return false;
        }

        if (!canInviteToEvent && !hasDistributionRights) {
          return false;
        }

        return this.parent === parentTypes.GROUP;
      }
      return false;
    },
    canCreateMeetingEvent() {
      return (
        this.hasNonBlockingProfiles &&
        this.profile.role == this.portalRoles.EMPLOYEE &&
        this.parent == parentTypes.PROFILE &&
        (this.hasPermission(this.permissionEnum.HANDLE_PERFORMANCE_MEETING) ||
          this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL) ||
          this.hasPermission(this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE))
      );
    },
    canCreateMeetingEventInGroupPage() {
      if (this.group != null && this.parent == parentTypes.GROUP) {
        return (
          this.canCreateEventInGroupPage &&
          this.profile.role == this.portalRoles.EMPLOYEE &&
          (this.hasPermissionInstitution(this.permissionEnum.HANDLE_PERFORMANCE_MEETING, this.group.institutionCode) ||
            this.hasPermissionInstitution(
              this.permissionEnum.HANDLE_PARENTAL_MEETING_SCHOOL,
              this.group.institutionCode
            ) ||
            this.hasPermissionInstitution(
              this.permissionEnum.HANDLE_PARENTAL_MEETING_DAYCARE,
              this.group.institutionCode
            ))
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      loadEventTypes: types.LOAD_EVENT_TYPES,
      loadAssignedDelegatedAccesses: types.LOAD_ASSIGNED_DELEGATED_ACCESSES,
      updateDelegatedAccess: types.UPDATE_DELEGATED_ACCESS,
      loadConsentSelected: types.LOAD_CALENDAR_SYNC_CONSENT,
      updateConsentSelected: types.UPDATE_CALENDAR_SYNC_CONSENT,
      loadCalendarFeeds: types.LOAD_CALENDAR_FEEDS,
      updateCalendarFeeds: types.UPDATE_CALENDAR_FEED,
      createCalendarFeeds: types.CREATE_CALENDAR_FEED,
      deleteCalendarFeeds: types.DELETE_CALENDAR_FEED,
      loadVacationRequestResponses: types.LOAD_VACATION_REQUEST_RESPONSES,
      loadCalendarFeedEnabled: types.LOAD_MUNICIPAL_CALENDER_FEED,
      loadCalendarFeedEvents: types.LOAD_CALENDAR_FEED_EVENTS,
      modifyDateTimeSelectedByClickedTimeslot: types.MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT,
    }),
    async getCalendarFeedEventOptions(portalRole: portalRoles) {
      await this.loadCalendarFeedEvents({ PortalRole: portalRole });
    },
    openedFromGroup() {
      return Object.keys(this.group).length !== 0;
    },
    okText() {
      if (this.isMobile) {
        this.buttonCreateText = this.isUpdateFeed ? 'CALENDAR_BUTTON_SAVE' : 'CALENDAR_BUTTON_CREATE';
      } else {
        this.buttonCreateText = this.isUpdateFeed
          ? 'CALENDARFEED_OVERVIEW_SAVE_LINK'
          : 'CALENDARFEED_OVERVIEW_CREATE_LINK';
      }
    },
    validateAddOrHideInOwnCalendar(isChangedFromAddToInstitution = true) {
      Vue.nextTick(() => {
        if (isChangedFromAddToInstitution && this.requestVacationIsAddedToInstitution) {
          this.hideInOwnCalendar = false;
        } else if (!isChangedFromAddToInstitution && this.hideInOwnCalendar) {
          this.requestVacationIsAddedToInstitution = false;
        }
        this.$forceUpdate();
      });
    },
    openCalendarSyncModal() {
      if (this.getConsentSelected.data.policyAccepted == true) {
        this.$refs.calendarSyncOverviewModal.show();
      } else {
        this.$refs.changeSyncOptionModal.show();
      }
    },

    async openOverview() {
      this.isLoading = true;
      if (this.consentSelected == false && this.getConsentSelected.data.policyAccepted == false) {
        this.showNoConsentWarning = true;
        this.isLoading = false;
      } else if (this.consentSelected == false && this.getConsentSelected.data.policyAccepted == true) {
        this.$refs.consentRemoveWarning.show();
        this.isLoading = false;
        return;
      } else {
        await this.changeStatus();
        this.$refs.calendarSyncOverviewModal.show();
      }
    },
    async changeStatus() {
      this.isLoading = true;
      const payload = {
        policyAccepted: this.consentSelected,
      };
      await this.updateConsentSelected(payload);
      await this.loadCalendarFeeds();
      this.calendarFeeds = this.getCalendarFeeds.data;
      this.showNoConsentWarning = false;
      this.isLoading = false;
      this.$refs.changeSyncOptionModal.hide();
    },
    resetCalendarSyncModal() {
      this.consentSelected = this.getConsentSelected.data.policyAccepted;
      this.showNoConsentWarning = false;
    },
    onDeleteButtonClicked(calendarFeedProfile) {
      this.selectedCalendarFeedProfile = calendarFeedProfile;
      this.$refs.feedDeleteWarning.show();
    },
    getCalendarYearFeedOverviewDescription(calendarFeedProfile) {
      if (this.calendarFeedProfiles.length === 0) {
        return '';
      }
      let description = this.$options.filters.fromTextKey('CALENDARFEED_OVERVIEW_EMPLOYEE_YEAR_FEED_DESCRIPTION');
      if (calendarFeedProfile.role === portalRoles.GUARDIAN) {
        description = this.$options.filters.fromTextKey(
          'CALENDARFEED_OVERVIEW_GUARDIAN_YEAR_FEED_DESCRIPTION_FOR_GUARDIAN'
        );
      } else if (calendarFeedProfile.role === portalRoles.CHILD) {
        description = this.$options.filters.fromTextKey(
          'CALENDARFEED_OVERVIEW_GUARDIAN_YEAR_FEED_DESCRIPTION_FOR_CHILD'
        );
      }
      return description;
    },
    copyWeekFeed(calendarFeedProfile) {
      navigator.clipboard.writeText(calendarFeedProfile.getOneWeekFeed());
      this.$refs.copyLinkToastr.show();
    },
    copyYearFeed(calendarFeedProfile) {
      navigator.clipboard.writeText(calendarFeedProfile.getOneYearFeed());
      this.$refs.copyLinkToastr.show();
    },
    openFeed(calendarFeedProfile) {
      this.getCalendarFeedEventOptions(calendarFeedProfile.role);
      this.selectedCalendarFeedProfile = calendarFeedProfile;
      this.selectedFilters = this.selectedCalendarFeedProfile.calendarFeedConfiguration?.filters || [];
      this.okText();
      this.$refs.calendarSyncOverviewModal.hide();
      this.$refs.calendarSyncCreateModal.show();
    },
    closeFeed() {
      this.$refs.calendarSyncCreateModal.hide();
      this.$refs.calendarSyncOverviewModal.show();
    },
    createFeed() {
      this.isLoading = true;
      if (this.selectedFilters.length > 0) {
        this.showNoFiltersChosenWarning = false;
      } else {
        this.isLoading = false;
        this.showNoFiltersChosenWarning = true;
        return;
      }
      const payload = {
        institutionProfileId: this.selectedCalendarFeedProfile.institutionProfileId,
        filters: this.selectedFilters,
      };
      this.createCalendarFeeds(payload).then(() => {
        this.loadCalendarFeeds().then(() => {
          this.calendarFeeds = this.getCalendarFeeds.data;
          this.isLoading = false;
          this.closeFeed();
        });
      });
    },
    checkIfHaveFeed(calendarFeedProfile) {
      if (this.calendarFeedProfiles.length === 0) {
        return false;
      }
      const calendarFeedRegardingIds = this.calendarFeeds.map(feed => feed.regardingId);
      return calendarFeedRegardingIds.includes(calendarFeedProfile.institutionProfileId);
    },
    updateFeed() {
      this.isLoading = true;
      if (this.selectedFilters.length > 0) {
        this.showNoFiltersChosenWarning = false;
      } else {
        this.isLoading = false;
        this.showNoFiltersChosenWarning = true;
        return;
      }
      const payload = {
        calendarfeedconfigurationid: this.selectedCalendarFeedProfile.calendarFeedConfiguration?.id,
        filters: this.selectedFilters,
      };
      this.updateCalendarFeeds(payload).then(() => {
        this.loadCalendarFeeds().then(() => {
          this.calendarFeeds = this.getCalendarFeeds.data;
          this.isLoading = false;
          this.closeFeed();
        });
      });
    },
    async deleteFeed() {
      this.isLoading = true;
      const payload = {
        calendarFeedConfigurationId: this.selectedCalendarFeedProfile.calendarFeedConfiguration?.id,
      };
      await this.deleteCalendarFeeds(payload);
      await this.loadCalendarFeeds();

      this.calendarFeeds = this.getCalendarFeeds.data;
      this.isLoading = false;
      this.$refs.feedDeleteWarning.hide();
      this.$refs.deleteLinkToastr.show();
    },
    createEvent(name, parent) {
      if (!this.isPreviewMode) {
        this.modifyDateTimeSelectedByClickedTimeslot(null);
        this.$router.push({
          name: name,
          params: { groupId: this.group.id },
          query: { parent: parent },
        });
      }
    },
    focusOtherUserSearch() {
      this.setOtherUserFocus = true;
      this.$nextTick(() => (this.setOtherUserFocus = false));
    },
    getDelegatedProfileByInstProfileId(assignedDelegatedAccesses) {
      const delegatedInstProfile = [];
      const em = this;
      em.institutions.forEach(function (institution) {
        const delegated = assignedDelegatedAccesses.filter(
          instProfile => instProfile.ownerInstProfileId == institution.institutionProfileId
        );
        if (delegated[0] != undefined && delegated[0].delegatedInstProfiles != undefined) {
          delegatedInstProfile[institution.institutionProfileId] = delegated[0].delegatedInstProfiles;
        } else {
          delegatedInstProfile[institution.institutionProfileId] = [];
        }
      });
      return delegatedInstProfile;
    },
    emitSelectedCalendars(calendars) {
      const groupTypeCalendars = calendars.filter(cal => cal.type === docTypes.GROUP.toLowerCase());

      if (this.profile.role !== portalRoles.EMPLOYEE && groupTypeCalendars.length > 1) {
        calendars.pop();
        this.showOnlyOneGroupWarning = true;
      } else {
        this.showOnlyOneGroupWarning = false;
        this.resetSearch = !this.resetSearch;
        if (this.isMobile) {
          this.mobileExistingSharedCalendars = calendars;
          this.checkForDefaultCalendars();
        } else {
          this.updateExistingSharedCalendars(calendars);
        }
      }
    },
    emitSelectedInstitutionCalendars(institutionCalendarIds) {
      if (this.isMobile) {
        this.mobileSelectedInstitutionCalendarIds = institutionCalendarIds;
        this.checkForDefaultCalendars();
      } else {
        const institutionIdSet = new Set(institutionCalendarIds);
        const selectedInstitutions = this.institutions.filter(({ id }) => institutionIdSet.has(id));
        this.updateInstitutionCalendars(selectedInstitutions);
        this.$emit('changeCalendars');
      }
    },
    checkForDefaultCalendars() {
      if (this.mobileExistingSharedCalendars.length === 0 && this.mobileSelectedInstitutionCalendarIds.length === 0) {
        this.$nextTick(() => (this.mobileExistingSharedCalendars = this.defaultCalendars));
      }
    },
    onMobileAddSharedUserModalOkClicked() {
      this.updateExistingSharedCalendars(this.mobileExistingSharedCalendars);

      const institutionIdSet = new Set(this.mobileSelectedInstitutionCalendarIds);
      const selectedInstitutions = this.institutions.filter(({ id }) => institutionIdSet.has(id));
      this.updateInstitutionCalendars(selectedInstitutions);
      this.$emit('changeCalendars');

      this.$refs.mobileAddSharedUserModal.hide();
    },
    showMobileAddShareUserModal() {
      this.mobileExistingSharedCalendars = this.existingSharedCalendars;
      this.mobileSelectedInstitutionCalendarIds = this.selectedInstitutionCalendarIds;
      this.$refs.mobileAddSharedUserModal.show();
    },
    emitFilters(newValue) {
      if (newValue == null) {
        return;
      }
      this.updateEventTypeFilters(newValue);
    },
    async selectDelegatedInstProfile(delegatedInstProfile = null) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      let delegatedContext = delegatedInstProfile;
      let setToDefault = false;
      if (this.delegatedContext.id === delegatedInstProfile.id) {
        delegatedContext = {};
        setToDefault = true;
      }

      await this.updateDelegatedContext(delegatedContext, setToDefault);
      this.showMobileMenu = false;
      this.isLoading = false;
    },
    async loadSearchRecipients(query) {
      this.lastSearchQuery = query;
      const response = await portal.get('?method=search.findProfilesAndGroups', {
        params: {
          text: query,
          instCodes: this.activeInstitutions,
          typeahead: true,
          limit: 100,
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD],
        },
      });
      const results = response.data.data.results;
      let resourceResults = [];
      if (this.profile.role == portalRoles.EMPLOYEE) {
        const resourceResponse = await portal.get('?method=resources.listResources', {
          params: {
            query: query,
            institutionCodes: this.activeInstitutions,
          },
        });
        resourceResults = resourceResponse.data.data;
      }
      if (this.lastSearchQuery == query) {
        const employees = [];
        const children = [];
        const groups = [];
        const existingResources = this.existingSharedCalendars;
        for (const item of results) {
          switch (item.docType.toLowerCase()) {
            case 'profile': {
              if (existingResources.filter(p => p.singleId == item.id && p.type == 'profile').length == 0) {
                item.singleId = item.id;
                item.id = item.docType.toLowerCase() + '-' + item.id;
                item.label = Vue.filter('displayProfileNameWithMetadata')(item);
                item.value = item.docType.toLowerCase() + item.id + item.institutionCode;
                item.type = item.docType.toLowerCase();
                switch (item.portalRole) {
                  case portalRoles.EMPLOYEE:
                    employees.push(item);
                    break;
                  case portalRoles.CHILD:
                    children.push(item);
                    break;
                }
              }
              break;
            }
            case 'group': {
              if (existingResources.filter(p => p.singleId == item.id && p.type == 'group').length == 0) {
                item.value = item.docType.toLowerCase() + item.id + item.institutionCode;
                item.label = item.name + (item.institutionName ? ' (' + item.institutionName + ')' : '');
                item.type = item.docType.toLowerCase();
                item.singleId = item.id;
                item.id = item.docType.toLowerCase() + '-' + item.id;
                groups.push(item);
                break;
              }
            }
          }
        }
        const groupedResults = [
          { label: Vue.filter('fromTextKey')('GROUP'), options: groups },
          {
            label: Vue.filter('fromTextKey')('EMPLOYEES'),
            options: employees,
          },
          { label: Vue.filter('fromTextKey')('PUPILS'), options: children },
        ];
        const resources = [];
        for (const key in resourceResults) {
          const resource = resourceResults[key];
          if (existingResources.filter(p => p.singleId == resource.id && p.type == 'resource').length == 0) {
            const entry = {
              label: `${resource.displayName} (${resource.institutionName})`,
              value: 'resource' + resource.id + resource.institutionCode,
              name: resource.name,
              shortName: resource.shortName,
              resourceType: resource.resourceCategory.resourceType,
              singleId: resource.id,
              id: 'resource-' + resource.id,
              type: 'resource',
              institutionCode: resource.institutionCode,
            };
            if (resources[resource.resourceCategory.id] != null) {
              resources[resource.resourceCategory.id].options.push(entry);
            } else {
              resources[resource.resourceCategory.id] = {
                label: resource.resourceCategory.name,
                options: [entry],
              };
            }
          }
        }
        for (const key in resources) {
          groupedResults.push(resources[key]);
        }
        this.searchedEmployees = groupedResults;
      }
    },
    showAddManagementUserModal() {
      this.loadAssignedDelegatedAccesses().then(() => {
        if (!this.isPreviewMode) {
          this.assignedDelegatedAccessListByInstProfileId = this.getDelegatedProfileByInstProfileId(
            this.assignedDelegatedAccesses
          );
          this.isLoading = false;
          this.$refs.addManagementUserModal.show();
        }
      });
    },
    print() {
      this.setPrintTimeRangeEventListeners();

      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') > -1) {
        document.execCommand('print', false, null);
      } else {
        window.print();
      }
    },
    printFullCalendar() {
      this.printFullCalendarSetting = true;
      this.print();
    },
    setPrintTimeRangeEventListeners() {
      window.addEventListener('beforeprint', this.setPrintTimeRange, {
        once: true,
      });
      window.addEventListener('afterprint', this.unsetPrintTimeRange, {
        once: true,
      });
    },
    setPrintTimeRange() {
      if (this.printFullCalendarSetting === false) {
        this.calendar.setOption('minTime', '06:00:00');
        this.calendar.setOption('maxTime', '18:00:00');
      }
    },
    unsetPrintTimeRange() {
      this.calendar.setOption('minTime', '00:00:00');
      this.calendar.setOption('maxTime', '24:00:00');
      this.printFullCalendarSetting = false;
    },
    hideAddManagementUserModal() {
      this.$refs.addManagementUserModal.hide();
    },
    addManagementUser() {
      this.isLoading = true;
      const updateArr = [];
      for (const key in this.assignedDelegatedAccessListByInstProfileId) {
        updateArr.push({
          ownerInstProfileId: key,
          delegatedInstProfileIds: this.assignedDelegatedAccessListByInstProfileId[key].map(function (user) {
            return user.id;
          }),
        });
      }
      if (updateArr.length > 0) {
        const em = this;
        this.updateDelegatedAccess({ delegatedAccess: updateArr }).then(() => {
          em.loadAssignedDelegatedAccesses().then(() => {
            this.hideAddManagementUserModal();
            this.isLoading = false;
            this.assignedDelegatedAccessListByInstProfileId = this.getDelegatedProfileByInstProfileId(
              em.assignedDelegatedAccesses
            );
          });
        });
      }
    },
    updateUserList(employees) {
      for (const employee of employees) {
        const instProfile = this.institutions.filter(i => i.institutionCode == employee.institutionCode);
        const instProfileId = instProfile[0].institutionProfileId;
        if (this.assignedDelegatedAccessListByInstProfileId[instProfileId] != undefined) {
          if (
            this.assignedDelegatedAccessListByInstProfileId[instProfileId].filter(user => user.id == employee.id)
              .length == 0
          ) {
            this.assignedDelegatedAccessListByInstProfileId[instProfileId].push(employee);
          }
        } else {
          this.assignedDelegatedAccessListByInstProfileId[instProfileId].push(employee);
        }
      }
    },
    removeDelegatedAccess(instProfileId, index) {
      this.assignedDelegatedAccessListByInstProfileId[instProfileId].splice(index, 1);
    },
  },
  watch: {
    activeInstitutions() {
      this.loadEventTypes({
        filterInstitutionCodes: this.activeInstitutions,
        use: 'filter',
      });
    },
  },
  mounted() {
    this.loadConsentSelected().then(() => {
      this.consentSelected = this.getConsentSelected.data.policyAccepted;
    });
    this.loadCalendarFeeds().then(() => {
      this.calendarFeeds = this.getCalendarFeeds.data;
    });
    this.loadCalendarFeedEnabled({
      municipalityCodes: this.institutions.map(institution => parseInt(institution.municipalityCode)),
    }).then(() => {
      if (this.calendarFeedEnabled && this.calendarFeedEnabled.length > 0) {
        this.calendarFeedEnabledForMunicipality =
          this.calendarFeedEnabled.filter(calendarFeed => calendarFeed.calendarFeedEnabled === true).length > 0;

        this.institutions.forEach(institution => {
          institution.calendarFeedEnabled = this.calendarFeedEnabled.find(
            calendarFeed => calendarFeed.municipalityCode === institution.municipalityCode
          ).calendarFeedEnabled;
        });

        this.children.forEach(child => {
          const calendarFeedResult = this.calendarFeedEnabled.find(
            calendarFeed => calendarFeed.municipalityCode === child.municipalityCode
          );
          if (calendarFeedResult != null) {
            child.calendarFeedEnabled = calendarFeedResult.calendarFeedEnabled;
          } else {
            child.calendarFeedEnabled = false;
          }
        });
      }
    });
    this.loadEventTypes({
      filterInstitutionCodes: this.activeInstitutions,
      use: 'filter',
    });
    if (this.parent == 'group') {
      this.routerPath = {
        createBasicEvent: 'group-calendar-create-event',
        createMeetingEvent: 'group-calendar-create-meeting-event',
      };
    } else {
      this.routerPath = {
        createBasicEvent: 'createEvent',
        createMeetingEvent: 'createMeetingEvent',
      };
    }
  },
  components: {
    AulaSearchRecipients,
    MobileTopbar,
  },
};
